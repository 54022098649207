import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { COLOR } from "../Theme";


const LoadingIcon = ({ iconStyle }) => {
  return <LoadingOutlined style={{ color: COLOR.text.primaryText, fontSize: 24, ...iconStyle }} spin />

}

export default LoadingIcon
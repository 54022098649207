import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  keyBy,
  map,
} from "lodash";
import * as Service from "../core/Service";
import AppLayout from "../components/AppLayout";
import { FONT, COLOR } from "../Theme";
import StartBookingCategoryItem from "../components/Landing/StartBookingCategoryItem";
import { setLoading } from "../redux/actions/common";

const BookingCategoryPage = () => {
  const [dataList, setDataList] = useState([]);
  const { company } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (company.company_id) {
      getCategoryList();
    }
  }, [company.company_id]);

  const getCategoryList = async () => {
    try {
      dispatch(setLoading(true));
      const resp = await Service.call(
        "get",
        `/api/booking/v1/category/list`
      );
      if (resp.status === 1) {
        const { bookingCategoryArr, categoryImageArr } = resp.data;
        let keyByBookingCategoryImage = keyBy(
          categoryImageArr,
          "booking_category_id"
        );
        setDataList(
          map(bookingCategoryArr, (eachBookingCategory) => {
            let imgRc = keyByBookingCategoryImage[eachBookingCategory.booking_category_id] || {};
            return {
              ...eachBookingCategory,
              filepath: imgRc.filepath || "",
            };
          })
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <AppLayout>
      <div style={{ padding: "1.5% 4%" }}>
        <div
          style={{
            color: COLOR.text.primaryText,
            ...FONT.h5,
            margin: "0 0 30px 0",
          }}
        >
          {t("start_booking")}
        </div>
        <Row
          gutter={[{ md: 20, lg: 28 }, 24]}
        >
          {map(dataList, (eachBookingCategory, key) => {
            return (
              <Col xs={24} sm={24} md={24} lg={8} xl={6}>
                <StartBookingCategoryItem
                  key={key}
                  bookingCategoryRc={eachBookingCategory}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </AppLayout>
  );
};

export default BookingCategoryPage;

import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ForgetPasswordForm from "./form/ForgetPasswordForm";
import LoginForm from "./form/LoginForm";
import RegisterForm from "./form/RegisterForm";
import * as Main from "../core/Main";
import { setAppModal } from "../redux/actions/common";
import EditPasswordForm from "./form/EditPasswordForm";

const AppModal = () => {
  const appModal = useSelector((state) => state.app.appModal);
  const dispatch = useDispatch();

  const {
    appModalVisible,
    type,
    onSuccess
  } = appModal;

  const renderForm = () => {
    let component = null;
    switch (type) {
      case Main.FORM_TYPE.LOGIN:
        component = (
          <LoginForm
            onSuccess={onSuccess}
          />
        );
        break;
      case Main.FORM_TYPE.REGISTER:
        component = (
          <RegisterForm
            onSuccess={onSuccess}
          />
        );
        break;
      case Main.FORM_TYPE.FORGET_PASSWORD:
        component = <ForgetPasswordForm />;
        break;
      case Main.FORM_TYPE.EDIT_PASSWORD:
        component = <EditPasswordForm />;
        break;
      default:
        break;
    }
    return component;
  };

  return (
    <Modal
      visible={appModalVisible}
      footer={null}
      onCancel={() => dispatch(setAppModal({
        appModalVisible: false,
        type: null
      }))}
      destroyOnClose
    >
      {/* <Spin spinning={loading}> */}
      {renderForm()}
      {/* </Spin> */}
    </Modal>
  );
};

export default AppModal;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  Form,
  Modal,
  Radio,
  Space
} from "antd";
import {
  map, orderBy, set
} from "lodash";
import { useHistory } from "react-router";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import { COLOR, FONT } from "../../Theme";
import { setAppModal } from "../../redux/actions/common";
import { openNotificationWithIcon } from "../form/ErrorMessage";

const MembershipCard = (props) => {
  const { company, user } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPlan, setSelectPlan] = useState({});
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const {
    color_primary
  } = company;
  const {
    membership_plan_id = 0,
    handleChangeLanguage,
    price
  } = props;

  const onFinish = async (formData) => {
    setLoading(true);
    try {
      const url = "/api/order";
      const method = "PUT";
      const resp = await Service.call(method, url, {
        item_id: formData.membership_plan_detail_id,
        item_type: 4 // membership
      });

      if (!resp || resp.status !== 1) {
        return openNotificationWithIcon("error", t("error"), t(resp.errorCode));
      }
      const { orderRc } = resp;
      return history.push(
        `/${company.company_key}/payment/${orderRc?.order_id || 0}`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={styles.container}
    >
      <div>
        <div
          style={{
            ...FONT.h6
          }}
        >
          {handleChangeLanguage(props, "name")}
        </div>
        <div style={{ padding: "24px" }}>
          <span
            style={{
              ...FONT.titleTextBold,
              color: color_primary,
              verticalAlign: "10px"
            }}
          >
            $
          </span>
          <span
            style={{
              ...FONT.h5,
              color: color_primary,
            }}
          >
            {price}
          </span>
          <span>
            /
            {" "}
            {t(`per_month`)}
          </span>
        </div>
        <div
          style={{
            width: "300px",
          }}
          dangerouslySetInnerHTML={{ __html: handleChangeLanguage(props, "description") }}
        />
      </div>
      <div>
        <Divider />
        <Button
          style={{
            width: "190px",
            height: "48px",
            borderRadius: "8px",
            borderWidth: "1px",
            padding: "12px 24px 12px 24px",
            color: color_primary,
            borderColor: color_primary,
            backgroundColor: "transparent"
          }}
          onClick={() => {
            if (!user.company_user_id > 0) {
              dispatch(setAppModal({
                appModalVisible: true,
                type: Main.FORM_TYPE.LOGIN,
                // onSuccess: () => {
                //   setModalVisible(true);
                //   setSelectPlan(props);
                //   form.setFieldsValue({
                //     membership_plan_id
                //   });
                // }
              }));
              return;
            }
            const customOrder = ["monthly", "quarterly", "half_yearly", "yearly"];

            let sortedPlans = orderBy(props.membershipPlanDetailList, [
              (plan) => customOrder.indexOf(plan.duration)
            ], ["asc"]);
            setSelectPlan({
              ...props,
              membershipPlanDetailList: sortedPlans
            });
            setModalVisible(true);
            form.setFieldsValue({
              membership_plan_id
            });
          }}
        >
          {t("get_it")}
        </Button>
      </div>
      <Modal
        visible={modalVisible}
        destroyOnClose
        onCancel={() => {
          setSelectPlan({});
          setModalVisible(false);
        }}
        footer={null}
        title={t("select_a_plan")}
      >
        <Form
          form={form}
          name="membership_plan_form"
          onFinish={onFinish}
        >
          <Form.Item name="membership_plan_id" noStyle />
          <Form.Item
            name="membership_plan_detail_id"
            rules={[{ required: true, message: t("require_plan") }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {map(selectedPlan.membershipPlanDetailList, (eachDetail) => {
                  const {
                    membership_plan_detail_id,
                    final_price,
                    duration
                  } = eachDetail;
                  return (
                    <Radio
                      key={membership_plan_detail_id}
                      value={membership_plan_detail_id}
                    >
                      {" "}
                      {t(`per_${duration}`)}
                      {" "}
                      -
                      {" "}
                      {`$${final_price}`}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button
              style={{
                width: "100%",
                borderRadius: "8px"
              }}
              theme_color={color_primary}
              size="large"
              htmlType="submit"
              loading={loading}
            >
              {t("checkout")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#FBF9F9",
    minWidth: "300px",
    minHeight: "466px",
    padding: "16px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  }
};

export default MembershipCard;

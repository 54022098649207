import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Divider
} from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { FONT, COLOR } from "../../Theme";
import { useDispatch, useSelector } from "react-redux";
import OrderDetailCard from "../order/OrderDetailCard";

const ShoppingCartMobileView = ({
  shoppingCartInfo,
  onDelete,
  history,
  onCheckout
}) => {
  const { t } = useTranslation();
  const { client, company } = useSelector((state) => state.app);
  const [buttonLoading, setButtonLoading] = useState(false);
  const {
    shoppingCartRc,
    shoppingCartItemList
  } = shoppingCartInfo;

  return (
    <>
      <div
        style={{
          ...FONT.h4,
          color: COLOR.text.primaryText,
          padding: "15px"
        }}
      >
        {t("shopping_cart")}
      </div>
      {!_.isEmpty(shoppingCartItemList) ? (
        <>
          <Row gutter={[0, 20]}>
            {_.map(shoppingCartItemList, (eachItem) => {
              return (
                <Col
                  span={24}
                  key={eachItem?.item_id}
                  style={{
                    backgroundColor: COLOR.function.white,
                  }}
                >
                  <OrderDetailCard
                    key={eachItem?.item_id}
                    data={eachItem}
                    isCartItem
                    containerStyle={{
                      padding: "15px 24px"
                    }}
                    onDelete={(value) => onDelete(value)}
                  />
                </Col>
              );
            })}
          </Row>
          <div
            style={{
              padding: "20px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                ...FONT.bodyText2Regular,
                color: COLOR.text.captionText,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "12px"
                }}
              >
                {`${t("discount")}:`}
                <span
                  style={{
                    ...FONT.bodyText1Bold,
                    color: COLOR.text.primaryText
                  }}
                >
                  {`HKD$${shoppingCartRc.totalDiscount.toLocaleString() || 0}`}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "12px"
                }}
              >
                {`${t("order_total")} (${shoppingCartItemList.length} ${t("items")})`}
                <span
                  style={{
                    ...FONT.bodyText1Bold,
                    color: COLOR.text.primaryText
                  }}
                >
                  {`HKD$${shoppingCartRc?.final_price?.toLocaleString() || 0}`}
                </span>
              </div>
            </div>
            <Button
              loading={buttonLoading}
              style={{
                width: "100%",
                marginTop: 20,
                borderRadius: 8,
                height: 48,
                backgroundColor: COLOR.primary.primary500,
                color: COLOR.whiteText
              }}
              onClick={async () => {
                setButtonLoading(true);
                try {
                  await onCheckout();
                } catch (error) {
                  console.error(error);
                } finally {
                  setButtonLoading(false);
                }
              }}
            >
              {t("checkout")}
            </Button>
            <Divider />
          </div>
        </>
      )
        : (
          <div
            style={{
              minHeight: 600,
              padding: "0 24px"
            }}
          >
            <Row
              justify="center"
              gutter={[0, 30]}
            >
              <Col
                span={24}
                align="middle"
                style={{
                  marginTop: 100
                }}
              >
                <img
                  src={`${client?.config?.STATIC_SERVER_URL}/shopping_cart_white.svg`}
                  width="40"
                  alt="empty_shopping_cart"
                  style={{
                    color: COLOR.function.disable
                  }}
                />
              </Col>
              <Col
                span={24}
                align="middle"
                style={{
                  ...FONT.bodyText1Regular,
                  color: COLOR.text.captionText
                }}
              >
                {t("shopping_cart_is_empty")}
              </Col>
              <Col
                span={24}
                align="middle"
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 8,
                    color: COLOR.text.whiteText,
                    backgroundColor: COLOR.primary.primary500,
                    height: 48
                  }}
                  onClick={() => history.push(`/${company.company_key}/booking`)}
                >
                  {t("continue_shopping")}
                </Button>
              </Col>
            </Row>
          </div>
        )}
    </>
  );
};

export default ShoppingCartMobileView;

import React from 'react';

const TNContent = ({ children, fullWidth, color, maxWidth, className }) => {
  return (
    <div
      className={className}
      style={{
        ...styles.container,
        padding: fullWidth ? 0 : '0px 10px',
        backgroundColor: color || '#FFFFFF',
        maxWidth: fullWidth ? '100%' : maxWidth || 1800
      }}
    >
      {children}
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    margin: '0px auto'
  }
}

export default TNContent;

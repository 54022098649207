import React, { useState } from "react";
import {
  Form,
  Input,
  message
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import { COLOR, FONT } from "../../Theme";
import lockIcon from "../../assets/lock.svg";
import Button from "../Button";
import { openNotificationWithIcon } from "./ErrorMessage";
import { setAppModal } from "../../redux/actions/common";

const EditPasswordForm = () => {
  const [editPasswordForm] = Form.useForm();
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    color_primary,
  } = app.company;
  const onFinish = async (formData) => {
    setLoading(true);
    try {
      const { original_password, new_password } = formData;
      if (original_password === new_password) {
        return openNotificationWithIcon("error", t("error"), t("new_password_cannot_be_same_as_original_password"));
      }
      let patchData = {
        password: original_password,
        new_password
      };
      let resp = await Service.call("patch", "/api/user/edit/password", patchData);
      if (!resp || resp.status !== 1) {
        return openNotificationWithIcon("error", t("error"), t(`${resp.errorCode}`));
      }
      openNotificationWithIcon("success", t("success"), t("change_password_success"));
      let { userSession } = resp;
      Main.setUserSession(userSession);
      dispatch(setAppModal({
        appModalVisible: false,
        type: null
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={editPasswordForm}
      name="editPasswordForm"
      layout="vertical"
      onFinish={onFinish}
    >
      <p style={{ ...FONT.h3, margin: "30px 0" }}>
        {t("change_password")}
      </p>
      <Form.Item
        name="original_password"
        rules={[{
          required: true,
          message: t("require_password"),
        },
        {
          pattern: /\S{8,}/,
          message: t('password_validation')
        }]}
      >
        <Input.Password
          placeholder={t("enter_original_password")}
          prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
      <Form.Item
        name="new_password"
        rules={[{
          required: true,
          message: t("require_password"),
        },
        {
          pattern: /\S{8,}/,
          message: t('password_validation')
        }]}
      >
        <Input.Password
          placeholder={t("enter_new_password")}
          prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={["new_password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: t("require_confirm_password"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("new_password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("password_not_match")));
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={t("confirm_password")}
          prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
      <Form.Item>
        <Button
          style={{
            width: "100%",
            borderRadius: "8px"
          }}
          theme_color={color_primary}
          size="large"
          htmlType="submit"
          loading={loading}
        >
          {t("sign_up")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditPasswordForm;

const styles = {
  inputContainer: {
    borderRadius: "8px",
    backgroundColor: "#FBF9F9",
    height: "45px",
  }
};

import React from "react";
import {
  Row,
  Col,
  Image,
  Divider,
  Button,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useSelector } from "react-redux";
import CardItem from "./CardItem";
import { FONT, COLOR } from "../../Theme";

const BookingOrderDetail = ({
  data,
  isCartItem,
  itemStyle = {},
  orderStatus,
  onDelete = () => null,
  setCancelTarget,
  setCancelModalVisible,
}) => {
  const {
    order_item_id,
    item_name,
    item_category_name,
    start_time,
    end_time,
    childItems,
    final_price,
    shopping_cart_item_id = null,
    childItemsFinalPrice = 0,
    item_img = null,
    is_active: orderItemStatus
  } = data;
  const { t } = useTranslation();
  const client = useSelector((state) => state.app.client);
  const { config } = client;
  return (
    <Row style={itemStyle} gutter={[{ md: 20, lg: 28 }, 24]}>
      <Col sm={24} md={12}>
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Image
            src={item_img ? `${config.MEDIA_URL}/${item_img}` : "/default_image.svg"}
            alt="image"
            width="100%"
            style={{
              borderRadius: 10,
            }}
            preview={false}
          />
        </div>
      </Col>
      <Col sm={24} md={12}>
        <Row gutter={[0, 12]}>
          <CardItem
            title={t("room")}
            value={(
              <>
                <div style={{ fontWeight: "bold", fontSize: 16 }}>
                  {item_category_name}
                </div>
                <div>{item_name}</div>
              </>
            )}
          />
          <CardItem title={t("date")} value={dayjs.unix(start_time).format("DD-MM-YYYY")} />
          <CardItem
            title={t("time")}
            value={`${dayjs.unix(start_time).format("HH:mm")} - ${dayjs
              .unix(end_time)
              .format("HH:mm")}`}
          />

          {!_.isEmpty(childItems) && !_.isNil(childItems) && (
            <CardItem
              title={t("rentals")}
              value={(
                <>
                  {_.map(
                    childItems,
                    ({ final_price, item_name, quantity }, index) => (
                      <div
                        key={index}
                      >
                        {`+$${final_price} ${item_name} x ${quantity}`}
                      </div>
                    )
                  )}
                </>
              )}
            />
          )}
        </Row>
      </Col>
      {_.includes([
        "partial_payment_refund",
        "partial_payment_refund_token",
        "partial_payment_refund_offline",
        "payment_confirmed",
        "payment_refund",
        "payment_refund_token",
        "payment_refund_offline",
        "cancelled"
      ], orderStatus) && start_time > dayjs().unix()
        && (
          <Col
            span={24}
            style={{
              ...FONT.buttonRegular,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="ghost"
              style={{
                border: "none",
                color: "#9c1d14",
                backgroundColor: "white"
              }}
              onClick={() => {
                setCancelTarget({
                  order_item_id
                });
                setCancelModalVisible(true);
              }}
              disabled={orderItemStatus !== 1}
            >
              <span
                style={orderItemStatus === 1 ? {
                  textDecoration: "underline",
                } : {
                  textDecoration: "underline",
                  color: COLOR.gray.gray400,
                }}
              >
                {orderItemStatus === 1 ? t("cancel") : t("cancelled")}
              </span>
            </Button>
          </Col>
        )}
      {isCartItem && (
        <>
          <Divider
            style={{
              margin: "15px 0"
            }}
          />
          <Col span={24}>
            <Row justify="space-between">
              <Col
                style={{
                  ...FONT.bodyText1Bold,
                  color: COLOR.text.blackText
                }}
              >
                HK$
                {(final_price + childItemsFinalPrice).toLocaleString()}
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "10px"
                }}
                onClick={() => {
                  onDelete(shopping_cart_item_id);
                }}
              >
                <img
                  src={`${client?.config?.STATIC_SERVER_URL}/trash.svg`}
                  width="18"
                  alt="cart"
                />
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};

export default BookingOrderDetail;

import _ from "lodash";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import moment from "moment";
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { ActionCreators } from "../redux/actions";
import { getStore } from "../redux/store/configureStore";
import { COLOR } from "../Theme";

// export function getUser() {
//   return getStore().getState().app.user;
// }

// export function getCompany() {
//   return getStore().getState().app.company;
// }

// export function signOut() {
//   localStorage.setItem('user');
//   localStorage.setItem('auth', JSON.stringify(false));
//   localStorage.removeItem('company');
// }

let userSession = {};

export function convertAllCompanyUserAccessLogToDisplayData(dataList) {
  try {
    let fullDataList = dataList.map((item) => {
      return {
        ctime: moment.unix(item.ctime).format("YYYY-MM-DD HH:mm:ss"),
        access_time: moment.unix(item.access_time).format("YYYY-MM-DD HH:mm:ss"),
        status: item.status,
        usage_count: item.usage_count,
        is_active: item.is_active === 1 ? "Active" : "Inactive",
        direction: item.direction,
        action: item.action,
        reader: item.reader,
        last_name: item.last_name,
        first_name: item.first_name,
        company_user_id: item.company_user_id,
        door_name: item.door_name,
      };
    });
    return fullDataList;
  } catch (error) {
    console.log(error);
  }
}

export async function exportToCSV(dataList, fileName) {
  const header = [
    [
      "Create Time",
      "Access Time",
      "Status",
      "Usage Count",
      "Active Status",
      "Direction",
      "Action",
      "Reader",
      "Last Name",
      "First Name",
      "User ID",
      "Door Name",
    ]
  ];
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  let ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, header);
  XLSX.utils.sheet_add_json(ws, dataList, { skipHeader: true, origin: "A2" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export function mergeByKey(arr, subArr, key) {
  return _.each(arr, (item) => {
    _.find(subArr, (subItem) => {
      return item[key] === subItem[key] ? _.assign(item, subItem) : null;
    });
  });
}

export function mergeByDiffKey(arr, subArr, key, keys) {
  return _.each(arr, (item) => {
    _.find(subArr, (subItem) => {
      return item[key] === subItem[keys] ? _.assign(item, subItem) : null;
    });
  });
}

export async function getXlsxSheetNames(fileObj) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };
    reader.onload = async (evt) => { // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      resolve(wb);
    };
    reader.readAsBinaryString(fileObj);
  });
}

export async function convertXlsxToJSON(fileObj, sheetList) {
  let datalist = {};
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };
    reader.onload = async (evt) => { // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      sheetList.map((sheet_index) => {
        const wsname = wb.SheetNames[sheet_index];
        const ws = wb.Sheets[wsname];

        /* Convert to obj */
        datalist = XLSX.utils.sheet_to_json(ws);
        // console.log("data", datalist);
      });
      resolve(datalist);
    };
    reader.readAsBinaryString(fileObj);
  });
}

const disableNum = {
  company: 4,
  door: 2,
  user: 1
};
// const largest_length = 3 // Maximum accept 7 (111)

export function intToBinary(num, largest_length) {
  let number = num;
  let result = [];
  // Find out the binary number format of the let 'num'
  while (number >= 1) {
    result.unshift(Math.floor(number % 2));
    number /= 2;
  }
  // Fill the zero missed
  while (result.length < largest_length) {
    result.unshift(0);
  }
  return result;
}

// export function checkDisableStatus (num, cate) {
//   let number = num
//   let result = []
//   // Find out the binary number format of the let 'num'
//   while (number >= 1) {
//     result.unshift(Math.floor(number % 2))
//     number /= 2
//   }
//   // Fill the zero missed
//   while (result.length < largest_length) {
//     result.unshift(0)
//   }
//   // If disabled
//   if (result[disableNum[cate]] === 1) {
//     return true
//   }
//   // Else
//   return false
// }

export async function getInitialProps(ctx, _opts) {
  // debug(`getInitialProps()`);
  // debug(ctx);
  const opts = _opts || {};
  const {
    req, res, asPath, query, pathname, store, isServer
  } = ctx;
  let pageProps = {
    namespacesRequired: opts.namespacesRequired || [], // for i18n
  };
  let fetchResult = {};
  let reqData = {
    pathname,
    asPath,
    query,
  };
  if (isServer) {
    // Run at Server
    fetchResult = req.fetchData ? await req.fetchData(reqData) : [];
  } else {
    // Run at Client
    const fetchData = opts.fetchData || _.noop;
    fetchResult = await fetchData(reqData);
  }
  pageProps = {
    ...reqData,
    ...pageProps,
    ...fetchResult,
  };
  // debug(`pageProps >> `, pageProps);
  return { ...pageProps };
}

export function setUserSession(user_session) {
  userSession = user_session;
  return localStorage.setItem("user_session", JSON.stringify(user_session));
}

export function getUserSession() {
  return userSession;
}

export function getLocalStorageUserSession() {
  const userSession = localStorage.getItem("user_session");
  return userSession === "undefined" ? null : userSession;
}

export function clearUserSession() {
  userSession = {};
  return localStorage.removeItem("user_session");
}

export function passwordValidation(password) {
  // min 8 letter password : {10,}
  // upper case letters : (?=.*[A-Z])
  // lower case letters :(?=.*[a-z])
  // a number : (?=.*\d)

  let regularExpression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
  return regularExpression.test(password);
}

export function getOrderStatus(status) {
  let displayStr = "";
  switch (status) {
    case "placed":
    case "pending":
      displayStr = "not_paid";
      break;
    case "pending_approve":
      displayStr = "pending_approve";
      break;
    case "payment_confirmed":
      displayStr = "payment_confirmed";
      break;
    case "expired":
      displayStr = "expired";
      break;
    case "voided":
    case "cancelled":
      displayStr = "cancelled";
      break;
    case "partial_payment_refund":
    case "partial_payment_refund_token":
    case "partial_payment_refund_offline":
      displayStr = "partial_payment_refund";
      break;
    case "payment_refund":
    case "payment_refund_token":
    case "payment_refund_offline":
      displayStr = "payment_refund";
      break;
    default:
      displayStr = "error";
      break;
  }
  return displayStr;
}

export const FORM_TYPE = {
  LOGIN: "login",
  REGISTER: "register",
  FORGET_PASSWORD: "forget_password",
  EDIT_PASSWORD: "edit_password",
  EDIT_EMAIL: "edit_email",
  EDIT_Mobile: "edit_mobile",
  EDIT_USERNAME: "edit_username"
};

export const OTP_TYPE = {
  USER_REGISTER: 1,
  USER_LOGIN: 2,
  FORGOT_PASSWORD: 3,
  UPDATE_USERNAME: 4,
};

export const areaCodeList = [
  { label: "+852", value: "+852" },
  { label: "+86", value: "+86" },
];

export const validateEmail = function (email) {
  let re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateMobile = function (mobile, prefix) {
  let re;
  if (!_.includes(["+852", "+86"], prefix)) {
    return false;
  }
  if (prefix === "+852") {
    re = /^1[0-9]{10}$|^[2-9][0-9]{7}$/;
  }
  if (prefix === "+86") {
    re = /^1\d{10}$/;
  }
  return re.test(mobile);
};

export const logout = (history, company_key) => {
  clearUserSession();
  getStore().dispatch(ActionCreators.setAuth(false));
  getStore().dispatch(ActionCreators.setOrderInfo({}));
  getStore().dispatch(ActionCreators.setUser({}));
  getStore().dispatch(ActionCreators.setUserSession({}));
  localStorage.removeItem("contactInfo");
  history.push(`/${company_key}`);
};

export const ITEM_TYPE = {
  1: "booking",
  2: "product",
  3: "event",
  4: "membership",
  5: "token",
};

export const getPaymentStatus = (value) => {
  let displayStr = "";
  let color = "000";
  switch (value) {
    case "placed":
    case "pending":
      displayStr = "not_paid";
      color = COLOR.warningRed;
      break;
    case "pending_approve":
      displayStr = "pending";
      color = COLOR.warningYellow;
      break;
    case "payment_confirmed":
      displayStr = "complete";
      color = COLOR.success.success400;
      break;
    case "partial_payment_refund":
    case "partial_payment_refund_token":
    case "partial_payment_refund_offline":
      displayStr = "partial_payment_refund";
      color = COLOR.warningYellow;
      break;
    case "expired":
    case "voided":
    case "cancelled":
    case "payment_refund":
    case "payment_refund_token":
    case "payment_refund_offline":
      displayStr = "cancelled";
      color = COLOR.warningRed;
      break;
    default:
      displayStr = "error";
      color = COLOR.warningRed;
      break;
  }
  return { displayStr, color };
};

export const LOGIN_METHOD = {
  username: 1,
  email: 2,
  mobile: 3
};

export const OTP_METHOD = {
  email: 1,
  mobile: 2
};

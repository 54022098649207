import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider, Row, Grid, Col, Modal, Spin
} from "antd";
import { useWindowWidth } from "@react-hook/window-size";
import { FONT, COLOR, BREAKPOINT } from "../Theme";
import ImageCarousel from "../components/bookingDetail/ImageCarousel";
import CollapseContainer from "../components/bookingDetail/CollaspeContainer";
import ReserveMobile from "../components/ReserveMobile";
import LoadingIcon from "../components/LoadingIcon";
import { useTranslation } from "react-i18next";

const BookingCategoryDetailMobilePage = ({
  categoryDetail,
  itemImgList,
}) => {
  const { t, i18n } = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const config = useSelector((state) => state.app.client.config);
  const [isImageModelVisible, setIsImageModalVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(1);
  return (
    <div className="booking-category-detail-mobile-page" style={{ marginBottom: isMobile && 50, padding: "4%" }}>
      <Row style={{ position: "relative" }}>
        <img alt="cover_image" src={`${config.MEDIA_URL}/${itemImgList?.[0]}`} style={{ width: "100%", height: isMobile ? "56.25vw" : 380, objectFit: "cover" }} />
        <div
          className="show-image-carousel"
          onClick={() => setIsImageModalVisible(true)}
        >
          {`+ ${itemImgList.length} ${t("photo")}`}
        </div>
      </Row>
      <Row gutter={32}>
        <Col xs={24} lg={16}>
          <Row style={{ ...styles.heading }}>{categoryDetail[`category_name_${i18n.language}`] || categoryDetail.category_name || ""}</Row>
          <CollapseContainer
            content={categoryDetail[`category_desc_${i18n.language}`] || categoryDetail.category_desc || ""}
            displayRow={10}
            showButtonJustify="end"
          />
          <Divider
            style={{
              borderTopColor: COLOR.function.divider,
              width: "100%",
            }}
          />
          <ReserveMobile />
          <Modal
            visible={isImageModelVisible}
            footer={null}
            onCancel={() => setIsImageModalVisible(false)}
            wrapClassName="image-carousel-modal"
          >
            <>
              <ImageCarousel
                coverImgArr={itemImgList}
                setImageIndex={setImageIndex}
                showDots={false}
              />
              <div className="image-carousel-detail">
                <div style={{
                  position: "absolute", bottom: 0, width: "100%", padding: 16
                }}
                >
                  <Row justify="space-between">
                    <Col>{categoryDetail[`category_name_${i18n.language}`] || categoryDetail.category_name || ""}</Col>
                    <Col>{`${imageIndex} / ${itemImgList.length}`}</Col>
                  </Row>
                </div>
              </div>
            </>
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  heading: {
    padding: "32px 0 24px 0",
    fontSize: 28,
    fontWeight: 700,
  },
  subHeading: {
    font: FONT.textMedium,
    color: COLOR.captionText,
  },
  showArrow: {
    color: COLOR.primary.primary500,
    fontSize: 10,
  },
};

export default BookingCategoryDetailMobilePage;

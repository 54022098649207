/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Spin
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  toInteger,
  orderBy,
  map
} from "lodash";
import { useWindowWidth } from "@react-hook/window-size";
import * as Service from "../core/Service";
import AppLayout from "../components/AppLayout";
import { FONT, COLOR, BREAKPOINT } from "../Theme";
import LoadingIcon from "../components/LoadingIcon";
import BookingCategoryDetailMobilePage from "./BookingCategoryDetailMobilePage";
import BookingCategoryDetailWebPage from "./BookingCategoryDetailWebPage";
import { setLoading } from "../redux/actions/common";

const BookingCategoryDetailPage = () => {
  const [categoryDetail, setCategoryDetail] = useState({});
  const [itemImgList, setItemImgList] = useState([]);
  const { company } = useSelector((state) => state.app);
  const { category: params, company_key } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(toInteger(params));
  const history = useHistory();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  useEffect(() => {
    if (company.company_id) {
      fetchData();
    }
  }, [company.company_id]);

  const fetchData = async () => {
    if (selectedCategory === 0) {
      return history.replace(`/${company_key}`);
    }
    getCategoryDetail();
  };

  const getCategoryDetail = async () => {
    dispatch(setLoading(true));
    try {
      const resp = await Service.call(
        "get",
        `/api/booking/v1/category/detail/${params}`
      );
      if (resp.status === 1) {
        const {
          bookingCategoryRc,
          bookingItemImageArr
        } = resp.data;
        const sortedImage = map(orderBy(
          bookingItemImageArr,
          ["sorting"],
          ["desc"]
        ), "filepath");

        setCategoryDetail(bookingCategoryRc);
        setItemImgList(sortedImage);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <AppLayout>
      {isMobile ? (
        <BookingCategoryDetailMobilePage
          categoryDetail={categoryDetail}
          itemImgList={itemImgList}
        />
      ) : (
        <BookingCategoryDetailWebPage
          categoryDetail={categoryDetail}
          itemImgList={itemImgList}
        />
      )}
    </AppLayout>
  );
};

const styles = {
  desktopSubheading: {
    ...FONT.h6,
    color: "#9E8677",
    borderBottom: `2px solid ${COLOR.primary.primary500}`,
    margin: "0 20px",
    cursor: "pointer",
  },
  mobileSubheading: {
    ...FONT.textBold,
    borderBottom: "2px solid #9E8677",
    paddingBottom: 5,
    color: "#9E8677",
    margin: "20px 0",
    cursor: "pointer",
  },
};

export default BookingCategoryDetailPage;

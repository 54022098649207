import {
  Button,
  Col,
  Divider,
  Image,
  InputNumber,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  DownOutlined,
  UpOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { setAppModal } from "../redux/actions/common";

import LoadingIcon from "../components/LoadingIcon";
import ImageBanner from "../components/ImageBanner";
import RegistrationForm from "../components/event/RegistrationForm";
import ModalDrawerContainer from "../components/bookingDetail/ModalDrawerContainer";
import AvailabilityList from "../components/AvailabilityList";
import * as Service from "../core/Service";
import { COLOR, FONT } from "../Theme";
import { NoRecord } from "./OrderReceiptPage";
import CollapseContainer from "../components/bookingDetail/CollaspeContainer";

import { checkAvailabilityStatus, setColor } from "../components/ReserveMobile";

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const { Paragraph } = Typography;

const EventMobilePage = ({ eventData }) => {
  const {
    eventInfo,
    eventMeta,
    eventSection,
    eventBannerList
  } = eventData;

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const { company, user } = useSelector((state) => state.app);
  const [bookingLoading, setBookingLoading] = useState(false);

  const eventInRegister = _.filter(
    eventSection,
    ({ registration_end_date }) => (
      registration_end_date > moment().unix())
  ).sort((a, b) => a.event_start_date - b.event_start_date);

  const getEventDetail = () => {
    const selectedEventSection = _.map(selectedItem, (value, key) => {
      let selectedSection = _.find(
        eventSection,
        (section) => section?.event_date_id === +key
      );
      selectedSection.quantity = value;
      const {
        quantity,
        event_start_date,
        event_end_date,
        event_price,
        event_date_id,
      } = selectedSection;
      return {
        quantity,
        event_start_time: event_start_date,
        event_end_time: event_end_date,
        event_price,
        event_date_id,
      };
    });
    return selectedEventSection;
  };

  const bookEventNow = async (values) => {
    setBookingLoading(true);
    try {
      const { event_id } = eventInfo;
      const putObj = {
        item_id: event_id,
        item_type: 3,
        event_date_list: getEventDetail(),
        registration_info: { ...values },
      };
      const resp = await Service.call("put", "/api/order", putObj);
      if (resp?.status === 1) {
        const { orderRc } = resp;
        notification.success({
          message: t("success"),
        });
        return history.push(
          `/${company.company_key}/payment/${orderRc?.order_id || 0}`
        );
      }
      notification.error({
        message: t("error"),
        description: t(resp?.errorCode),
      });
    } catch (err) {
      console.error(err);
    } finally {
      setBookingLoading(false);
    }
  };

  const bookNow = (values) => {
    if (!user.isUser) {
      return dispatch(
        setAppModal({
          appModalVisible: true,
          type: "login",
        })
      );
    }
    bookEventNow(values);
  };

  return (
    <div className="event-mobile-page">
      <ImageBanner imgArr={eventBannerList} />
      <div style={{ padding: 16 }}>
        <EventInfoSection eventInfo={eventInfo} company={company} />
        <Divider style={{ ...styles.divider }} />
        <PackageOptionsSection
          eventSection={eventInRegister}
          item={selectedItem}
          selectedItem={(value) => setSelectedItem({ ...value })}
        />
        {!_.isEmpty(selectedItem) && user.isUser ? (
          <div className="registration-form-section">
            <RegistrationForm
              eventMeta={eventMeta}
              buttonStyle={{ ...styles.button }}
              buttonTitle={t("book_now")}
              onFinish={(values) => bookNow(values)}
              loading={bookingLoading}
            />
          </div>
        ) : (
          <div>
            {!_.isEmpty(eventInRegister) && (
              <BookingButton
                loading={bookingLoading}
                disabled={_.isEmpty(selectedItem)}
                title={t("book_now")}
                clickAction={(values) => bookNow(values)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventMobilePage;

const EventInfoSection = ({ eventInfo, company }) => {
  const { event_name = "", event_description = "" } = eventInfo;

  return (
    <Row gutter={[0, 12]}>
      <Col span={24} style={{ ...styles.title }}>
        {event_name}
      </Col>
      <Col span={24}>
        <Space size={4}>
          By
          <div style={{ ...styles.primaryColor }}>{company?.company_name}</div>
        </Space>
      </Col>
      <Col span={24}>
        <CollapseContainer
          content={event_description}
          displayRow={10}
          showButtonJustify="end"
        />
      </Col>
    </Row>
  );
};

const PackageOptionsSection = ({ eventSection = [], selectedItem, item }) => {
  const { t } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSection, setSelectedSection] = useState({});

  return (
    <Row gutter={[0, 12]}>
      <Col span={24} style={{ ...styles.title }}>
        {t("package_options")}
      </Col>
      <Col span={24} style={{ fontSize: 16 }}>
        {t("event_type")}
      </Col>
      <AvailabilityList />
      <Col span={24}>
        <Row gutter={[0, 16]} style={{ width: "100%" }} justify="center">
          {eventSection.length < 1 ? (
            <NoRecord minHeight={200} />
          ) : (
            <>
              {_.map(eventSection, (section, i) => (
                <OptionItem
                  key={i}
                  section={section}
                  item={item}
                  selectedItem={selectedItem}
                  setSelectedSection={setSelectedSection}
                  setDrawerVisible={setDrawerVisible}
                />
              ))}
            </>
          )}
          <EventInfoDrawer
            data={selectedSection}
            visible={drawerVisible}
            setVisible={setDrawerVisible}
          />
        </Row>
      </Col>
    </Row>
  );
};

export const BookingButton = ({
  title, clickAction, disabled, formItem
}) => {
  return (
    <Button
      type="primary"
      disabled={disabled}
      onClick={clickAction}
      style={{ ...styles.button }}
      htmlType={formItem ? "submit" : ""}
    >
      {title}
    </Button>
  );
};

const ListItem = ({ title, value }) => {
  return (
    <Row justify="space-between">
      <Col style={{ ...styles.secondaryColor }}>{title}</Col>
      <Col>{value}</Col>
    </Row>
  );
};

const OptionItem = ({
  section,
  selectedItem,
  item,
  setSelectedSection,
  setDrawerVisible,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const {
    event_date_id,
    is_active = 1,
    registration_start_date,
    registration_count = 0,
    event_applicants_count = 0,
    event_start_date,
    event_end_date,
    event_price,
    maximum_quantity
  } = section;

  const remaining = event_applicants_count - registration_count;

  const availabilityStatus = checkAvailabilityStatus(
    event_applicants_count,
    registration_count,
    is_active === 1,
    "event"
  );
  const colorStyle = setColor(
    event_date_id,
    _,
    availabilityStatus,
    true
  ).colorStyle;

  const cardColorStyle = {
    darkToPrimary: COLOR.text.textPrimary,
    secondaryToPrimary: COLOR.brandSecondary,
  };

  return (
    <Col span={24} style={{ position: "relative" }}>
      <div
        style={{
          ...styles.packageOptionCard,
        }}
      >
        <Button
          disabled={!is_active}
          className="event-availability-button"
          style={{ ...colorStyle, ...styles.availabilityBtn }}
        >
          <Row gutter={[0, 4]} style={{ padding: "8px 0" }}>
            <Col span={24}>
              <Paragraph
                ellipsis={{ rows: 1 }}
                style={{
                  color: cardColorStyle.darkToPrimary,
                  font: FONT.smallText,
                  margin: "0 auto",
                }}
              >
                {t("start_date")}
              </Paragraph>
            </Col>
            <Col span={24} style={{ color: COLOR.text.primaryText, whiteSpace: "break-spaces" }}>
              {`${moment
                .unix(event_start_date)
                .format("DD-MM-YYYY HH:mm")} to ${moment
                  .unix(event_end_date)
                  .format("DD-MM-YYYY HH:mm")}`}
            </Col>
            <Col
              span={24}
              style={{
                ...styles.positionCenter,
              }}
            >
              <div style={{ color: cardColorStyle.darkToPrimary }}>
                $
                {event_price}
              </div>
              <div>
                <Image
                  src="/event/minus_icon.svg"
                  alt="-"
                  preview={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    // negative number
                    if (value < 1) {
                      return;
                    }
                    // zero
                    if (value === 1) {
                      delete item[event_date_id];
                      selectedItem({ ...item });
                      setValue(0);
                      return;
                    }
                    selectedItem({ ...item, [event_date_id]: value - 1 });
                    setValue((value) => value - 1);
                  }}
                />
                <InputNumber
                  min={0}
                  max={maximum_quantity}
                  value={value}
                  style={{
                    ...styles.cardInputNumber,
                  }}
                  onChange={(value) => setValue(_.toInteger(value))}
                />
                <Image
                  src="/event/plus_icon.svg"
                  slt="+"
                  preview={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    const qty = value >= maximum_quantity ? maximum_quantity : value + 1;
                    setValue(qty);
                    selectedItem({ ...item, [event_date_id]: qty });
                  }}
                />
              </div>
            </Col>
          </Row>
        </Button>
        <Button
          type="primary"
          style={{
            ...styles.primaryColor,
            ...styles.cardBottomBtn,
          }}
          onClick={() => {
            setSelectedSection(section);
            setDrawerVisible(true);
          }}
        >
          {t("show_detail")}
        </Button>
        {event_applicants_count > 0 && remaining === 0 && (
          <CardOverlay
            text={t("registration_full")}
          />
        )}
        {registration_start_date > moment().unix() && (
          <CardOverlay
            text={t("coming_soon")}
            message={(
              <Space size={4}>
                {t("registration_start")}
                {moment
                  .unix(registration_start_date)
                  .format("YYYY-MM-DD HH:mm")}
              </Space>
            )}
          />
        )}
      </div>
    </Col>
  );
};

const CardOverlay = ({ text, message }) => {
  return (
    <Row
      justify="center"
      align="middle"
      gutter={[0, 8]}
      style={{
        ...styles.cardMask,
      }}
    >
      <Col style={{ ...FONT.bodyText1Bold }}>{text}</Col>
      <Col>{message}</Col>
    </Row>
  );
};

const EventInfoDrawer = ({ data, visible, setVisible }) => {
  const {
    registration_start_date,
    registration_end_date,
    event_display_applicants,
  } = data;
  const { t } = useTranslation();
  const unixStartDate = moment.unix(registration_start_date);
  const unixEndDate = moment.unix(registration_end_date);
  return (
    <ModalDrawerContainer
      title={t("event_info")}
      visible={visible}
      onClose={() => setVisible(false)}
      footer={null}
      headerStyle={{ textAlign: "center", fontWeight: "bold" }}
      bodyStyle={{ padding: 16 }}
    >
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <ListItem
            title={t("start_registration")}
            value={(
              <Space>
                {unixStartDate.format("YYYY-MM-DD")}
                <ClockCircleOutlined />
                {unixStartDate.format("HH:mm")}
              </Space>
            )}
          />
        </Col>
        <Col span={24}>
          <ListItem
            title={t("end_registration")}
            value={(
              <Space>
                {unixEndDate.format("YYYY-MM-DD")}
                <ClockCircleOutlined />
                {unixEndDate.format("HH:mm")}
              </Space>
            )}
          />
        </Col>
      </Row>
      <Divider style={{ ...styles.divider, margin: "12px 0" }} />
      <Row justify="end">
        <Space size={4}>
          {event_display_applicants > 0 && (
            <>
              {t("total")}
              :
            </>
          )}
          <img
            src="/event/ticket.svg"
            width={16}
            height={16}
            alt=""
            style={{ verticalAlign: "text-top" }}
          />
          {event_display_applicants === 0 ? t("unlimited") : event_display_applicants}
        </Space>
      </Row>
    </ModalDrawerContainer>
  );
};

const styles = {
  title: {
    fontSize: 28,
    fontWeight: "bold",
  },
  primaryColor: {
    color: COLOR.brandPrimary,
  },
  secondaryColor: {
    color: COLOR.captionText,
  },
  availabilityLabel: {
    width: 8,
    height: 12,
    borderRadius: 4,
    marginRight: 8,
  },
  divider: {
    background: COLOR.function.divider,
  },
  button: {
    background: COLOR.primary.primary700,
    borderRadius: 8,
    width: "100%",
    height: 48,
    lineHeight: "24px",
    color: "white",
    marginTop: 16,
  },
  showArrow: {
    color: COLOR.primary.primary500,
    fontSize: 10,
  },
  packageOptionCard: {
    background: "#fbf9f9",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  cardMask: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0.45)",
    color: "#fff",
    flexDirection: "column",
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
  },
  cardBottomBtn: {
    textAlign: "center",
    padding: "8px 16px",
    cursor: "pointer",
    border: "none",
    outline: "none",
    width: "100%",
    background: "transparent",
    lineHeight: "7px",
  },
  cardInputNumber: {
    border: "none",
    outline: "none",
    background: "transparent",
    margin: "0 8px",
    width: 50,
  },
  positionCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  selectedCard: {
    background: "#E1F4FF",
    // border: "1px solid #1E73BE",
  },
  availabilityBtn: {
    color: "#000",
    height: "100%",
    width: "100%",
    textAlign: "left",
    outline: "none",
    cursor: "default",
  },
};

import React, { Component, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from "react-router-dom";
import { Layout, Menu, Avatar, Modal, Row, Col, Button, Grid } from "antd";
import "antd/dist/antd.min.css";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import _, { each } from "lodash";
import ShoppingCartDrawer from "./ShoppingCartDrawer";
import { COLOR, FONT } from "../Theme";
import { useTranslation } from "react-i18next";

const { Header } = Layout;

const DirectBar = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    client,
    company,
    companySiteMeta,
    shoppingCartAmount
  } = useSelector((state) => state.app);

  const buttonProps = {
    home: {
      key: "home",
      direct: `/${company.company_key}`,
    },
    news: {
      key: "news",
      direct: `/${company.company_key}/news`,
    },
    booking: {
      key: "booking",
      direct: `/${company.company_key}/booking`,
    },
    token: {
      key: "token",
      direct: `/${company.company_key}/token`,
    },
    event: {
      key: "event",
      direct: `/${company.company_key}/event/list`,
    },
    membership: {
      key: "membership",
      direct: `/${company.company_key}/membership/list`,
    },
    blog: {
      key: "blog_post",
      direct: `/${company.company_key}/blog_post`,
    },
    contact_us: {
      key: "contact_us",
      direct: `/${company.company_key}/contact/us`,
    },
  };

  const renderDirectButton = () => {
    return _.map(buttonProps, (eachButton, key) => {
      if (_.includes(["booking", "event", "token", "membership"], key) && !company[`allow_${key}`]) {
        return;
      }
      if (_.includes(["blog", "news"], key) && !_.toInteger(companySiteMeta[`${eachButton.key}_enable`])) {
        return;
      }
      return (
        <Col
          key={key}
          span={3}
          style={{ textAlign: "center" }}
        >
          <Button
            type="ghost"
            style={{
              ...FONT.textMedium,
              borderWidth: 0,
              backgroundColor: COLOR.function.background,
              color: COLOR.text.captionText,
            }}
            onClick={() => history.push(eachButton.direct)}
          >
            {t(key)}
          </Button>
        </Col>
      );
    });
  };

  return (
    <Row>
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={16}
        style={{ backgroundColor: COLOR.function.background, height: 56 }}
      >
        <Row
          justify="start"
          style={{ height: "100%" }}
          align="middle"
        >
          {renderDirectButton()}
        </Row>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={8}
        style={{ backgroundColor: COLOR.function.background, height: 56 }}
      >
        <Row
          justify="end"
          style={{ marginRight: "8%", height: "100%" }}
          align="middle"
        >
          <Col>
            <Button
              style={{
                ...FONT.textMedium,
                borderWidth: 0,
                backgroundColor: COLOR.function.background,
                color: COLOR.text.captionText,
              }}
              onClick={() => history.push(`/${company.company_key}/shopping_cart`)}
            >
              {`${t("cart")}  ( ${shoppingCartAmount || 0} ) `}
              <img
                src={
                  shoppingCartAmount > 0
                    ? `${client?.config?.STATIC_SERVER_URL}/cart_active.svg`
                    : `${client?.config?.STATIC_SERVER_URL}/cart.svg`
                }
                height="100%"
                alt=""
                style={{ marginLeft: 10 }}
              />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DirectBar;

import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  notification
} from "antd";
import {
  startsWith,
  endsWith,
  split
} from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formItemLayout } from "../ModalLayout";
import userIcon from "../../assets/user.svg";
import emailIcon from "../../assets/email.svg";
import mobileIcon from "../../assets/mobile-alt.svg";
import editIcon from "../../assets/edit.svg";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import { setLoading, setUser } from "../../redux/actions/common";

const EditProfileDesktopView = ({
  handleSubmit
}) => {
  const { company, user } = useSelector((state) => state.app);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { nickname, email: emailAddress, mobile } = user;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [mobilePrefix, mobileNumber] = split(mobile, "-");
  useEffect(() => {
    dispatch(setLoading(true));
    form.setFieldsValue({
      nickname,
      email: startsWith(emailAddress, "user") && endsWith(emailAddress, "@vms.io") ? "" : emailAddress,
      mobilePrefix: mobilePrefix === "+0" ? "" : mobilePrefix,
      mobile: mobilePrefix === "+0" ? "" : mobileNumber,
    });
    dispatch(setLoading(false));
  }, [user]);

  const onFinish = async (formData) => {
    setButtonLoading(true);
    try {
      await handleSubmit(formData);
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="time_related_controls"
      className="edit-profile-form"
      onFinish={(obj) => onFinish(obj)}
    >
      <InputItem name="nickname" label={t("display_name")} icon={userIcon} required message="require_username" />
      <InputItem name="email" label="email" icon={emailIcon} required={company.login_method === Main.LOGIN_METHOD.email} message="require_a_valid_email" />
      <Row gutter={[15, 0]}>
        <Col span={5}>
          <Form.Item
            name="mobilePrefix"
            label={t("phone")}
            rules={[{
              required: company.login_method === Main.LOGIN_METHOD.mobile,
              message: t("require_a_valid_mobile"),
            }]}
          >
            <Select
              showArrow={false}
              options={Main.areaCodeList}
              style={styles.inputContainer}
            />
          </Form.Item>
        </Col>

        <Col span={19}>
          <Form.Item
            name="mobile"
            label={t("phone_number")}
            rules={[{
              required: company.login_method === Main.LOGIN_METHOD.mobile,
              message: t("require_a_valid_mobile"),
            }]}
          >
            <Input
              placeholder={t("enter_mobile")}
              prefix={<img style={{ margin: "0px 5px", }} src={mobileIcon} alt="prefix_icon" />}
              suffix={<img src={editIcon} alt="" />}
              style={styles.inputContainer}
              allowClear
            // onChange={(evt) => {
            //   setMobile({
            //     mobilePrefix: form.getFieldValue("mobilePrefix"),
            //     mobile: evt.target.value
            //   });
            // }}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <InputItem name="password" label="password" icon={userIcon} /> */}
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          htmlType="submit"
          className="save-btn"
          style={{ width: "20%" }}
          loading={buttonLoading}
        >
          {t("save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

const InputItem = (props) => {
  const {
    name, label, icon, required, message
  } = props;
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={[{ required, message: t(message) }]}
    >
      <Input prefix={<img src={icon} alt="" style={{ margin: "0px 5px" }} />} suffix={<img src={editIcon} alt="" />} />
    </Form.Item>
  );
};

const styles = {
  inputContainer: {
    borderRadius: "8px",
    backgroundColor: "#FFF",
    height: "46px",
  }
};

export default EditProfileDesktopView;

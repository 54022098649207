import React, { useState } from "react";
import { Button, Checkbox } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import _ from "lodash";

import OrderSummarySection from "../components/payment/OrderSummarySection";
import ContactInfoSection from "../components/payment/ContactInfoSection";
import PaymentMethodSection from "../components/payment/PaymentMethodSection";
import { COLOR } from "../Theme";
import ReminderModal from "../components/payment/ReminderModal";
import PaymentBreadcrumb from "../components/payment/PaymentBreadcrumb";
import { ITEM_TYPE } from "../constants";

const PaymentMobilePage = ({
  step,
  setStep,
  setNeedUpdate,
  orderInfo,
  orderDetail,
  paymentType,
  useToken,
  setUseToken,
  token,
  setToken,
  total,
  balance,
  confirmPayment,
  setContactInfo,
  contactInfo,
  setIsAgree,
  setReminderVisible,
  isAgree,
  reminderVisible,
}) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const { company } = app;
  const { STATIC_SERVER_URL } = app.client.config;
  const { order_id: params } = useParams();
  const order_id = _.toInteger(params);
  const isBooking = _.first(orderInfo?.order_type) === ITEM_TYPE.BOOKING;

  const [buttonLoading, setButtonLoading] = useState(false);

  return (
    <div className="payment-page">
      {/* Loading view */}

      {/* Header Breadcrumb */}
      <PaymentBreadcrumb step={step} style={{ ...styles.breadcrumb }} />

      {/* OrderSummary */}
      <OrderSummarySection
        step={step}
        orderDetail={orderDetail}
        orderInfo={orderInfo}
        paymentType={paymentType}
        setNeedUpdate={setNeedUpdate}
        useToken={useToken}
        setUseToken={setUseToken}
        token={token}
        setToken={setToken}
        total={total}
      />
      {/* Contact Info */}
      {isBooking && (
        <ContactInfoSection
          step={step}
          setStep={setStep}
          contactInfo={contactInfo}
          setContactInfo={setContactInfo}
          order_id={order_id}
        />
      )}
      {!isBooking && step === 1 && (
        <div style={{ margin: "16px 0", textAlign: "right" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="action-btn"
            style={{ width: "fit-content" }}
            onClick={() => setStep(2)}
          >
            {t("next")}
          </Button>
        </div>
      )}
      {/* Payment Method Selection */}
      {balance > 0 && step === 2 && (
        <PaymentMethodSection
          order_id={order_id}
          order_key={orderInfo?.order_key || ""}
          paymentType={paymentType}
          useToken={useToken}
          setUseToken={setUseToken}
          token={token}
          setToken={setToken}
          balance={balance}
          setNeedUpdate={setNeedUpdate}
          expiry_time={orderInfo.expiry_time}
        />
      )}

      {/* Free payment */}
      {balance === 0 && step === 2 && (
        <div style={{ padding: 16 }}>
          <div style={{ margin: "16px 0" }}>
            <Checkbox
              checked={isAgree}
              style={{ color: COLOR.captionText }}
              onChange={() => setIsAgree(!isAgree)}
            >
              {t("i_agree")}{" "}
              <a
                href={`${STATIC_SERVER_URL}/${company.company_key}/terms/conditions`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                style={{ color: COLOR.brandPrimary }}
              >
                {t("t_of_s_agreement")}
              </a>
            </Checkbox>
          </div>
          <Button
            type="primary"
            disabled={!isAgree}
            className="action-btn"
            style={{
              margin: 0,
            }}
            loading={buttonLoading}
            onClick={async () => {
              setButtonLoading(true);
              await confirmPayment();
              setButtonLoading(false);
            }}
          >
            {t("confirm_payment")}
          </Button>
        </div>
      )}
      <ReminderModal
        visible={reminderVisible}
        setVisible={setReminderVisible}
      />
    </div>
  );
};

export default PaymentMobilePage;

const styles = {
  breadcrumb: {
    marginBottom: "24px",
    padding: "0 16px",
  },
};

import React, { useRef } from "react";
import { Grid, Row, Col } from "antd";
import Icon from '@ant-design/icons';
import _ from 'lodash';
import { useWindowWidth } from "@react-hook/window-size";
import Carousel from 'react-multi-carousel';
import ArrowLeft from '../../assets/arrow_left.svg';
import ArrowRight from '../../assets/arrow_right.svg';
import ImageDot from '../ImageDot';
import { useSelector } from "react-redux";
import { BREAKPOINT } from "../../Theme";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CustomRightArrow = ({ carouselNewItem }) => {
  return (
    <Icon
      component={() => <img alt="arrow-right" src={ArrowRight} style={{ width: '100%' }} />}
      style={{
        position: 'absolute',
        right: 10,
        // top: '48%',
        alignItems: 'center',
        zIndex: 3
      }}
      className="responsive-arrow"
      onClick={() => {
        carouselNewItem.current.next();
      }}
    />
  );
}

const CustomLeftArrow = ({ carouselNewItem }) => {
  return (
    <Icon
      component={() => <img alt="arrow-left" src={ArrowLeft} style={{ width: '100%' }} />}
      style={{
        position: 'absolute',
        left: 10,
        // top: '48%',
        alignItems: 'center',
        color: '#fff',
        zIndex: 3
      }}
      className="responsive-arrow"
      onClick={() => {
        carouselNewItem.current.previous();
      }}
    />
  );
}

const ImageCarousel = (props) => {
  const { coverImgArr, setImageIndex, showDots, customButtonGroup } = props;
  const config = useSelector(state => state.app.client.config);
  const carouselNewItem = useRef();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  return (
    <Carousel
      ref={carouselNewItem}
      responsive={responsive}
      shouldResetAutoplay={false}
      keyBoardControl
      showDots={showDots ?? !isMobile}
      renderDotsOutside
      dotListClass="image-dot-list"
      customLeftArrow={<CustomLeftArrow carouselNewItem={carouselNewItem} />}
      customRightArrow={<CustomRightArrow carouselNewItem={carouselNewItem} />}
      customDot={<ImageDot product_image={coverImgArr} />}
      customTransition="transform 500ms ease-in-out"
      afterChange={(previousSlide, { currentSlide }) => setImageIndex(currentSlide + 1)}
      customButtonGroup={customButtonGroup && <ButtonGroup coverImgArr={coverImgArr} setImageIndex={setImageIndex} carouselNewItem={carouselNewItem} />}
    >
      {_.map(coverImgArr, (item, index) => {
        return (
          <img key={index} draggable={false} alt="cover_image" src={`${config.MEDIA_URL}/${item}`} style={{ width: "auto", height: isMobile ? '56.25vw' : 480, objectFit: "contain", margin: "auto", display: "block", borderRadius: 8 }} />
        );
      })}
    </Carousel>
  )
}

const ButtonGroup = ({ coverImgArr, setImageIndex, carouselNewItem, next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const config = useSelector(state => state.app.client.config);

  const customDotImageLength = 6;
  const buttonGroupResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: customDotImageLength,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const onHandleImageDotOption = (index) => {
    setImageIndex(index + 1);
    carouselNewItem.current.goToSlide(index);
  };

  return (
    <Carousel
      arrows={false}
      responsive={buttonGroupResponsive}
      transitionDuration={500}
      containerClass="btn-dots-carousel-container"
      itemClass="image-dots-carousel-item"
      shouldResetAutoplay={false}
      focusOnSelect={coverImgArr.length > customDotImageLength}
      infinite
    >
      {_.map(coverImgArr, (image, index) => {
        return (
          <Row
            gutter={2}
            key={`image-dot-${index}`}
            onClick={() => onHandleImageDotOption(index)}
          >
            <Col span={23}>
              <img draggable={false} alt="cover_image" src={`${config.MEDIA_URL}/${image}`} style={{ width: "auto", height: 100, objectFit: 'contain', borderRadius: 8 }} className={currentSlide === index ? "btn-dots-active" : "btn-dots"} />
            </Col>
          </Row>
        );
      })}
    </Carousel>
  );
};

export default ImageCarousel;

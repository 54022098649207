import React, { useState } from "react";
import * as Main from "../../core/Main";
import BookingOrderDetail from "./BookingOrderDetail";
import MembershipOrderDetail from "./MembershipOrderDetail";
import EventOrderDetail from "./EventOrderDetail";
import TokenOrderDetail from "../token/TokenOrderDetail";
import CancelOrderModal from "../CancelOrderModal";

const OrderDetailCard = ({
  data,
  containerStyle,
  isCartItem,
  onDelete,
  itemStyle,
  orderStatus,
  setNeedUpdate,
}) => {
  const [cancelTarget, setCancelTarget] = useState({});
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  return (
    <div
      style={{
        // borderTop: "1px solid #E7E8E8",
        // borderBottom: "1px solid #E7E8E8",
        ...containerStyle
      }}
    >
      {Main.ITEM_TYPE[data?.item_type] === "booking" && (
        <BookingOrderDetail
          data={data}
          isCartItem={isCartItem}
          onDelete={(value) => onDelete(value)}
          itemStyle={itemStyle}
          orderStatus={orderStatus}
          setCancelTarget={setCancelTarget}
          setCancelModalVisible={setCancelModalVisible}
        />
      )}
      {Main.ITEM_TYPE[data?.item_type] === "membership" && (
        <MembershipOrderDetail data={data} isCartItem={isCartItem} />
      )}
      {Main.ITEM_TYPE[data?.item_type] === "event" && (
        <EventOrderDetail
          data={data}
          isCartItem={isCartItem}
          orderStatus={orderStatus}
          setCancelTarget={setCancelTarget}
          setCancelModalVisible={setCancelModalVisible}
        />
      )}
      {Main.ITEM_TYPE[data?.item_type] === "token" && (
        <TokenOrderDetail data={data} />
      )}
      <CancelOrderModal
        orderStatus={orderStatus}
        cancelTarget={cancelTarget}
        type="order"
        mode="item"
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        setNeedUpdate={() => {
          setNeedUpdate(true);
          setCancelTarget({});
        }}
      />
    </div>
  );
};

export default OrderDetailCard;

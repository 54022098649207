import React, { useEffect, useState } from "react";
import {
  Spin
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import * as Service from "../../core/Service";

const PayPalContainer = (props) => {
  const {
    confirmPayment,
    setPaymentStatus,
  } = props;
  // This values are the props in the UI
  // const style = {"layout":"vertical"};
  const [{ isResolved }] = usePayPalScriptReducer();

  return (
    <Spin spinning={!isResolved}>
      <PayPalButtons
        createOrder={async () => {
          // Call api save paypal record
          let ptxObj = await confirmPayment();
          return ptxObj.orderId;
        }}
        onApprove={async (data, actions) => {
          try {
            const resp = await Service.call("post", "/api/payment/paypal/confirm-payment", { orderId: data.orderID });
            if (resp.status === 1) {
              setPaymentStatus("success");
            } else {
              setPaymentStatus("fail");
            }
          } catch (err) {
            console.error(err);
          }
        }}
      />
    </Spin>
  );
};

export default PayPalContainer;

import React from 'react';
import styled from 'styled-components';
import {Button as antdButton} from 'antd'

const StyledButton = styled(antdButton)`
  color: white;
  background: ${({theme_color}) => (theme_color || "grey")};
  border-color: ${({theme_color}) => (theme_color || "grey")};
  :hover, :focus {
    color: ${({theme_color}) => (theme_color || "grey")};
    background: #fff;
    border-color: ${({theme_color}) => (theme_color || "grey")};
  }
`;

const Button = (props) => {
  return (
    <StyledButton
      {...props}
    />
  )
}

export default Button;

import React, { useEffect, useState } from "react";
import { Spin, message } from "antd";
import { Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  split, first, compact, isEmpty
} from "lodash";
import { useParams, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { ActionCreators } from "./redux/actions";
import LoadingIcon from "./components/LoadingIcon";
import { setCompanyInfo } from "./redux/actions/common";
import * as Service from "./core/Service";

const PublicRoute = ({
  component: Component,
  restricted,
  app,
  privilege,
  no_guest,
  ...rest
}) => {
  const { company, user } = app;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const company_key = first(compact(split(location.pathname, "/")));
  const { i18n } = useTranslation();
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false);

  useEffect(() => {
    if (isEmpty(company)) {
      dispatch(setCompanyInfo(company_key, history));
    }
    if (company.company_id && !user.company_user_id > 0) {
      restoreUserSession();
    }
    i18n.init({ lng: company.company_language }).then(() => {
      setIsLanguageInitialized(true);
    });
  }, [company]);

  const restoreUserSession = async () => {
    try {
      const userInfo = await Service.quickAccess(history);

      if (privilege === "user" && !userInfo) {
        return history.replace(`/${company_key}`);
      }
      if (userInfo && userInfo.is_guest && no_guest) {
        history.replace(`/${company_key}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isEmpty(company) || !isLanguageInitialized || (privilege === "user" && !user.company_user_id > 0)) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <Spin indicator={<LoadingIcon />} />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (<Component />)}
    />
  );
};

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicRoute);

import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { COLOR } from "../../Theme";

function MainImageDot({ onClick, ...rest }) {
  const { index, imageArr, active } = rest;

  const [productImages, setProductImages] = useState([]);
  const config = useSelector((state) => state.app.client.config);

  useEffect(() => {
    const allImage = [];
    _.map(imageArr, (eachImage) => {
      allImage.push(
        <div
          style={{
            width: 16,
            height: 16,
            borderRadius: 45,
            cursor: 'pointer',
            backgroundColor: COLOR.function.white,
          }}
        />
      );
    });
    setProductImages(allImage);
  }, []);

  return (
    <div
      style={{
        marginRight: index !== productImages.length - 1 ? 40 : 0,
        cursor: "pointer",
        marginBottom: 75,
      }}
      className={active ? "image-dot-active" : "image-dot"}
      onClick={() => onClick()}
    >
      {productImages[index]}
    </div>
  );
}

export default MainImageDot;

import React, { useEffect, useState } from "react";
import { Grid } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import { useTranslation } from "react-i18next";
import AppLayout from "../components/AppLayout";
import * as Service from "../core/Service";
import { setLoading } from "../redux/actions/common";
import { BREAKPOINT } from "../Theme";
import MembershipListWebPage from "./MembershipListWebPage";
import MembershipListMobilePage from "./MembershipListMobilePage";

const MembershipListContainer = () => {
  const { company, auth } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { i18n } = useTranslation();

  const [membershipList, setMembershipList] = useState([]);

  useEffect(() => {
    if (company.company_id && auth !== null) {
      getMembershipList();
    }
  }, [company, auth]);

  const getMembershipList = async () => {
    dispatch(setLoading(true));
    try {
      let url = "/api/membership/list";
      let resp = await Service.call("GET", url);
      if (!resp || resp.status !== 1) {
        return;
      }
      const { membershipList: list } = resp;
      setMembershipList(list);
    } catch (err) {
      console.error(err.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangeLanguage = (dataSet, target) => {
    const language = i18n.language;

    const metaValue = dataSet[`${target}_${language}`] || dataSet[target] || "";
    return metaValue;
  };

  return (
    <AppLayout>
      {isMobile ? (
        <MembershipListMobilePage
          membershipList={membershipList}
          handleChangeLanguage={handleChangeLanguage}
        />
      ) : (
        <MembershipListMobilePage
          membershipList={membershipList}
          handleChangeLanguage={handleChangeLanguage}
        />
      )}
    </AppLayout>
  );
};

export default MembershipListContainer;

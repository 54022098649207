import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Divider,
  Row,
  Space,
  notification,
  Grid
} from "antd";
import {
  filter,
  includes,
  groupBy,
  toInteger,
  map,
  sumBy,
  compact,
  uniqBy,
  isEmpty
} from "lodash";
import moment from "moment";
import { useWindowWidth } from "@react-hook/window-size";
import { setLoading, setQRCodeVisible } from "../redux/actions/common";
import * as Service from "../core/Service";
import * as Main from "../core/Main";
import { BREAKPOINT, COLOR } from "../Theme";
import CancelOrderModal from "../components/CancelOrderModal";
import OtherItemCarousel from "../components/OtherItemCarousel";
import AppLayout from "../components/AppLayout";
import { ListItem } from "./OrderReceiptPage";
import OrderCardWrapper from "../components/order/OrderCardWrapper";
import OrderDetailCard from "../components/order/OrderDetailCard";

const OrderDetailPage = () => {
  const { user } = useSelector((state) => state.app);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { order_id: params, company_key } = useParams();
  const order_id = toInteger(params);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  const [orderInfo, setOrderInfo] = useState({ order_type: [] });
  const [orderDetail, setOrderDetail] = useState({});
  const [needUpdate, setNeedUpdate] = useState(true);

  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    fetchData();
  }, [order_id, needUpdate, user]);

  const fetchData = async () => {
    if (order_id === 0) {
      return history.replace(`/${company_key}`);
    }
    if (needUpdate) {
      getOrderDetail();
    }
  };

  const getOrderDetail = async () => {
    let orderDetailSource = [];
    let orderInfoSource = {};
    dispatch(setLoading(true));
    try {
      // server action
      const resp = await Service.call("get", "/api/order", { order_id });
      if (!resp || resp.status !== 1) {
        notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
        return history.replace(`/${company_key}/order`);
      }
      const {
        orderItemList,
        orderRc
      } = resp;
      orderInfoSource = orderRc;

      let targetOrderItemList = [];
      setPaymentStatus(Main.getPaymentStatus(orderRc.status)?.displayStr);
      switch (orderRc?.status) {
        case "partial_payment_refund":
        case "partial_payment_refund_token":
        case "partial_payment_refund_offline":
          targetOrderItemList = filter(
            orderItemList,
            ({ is_active }) => includes([-1, 1], is_active)
          );
          break;
        case "payment_refund":
        case "payment_refund_token":
        case "payment_refund_offline":
          targetOrderItemList = filter(orderItemList, { is_active: -1 });
          break;
        case "expired":
        case "cancelled":
          targetOrderItemList = orderItemList;
          break;
        default:
          targetOrderItemList = filter(orderItemList, { is_active: 1 });
          break;
      }
      const childCartItemGroup = groupBy(
        orderItemList,
        "parent_order_item_id"
      );
      orderDetailSource = map(targetOrderItemList, (item) => {
        const {
          item_id,
          order_item_id,
          parent_order_item_id,
          final_price
        } = item;
        const product_price = sumBy(
          childCartItemGroup[order_item_id],
          ({ final_price }) => final_price
        );
        const session_price = final_price;
        if (parent_order_item_id !== 0) {
          return null;
        }
        return {
          ...item,
          price: product_price + session_price,
          childItems: childCartItemGroup[order_item_id] || [],
        };
      });
      orderDetailSource = compact(orderDetailSource);
      orderDetailSource.sort((a, b) => a.start_time - b.start_time);
      orderInfoSource.order_type = uniqBy(
        targetOrderItemList,
        "item_type"
      ).map((item) => item.item_type);
      orderInfoSource.total_item = targetOrderItemList?.length || 0;
      if (orderRc?.status === "cancelled") {
        orderInfoSource.final_price = sumBy(orderDetailSource, "price");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setOrderInfo(orderInfoSource);
      setOrderDetail(orderDetailSource);
      setNeedUpdate(false);
      dispatch(setLoading(false));
    }
  };

  return (
    <AppLayout className="order-layout">
      <div className="order-detail-page-layout" style={{ padding: !isMobile && "2% 15%" }}>
        <OrderCardWrapper title={t("order_details")} titleStyle={{ marginBottom: 24 }}>
          <PaymentStatusCard
            info={orderInfo}
            detail={orderDetail}
            paymentStatus={paymentStatus}
            onCancelOrder={setCancelModalVisible}
          />
        </OrderCardWrapper>

        {(includes(
          [
            "payment_refund",
            "payment_refund_token",
            "payment_refund_offline",
            "partial_payment_refund",
            "partial_payment_refund_token",
            "partial_payment_refund_offline"
          ], orderInfo?.status)) && (
            <OrderCardWrapper title={t("payment")} titleStyle={{ marginBottom: 24 }}>
              <RefundPaymentDetail data={orderInfo} />
            </OrderCardWrapper>
          )}
        {paymentStatus === "pending"
          || (paymentStatus === "complete" && (
            <OrderCardWrapper title={t("payment")} titleStyle={{ fontSize: 20 }}>
              <Divider style={{ margin: "16px 0" }} />
              <PaymentDetailCard data={orderInfo} />
            </OrderCardWrapper>
          ))}

        <OrderCardWrapper title={t("items")} titleStyle={{ fontSize: 20 }}>
          <>
            {map(orderDetail, (order) => (
              <>
                <Divider style={{ margin: "16px 0" }} />
                <OrderDetailCard
                  key={order?.item_id}
                  data={order}
                  itemStyle={{ margin: 0 }}
                  orderStatus={orderInfo?.status}
                  setNeedUpdate={setNeedUpdate}
                // containerStyle={{
                //   backgroundColor: COLOR.function.white
                // }}
                />
              </>
            ))}
            {/* <Space
                  size={4}
                  style={{ marginTop: 24, color: COLOR.captionText }}
                >
                  {t("you_get")}
                  <span style={{ fontWeight: "bold", color: COLOR.brandPrimary }}>
                    {orderInfo?.token_earned}
                  </span>
                  {t("point_for_this_order")}
                </Space> */}
          </>
        </OrderCardWrapper>
        <OrderCardWrapper
          title={t("need_help")}
          style={
            !isMobile
            && { display: "flex", justifyContent: "space-between", alignItems: "center" }
          }
        >
          {isMobile && (<Divider />)}
          <Button
            className="ghost-btn"
            type="primary"
            ghost
            style={{ width: !isMobile && "20%", margin: 0 }}
            onClick={() => {
              history.push(`/${company_key}/contact/us`);
            }}
          >
            {t("contact_us")}
          </Button>
        </OrderCardWrapper>
        {orderInfo?.order_type.includes(1) && (
          <OrderCardWrapper
            title={t("other_item")}
            style={{ background: "#fff" }}
          >
            <OtherItemCarousel />
          </OrderCardWrapper>
        )}

        <CancelOrderModal
          orderStatus={paymentStatus}
          cancelTarget={orderInfo}
          type="payment"
          mode="all"
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          setNeedUpdate={setNeedUpdate}
        />
      </div>
    </AppLayout>
  );
};

export default OrderDetailPage;

const RefundPaymentDetail = ({ data }) => {
  const { t } = useTranslation();
  const {
    refund_amount, refund_method, refund_time, paymentRecordList
  } = data;
  return (
    <>
      <Row
        gutter={[0, 6]}
        style={{ paddingBottom: 24, borderBottom: "1px solid #E7E8E8" }}
      >
        <Col span={24}>
          <ListItem title={t("refund")} value={`$${refund_amount}`} />
        </Col>
        <Col span={24}>
          <ListItem
            title={t("refund_method")}
            value={map(refund_method, (method) => (
              <div key={method}>{method}</div>
            ))}
          />
        </Col>
        <Col span={24}>
          <ListItem
            title={t("payment")}
            value={map(paymentRecordList, (payment) => (
              <div key={payment?.payment_name}>t({payment?.payment_name})</div>
            ))}
          />
        </Col>
        <Col span={24}>
          <ListItem
            title={t("refund_on")}
            value={moment.unix(refund_time).format("DD-MM-YYYY HH:mm:ss")}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          <Button
            className="ghost-btn"
            type="primary"
            ghost
            onClick={() => {
              console.log("check receipt");
            }}
          >
            {t("check_receipt")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const PaymentDetailCard = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let {
    final_price,
    token_earned,
    ctime,
    order_type,
    ptx_complete_time,
    paymentRecordList,
    status,
    token_amount,
    token_used,
  } = data;
  return (
    <>
      {map(paymentRecordList, (record) => {
        const {
          payment_name,
          ptx_complete_time,
          ptx_final_price = 0,
          order_id
        } = record;
        return (
          <Row
            key={order_id}
            gutter={[0, 6]}
          >
            <Col span={24}>
              <ListItem title={t("payment_amount")} value={`$${ptx_final_price}`} />
            </Col>
            <Col span={24}>
              <ListItem title={t("payment")} value={t(payment_name)} />
            </Col>
            <Col span={24}>
              <ListItem
                title={t("pay_on")}
                value={
                  ptx_complete_time
                    ? moment
                      .unix(ptx_complete_time)
                      .format("DD-MM-YYYY HH:mm:ss")
                    : "N/A"
                }
              />
            </Col>
            {(order_type.some((type) => includes([1, 2, 3], type))) && (
              <Col span={24}>
                <ListItem
                  title={t("book_on")}
                  value={moment.unix(ctime).format("DD-MM-YYYY HH:mm:ss")}
                />
              </Col>
            )}
          </Row>
        );
      })}
      {!includes([4, 5], order_type[0]) && (
        <Row
          gutter={[0, 8]}
          style={{
            marginTop: 24,
            paddingTop: 24,
            borderTop: "1px solid #E7E8E8"
          }}
        >
          <Col span={24}>
            <Button
              className="ghost-btn"
              type="primary"
              ghost
              style={{ margin: 0 }}
              onClick={() => {
                dispatch(setQRCodeVisible(true));
              }}
            >
              {t("access_qr_code")}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

const PaymentStatusCard = ({
  info,
  detail,
  paymentStatus,
  onCancelOrder
}) => {
  const {
    order_id,
    order_key,
    status,
    ctime,
    original_price,
    order_discount,
    discount,
    expiry_time,
    order_type,
    total_item,
  } = info;
  const { t } = useTranslation();
  const history = useHistory();
  const { company_key } = useParams();

  const getStatusColor = (value) => {
    let color = "#000";
    switch (value) {
      case "payment_confirmed":
        color = COLOR.success.success400;
        break;
      case "pending_approve":
      case "partial_payment_refund":
      case "partial_payment_refund_token":
      case "partial_payment_refund_offline":
        color = COLOR.warningYellow;
        break;
      case "placed":
      case "expired":
      case "cancelled":
      case "payment_refund":
      case "payment_refund_token":
      case "payment_refund_offline":
      default:
        color = COLOR.warningRed;
        break;
    }
    return color;
  };
  return (
    <>
      <Row gutter={[0, 6]}>
        <Col span={24}>
          <ListItem
            title={t("order_number")}
            value={`#${order_key}`}
            titleStyle={{ color: "#000" }}
          />
        </Col>
        <Col span={24}>
          <ListItem
            title={t("type")}
            value={(
              <Space>
                {map(order_type, (type) => `${t(Main.ITEM_TYPE[type])}`)}
              </Space>
            )}
          />
        </Col>
        <Col span={24}>
          <ListItem title={t("item")} value={total_item} />
        </Col>
        {paymentStatus === "cancelled" && (
          <Col span={24}>
            <ListItem
              title={includes(order_type, 1) ? t("book_on") : t("order_on")}
              value={moment.unix(ctime).format("DD-MM-YYYY HH:mm:ss")}
            />
          </Col>
        )}

        <Col span={24}>
          <ListItem
            title={includes(order_type, 1) ? t("booking_status") : t("order_status")}
            value={t(paymentStatus)}
            valueStyle={{ color: getStatusColor(status) }}
          />
        </Col>
        {paymentStatus === "not_paid" && (
          <Col span={24}>
            <ListItem
              title={t("pay_by")}
              value={moment.unix(expiry_time).format("HH:mm:ss")}
            />
          </Col>
        )}
      </Row>
      {/* {paymentStatus === "not_paid" && (
        <Row style={{ padding: "24px 0 16px", borderTop: "1px solid #E7E8E8" }}>
          <Col span={24}>
            <div style={{ fontWeight: "bold" }}>{`${t(
              "to_pay"
            )} : $${final_price}`}</div>
          </Col>
        </Row>
      )} */}
      <Divider />
      <Row className="order-detail-total-price" style={{ marginBottom: 8 }}>{`${t("total_price")}: $${original_price - order_discount - discount}`}</Row>
      <>
        {!includes([
          "complete",
          "partial_payment_refund",
          "partial_payment_refund_offline",
          "partial_payment_refund_token",
          "cancelled",
        ], paymentStatus)
          && (
            <div style={{ marginTop: 16 }}>
              <Button
                className="ghost-btn"
                type="primary"
                ghost
                onClick={() => onCancelOrder(true)}
              >
                {t("cancel_order")}
              </Button>
            </div>
          )}
        {!paymentStatus !== "cancelled"
          && paymentStatus === "not_paid" && (
            <div style={{ marginTop: 8 }}>
              <Button
                className="primary-btn"
                type="primary"
                onClick={() => history.push(`/${company_key}/payment/${order_id}`)}
              >
                {t("confirm_payment")}
              </Button>
            </div>
          )}
      </>
    </>
  );
};

import React, { useRef } from "react";
import { Grid } from "antd";
import Icon from "@ant-design/icons";
import _ from "lodash";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import MainImageDot from "./MainImageDot";
import default_banner_img from "../../assets/default_banner.png";
import { BREAKPOINT } from "../../Theme";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const MainImageCarousel = (props) => {
  const { coverImgArr } = props;
  const config = useSelector((state) => state.app.client.config);
  const carouselNewItem = useRef();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.md;

  return (
    <Carousel
      ref={carouselNewItem}
      responsive={responsive}
      infinite
      arrows
      keyBoardControl
      showDots
      customTransition="transform 500ms ease-in-out"
      autoPlay={false}
      customDot={<MainImageDot imageArr={coverImgArr} />}
      renderDotsOutside
    >
      {!_.isEmpty(coverImgArr) ? _.map(coverImgArr, (item, index) => {
        return (
          <div key={index}
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              minHeight: 200
            }}
          >
            <img
              draggable={false}
              alt="cover_image"
              src={`${config.MEDIA_URL}/${item}`}
              style={{
                width: "100%",
                maxHeight: isMobile ? "56.25vw" : 650,
                objectFit: "contain",
                borderRadius: 8
              }}
            />
          </div>
        );
      }) : (
        <img
          draggable={false}
          alt="cover_image"
          src={default_banner_img}
          style={{
            width: "100%",
            maxHeight: isMobile ? "56.25vw" : 650,
            objectFit: "contain",
            borderRadius: 8
          }}
        />
      )}
    </Carousel>
  );
};

export default MainImageCarousel;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import * as Service from '../../core/Service';
import { openNotificationWithIcon } from '../form/ErrorMessage';
import { useDispatch, useSelector } from "react-redux";
import { setUser } from '../../redux/actions/common';
import { Form, Input, Radio } from 'antd'
import debugLib from 'debug';
import { useHistory } from 'react-router';

const debug = debugLib('app:register/RegisterForm');

const Register = (props) => {
  const router = useHistory();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [UserForm] = Form.useForm();
  const app = useSelector((state) => state.app);
  const { color_primary, color_secondary, company_id, company_key } = app.company

  const { handleCloseModal } = props

  const handleSubmit = async (formData) => {
    let postData = {
      ...formData,
      company_id,
      // app_key: "123456"
    }
    // postData = {
    //   username: 'testRegister',
    //   password: '12345678',
    //   email: 'test@123.com',
    //   mobile: '85718293',
    //   first_name: 'testing',
    //   last_name: 'user',
    //   nickname: 'tu',
    //   gender: 'M'
    // }
    // app_key to be done later (lucas)
    try {
      const resp = await Service.call('post', `/api/app/register/user`, postData);
      // console.log("resp>>>", resp)
      if (!resp || resp.status !== 1) {
        openNotificationWithIcon('error', t('fail'), resp.errorMessage)
        return
      }
      const {userInfo} = resp
      openNotificationWithIcon('success', t('success'), "")
      dispatch(setUser(userInfo))
      // Remove company key later
      router.push(`/${company_key}/profile?tab=1`)
      handleCloseModal()
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <Form
      form={UserForm}
      name="UserForm"
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item>
        <h2>{t("register")}</h2>
      </Form.Item>
      <Form.Item required label={t("username")} name="username">
        <Input />
      </Form.Item>

      <Form.Item required label={t("password")} name="password">
        <Input type="password" />
      </Form.Item>

      <Form.Item
        label={t("repeat_password")}
        name="repeat_password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item required label={t("email")} name="email">
        <Input />
      </Form.Item>

      <Form.Item required label={t("mobile")} name="mobile">
        <Input />
      </Form.Item>

      <Form.Item required label={t("first_name")} name="first_name">
        <Input />
      </Form.Item>

      <Form.Item required label={t("last_name")} name="last_name">
        <Input />
      </Form.Item>

      <Form.Item required label={t("nickname")} name="nickname">
        <Input />
      </Form.Item>

      <Form.Item required label={t("gender")} name="gender">
        <Radio.Group>
          <Radio value="M">{t('male')}</Radio>
          <Radio value="F">{t('female')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button
          theme_color={color_primary}
          size="large"
          htmlType="submit"
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Register;

import React, { useEffect, useState } from "react";
import {
  Grid,
  notification,
  Spin
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  groupBy,
  map,
  filter,
  clone
} from "lodash";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";
import AppLayout from "../components/AppLayout";
import * as Service from "../core/Service";
import ShoppingCartMobileView from "../components/shoppingCart/ShoppingCartMobileView";
import ShoppingCartDesktopView from "../components/shoppingCart/ShoppingCartDesktopView";
import { setLoading, setShoppingCartAmount } from "../redux/actions/common";
import { BREAKPOINT, COLOR, FONT } from "../Theme";
import ConfirmModal from "../components/ConfirmModal";

const ShoppingCartContainer = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { company, user } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const [visible, setModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [shoppingCartInfo, setShoppingCartInfo] = useState({
    shoppingCartRc: {},
    shoppingCartItemList: []
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user.company_user_id > 0) {
      getShoppingCartInfo();
    }
  }, [user]);

  const getShoppingCartInfo = async () => {
    dispatch(setLoading(true));
    try {
      const resp = await Service.call("get", "/api/shopping_cart");
      if (resp.status === 1) {
        const {
          shoppingCartRc,
          shoppingCartItemList,
        } = resp;
        const groupByCartItemList = groupBy(shoppingCartItemList, "parent_shopping_cart_item_id");
        const parentCartItem = filter(
          clone(shoppingCartItemList),
          (rc) => rc.parent_shopping_cart_item_id === 0
        );
        const cartItemList = map(parentCartItem, (eachCartItem) => {
          const childItems = groupByCartItemList[eachCartItem.shopping_cart_item_id] || [];
          let childItemsFinalPrice = 0;
          map(childItems, (rc) => {
            childItemsFinalPrice += rc.final_price;
          });
          return {
            ...eachCartItem,
            childItems,
            childItemsFinalPrice
          };
        });
        dispatch(setShoppingCartAmount(cartItemList.length));
        setShoppingCartInfo({
          shoppingCartRc: {
            ...shoppingCartRc,
            totalDiscount: shoppingCartRc.discount + shoppingCartRc.cart_discount
          },
          shoppingCartItemList: cartItemList
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const deleteShoppingCartItem = async () => {
    try {
      if (!selectedId) {
        return;
      }
      const resp = await Service.call("post", "/api/shopping_cart/item/remove", {
        shopping_cart_item_id: selectedId
      });

      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t(`error`),
          description: t(resp.errorCode)
        });
      }
      getShoppingCartInfo();
    } catch (error) {
      console.error(error);
    }
  };

  const onCheckout = async () => {
    try {
      const resp = await Service.call("post", "/api/shopping_cart/submit");
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t(`error`),
          description: t(resp.errorCode)
        });
      }
      const { orderRc } = resp;
      dispatch(setShoppingCartAmount(0));
      history.push(`/${company.company_key}/payment/${orderRc?.order_id || 0}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppLayout
      childrenFullWidth={isMobile}
    >
      {isMobile
        ? (
          <ShoppingCartMobileView
            shoppingCartInfo={shoppingCartInfo}
            onDelete={(value) => {
              setSelectedId(value);
              setModalVisible(true);
            }}
            history={history}
            onCheckout={onCheckout}
          />
        )
        : (
          <ShoppingCartDesktopView
            shoppingCartInfo={shoppingCartInfo}
            onDelete={(value) => {
              setSelectedId(value);
              setModalVisible(true);
            }}
            history={history}
            onCheckout={onCheckout}
          />
        )}
      <ConfirmModal
        title={t("delete_booking")}
        visible={visible}
        setModalVisible={setModalVisible}
        confirmButtonStr={t("delete")}
        cancelButtonStr={t("cancel")}
        onCancel={() => {
          setSelectedId(null);
          setModalVisible(false);
        }}
        onConfirm={() => deleteShoppingCartItem()}
      >
        <span
          style={{
            ...FONT.bodyText1Regular,
            color: COLOR.text.captionText
          }}
        >
          {t("delete_booking_warning")}
        </span>
      </ConfirmModal>
    </AppLayout>
  );
};

export default ShoppingCartContainer;

import { Breadcrumb } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PaymentBreadcrumb = ({ step, style = {} }) => {
  const { t } = useTranslation();
  return (
    <Breadcrumb separator=">" style={{...style}}>
      <Breadcrumb.Item className={step >= 1 ? "selected-tab" : "link-tab"}>
        {t("enter_info")}
      </Breadcrumb.Item>
      <Breadcrumb.Item disabled className={step >= 2 ? "selected-tab" : "link-tab"}>
        {t("pay")}
      </Breadcrumb.Item>
      <Breadcrumb.Item disabled className={step >= 3 ? "selected-tab" : "link-tab"}>
        {t("order_complete")}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default PaymentBreadcrumb;

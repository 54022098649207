import React, {
  useState, useEffect, useRef, useContext
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Row, Col, Button, Calendar, Divider, Spin, Modal, Typography, Drawer, Grid, notification
} from "antd";
import {
  RightOutlined, CalendarOutlined, InfoCircleOutlined, ClockCircleOutlined
} from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import { Decimal } from "decimal.js";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import { FONT, COLOR, BREAKPOINT } from "../Theme";
import * as Service from "../core/Service";
import * as Main from "../core/Main";
import ProductButton, {
  QuantityInput,
} from "./bookingDetail/ProductButton";
import LoadingIcon from "./LoadingIcon";
import ModalDrawerContainer from "./bookingDetail/ModalDrawerContainer";
import { setShoppingCartAmount, setAppModal, setAuth, setUser, setUserSession } from "../redux/actions/common";
import "react-multi-carousel/lib/styles.css";
import calender_hk from "antd/es/date-picker/locale/zh_CN"; // set calender language
import calender_en from "antd/es/date-picker/locale/en_US"; // set calender language
import { useWindowWidth } from "@react-hook/window-size";

const { Paragraph } = Typography;

const ReserveMobile = ({ bookingItemPriceList }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { category: booking_category_id } = useParams();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { company, user } = useSelector((state) => state.app);

  const [loading, setLoading] = useState(false);
  const [bookingItemList, setBookingItemList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productPrice, setProductPrice] = useState(0);
  const [sectionPrice, setSectionPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [inputNumber, setInputNumber] = useState(0);
  const [capacityAmount, setCapacityAmount] = useState(0);
  const [locale, setLocale] = useState(calender_en);

  // timeslot ui control
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [timeslot, setTimeslot] = useState([]);

  // selected data control
  const [selectedRoom, setSelectedRoom] = useState(0); // booking item
  const [bookingRuleSet, setBookingRuleSet] = useState(0); // selected booking rule set
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(moment().endOf("day")); // Calendar
  const [isCalenderConfirm, setIsCalenderConfirm] = useState(false);
  const [selectedTime, setSelectedTime] = useState([]); // timeslot
  const [selectedTimeConfirm, setSelectedTimeConfirm] = useState([]); // timeslot confirm
  const [targetItems, setTargetItems] = useState([]); // product
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [lowestTimeslotCapacity, setLowestTimeslotCapacity] = useState(1);
  const [suggestDateOptions, setSuggestDateOptions] = useState([]);

  // section control
  const [selectedRoomStatus, setSelectedRoomStatus] = useState(false);
  const [selectedDateTimeStatus, setSelectedDateTimeStatus] = useState(false);
  const [checkoutStatus, setCheckoutStatus] = useState(false);

  // set drawer visible
  const [calenderDrawerVisible, setCalenderDrawerVisible] = useState(false);
  const [availabilityDrawerVisible, setAvailabilityDrawerVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const responsiveDay = {
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: 7,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  // set wider row
  const [widerChoice, setWiderChoice] = useState(false);

  useEffect(() => {
    setSuggestDateOptions(renderDateOptionList(moment().endOf("day")));
  }, [locale]);

  useEffect(() => {
    // find the booking item that exceed the required length
    let widerChoice = !!_.find(bookingItemList, (item) => {
      const { item_name } = item;
      // console.log(item_name.length);
      return item_name.length > 24;
    });
    // console.log(widerChoice);
    setWiderChoice(widerChoice);
  }, [bookingItemList]);

  const renderDateOptionList = (date) => {
    let days = [];
    let daysRequired = 7;

    for (let i = 0; i < daysRequired; i++) {
      days.push(moment(date)?.add(i, "days"));
    }
    return days;
  };

  const clearRoomTimeSlot = () => {
    // clear child selection
    setSelectedTime([]);
    setTargetItems([]);

    // hide product session
    setSelectedDateTimeStatus(false);

    // clear selected time
    setSelectedTime([]);
    setSelectedTimeConfirm([]);

    setCapacityAmount(0);
    setProductPrice(0);
    setSectionPrice(0);
    setTotalPrice(0);
    setSelectedProductList([]);
  };

  const onHandleDateOption = (day) => {
    setSelectedCalendarDate(day);
    getBookingTimeslotList(day);
    setSelectedRoom(0);
    clearRoomTimeSlot();
  };

  const selectRoom = (target, targetObj) => {
    setSelectedRoom(targetObj);
    setSelectedRoomStatus(true);
    clearRoomTimeSlot();
  };

  const validateTimeslots = (timeslotArr, maxLength) => {
    if (timeslotArr.length > maxLength) {
      return false;
    }

    for (let i = 0; i < timeslotArr.length; i++) {
      if (!timeslotArr[i].status) {
        return false;
      }

      if (i > 0 && (timeslotArr[i].start_time - timeslotArr[i - 1].end_time !== 1)) {
        return false;
      }
    }

    return true;
  };

  const updateSelectedTime = (timeslotArr, minLength) => {
    setSelectedTime(_.orderBy(timeslotArr, ["id"], ["asc"]));
    const additionalPrice = _.sumBy(timeslotArr, "section_price");
    setTotalPrice((prev) => prev + additionalPrice);
    if (timeslotArr.length >= minLength) {
      setSelectedDateTimeStatus(true);
    } else {
      setSelectedDateTimeStatus(false);
    }
  };

  const selectTimelog = (obj) => {
    // first times : start ture , end false
    // second times click then set color continue
    if (!inputNumber) {
      setCapacityAmount(1);
      setInputNumber(1);
    }

    let arr = [];
    const { min_duration, max_duration, section_duration } = bookingRuleSet;
    const maxLength = max_duration / section_duration;
    const minLength = min_duration / section_duration;

    const timeslotMap = _.keyBy(timeslot, "id");
    // this is for first time click then second times click and set mutiply button color
    if (start && !end) {
      if (startTime < obj) {
        for (let i = startTime; i <= obj; i++) {
          arr.push(timeslotMap[i]);
        }
      } else {
        // opposite selected
        for (let i = startTime; i >= obj; i--) {
          arr.unshift(timeslotMap[i]);
        }
      }

      if (validateTimeslots(arr, maxLength)) {
        setEnd(true); // marked second times click
      } else {
        arr = [timeslotMap[obj]];
        setStartTime(obj);
      }

      updateSelectedTime(arr, minLength);
      return;
    }

    // when user click 2 times and reset inital color then set color , go back first times status
    if (start && end) {
      arr.push(timeslotMap[obj]);
      setEnd(false); // go back first times
      setStartTime(obj);
    }

    // marked first times click and set 1 color button
    if (!start && !end) {
      setStartTime(obj);
      setStart(true);
      arr.push(timeslotMap[obj]);
    }

    // marked second times click
    if (start && !end) {
      setEnd(true);
    }

    // for data store
    updateSelectedTime(arr, minLength);
  };

  const handleSelectedTimeConfirm = () => {
    setAvailabilityDrawerVisible(false);
    setSelectedTimeConfirm(selectedTime);
    setCapacityAmount(inputNumber);
  };

  const onAddCart = async () => {
    const { min_duration, max_duration, section_duration } = bookingRuleSet;
    try {
      setLoading(true);
      const start_time = _.first(selectedTimeConfirm)?.start_time;
      const end_time = _.last(selectedTimeConfirm)?.end_time;
      if ((end_time + 1 - start_time) < min_duration) {
        return notification.error({
          message: t("error"),
          description: `${t("min_duration_error")} (${min_duration / 60} mins)`
        });
      }
      if ((end_time - start_time) > max_duration) {
        return notification.error({
          message: t("error"),
          description: `${t("max_duration_error")} (${max_duration / 60} mins)`
        });
      }
      const booking_product = _.map(
        _.filter(targetItems, ({ quantity }) => quantity),
        ({ product_item_id, quantity }) => {
          return { product_item_id, quantity };
        }
      );
      const postObj = {
        start_time,
        end_time,
        item_id: selectedRoom?.booking_item_id,
        capacity: capacityAmount,
        item_type: 1,
        booking_product,
      };
      const resp = await Service.call(
        "post",
        "/api/shopping_cart/item/add",
        postObj
      );
      if (!resp || resp.status !== 1) {
        notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
        getBookingTimeslotDetail();
        return;
      }
      const { shoppingCartItemList } = resp;
      const parentCartItem = _.filter(
        _.clone(shoppingCartItemList),
        (rc) => rc.parent_shopping_cart_item_id === 0
      );
      setSelectedRoom(0);
      setStart(false);
      setEnd(false);
      setSelectedTime([]);
      setSelectedTimeConfirm([]);
      setSelectedCalendarDate(moment().endOf("day"));
      setCapacityAmount(0);
      setInputNumber(0);
      setTargetItems([]);
      setTimeslot([]);
      setCheckoutStatus(false);
      setSelectedRoomStatus(false);
      setSelectedDateTimeStatus(false);
      dispatch(setShoppingCartAmount(parentCartItem.length));
      Modal.success({
        content: t("add_to_cart_success"),
        okButtonProps: {
          style: {
            borderColor: COLOR.primary.primary500,
            backgroundColor: COLOR.primary.primary500,
            color: COLOR.whiteSecondary,
          },
        },
        onOk() {
          getBookingTimeslotList(selectedCalendarDate, true);
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onPayment = async () => {
    const { min_duration, max_duration, section_duration } = bookingRuleSet;
    try {
      setLoading(true);
      const start_time = _.first(selectedTimeConfirm)?.start_time;
      const end_time = _.last(selectedTimeConfirm)?.end_time;
      if ((end_time + 1 - start_time) < min_duration) {
        return notification.error({
          message: t("error"),
          description: `${t("min_duration_error")} (${min_duration / 60} mins)`
        });
      }
      if ((end_time - start_time) > max_duration) {
        return notification.error({
          message: t("error"),
          description: `${t("max_duration_error")} (${max_duration / 60} mins)`
        });
      }
      const booking_product = _.map(
        _.filter(targetItems, ({ quantity }) => quantity),
        ({ product_item_id, quantity }) => {
          return { product_item_id, quantity };
        }
      );
      const putObj = {
        start_time,
        end_time,
        item_id: selectedRoom?.booking_item_id,
        capacity: capacityAmount,
        item_type: 1,
        booking_product,
      };
      const resp = await Service.call("put", "/api/order", putObj);
      if (!resp || resp.status !== 1) {
        notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
        getBookingTimeslotDetail();
        return;
      }

      const { orderRc } = resp;
      setSelectedRoom(0);
      setStart(false);
      setEnd(false);
      setSelectedTime([]);
      setSelectedCalendarDate(moment().endOf("day"));
      setCapacityAmount(0);
      setInputNumber(0);
      setTargetItems([]);
      setTimeslot([]);
      setCheckoutStatus(false);
      setSelectedRoomStatus(false);
      setSelectedDateTimeStatus(false);
      return history.push(
        `/${company.company_key}/payment/${orderRc?.order_id || 0}`
      );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const guestCheckout = async (onSuccess) => {
    try {
      const guestResp = await Service.call("post", "/api/user/guest");
      if (!guestResp || guestResp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(guestResp.errorCode)
        });
      }
      const {
        guestRc,
        userSession
      } = guestResp.data;
      Main.setUserSession(userSession);
      dispatch(setAuth(true));
      dispatch(setUser(guestRc));
      dispatch(setUserSession(userSession));
      onSuccess();
    } catch (error) {
      console.error(error);
    }
  };
  const onClickCheckout = () => {
    if (user.isUser) {
      return onPayment();
    }
    if (company?.allow_guest_checkout) {
      return guestCheckout(onPayment);
    }
    return showLoginModal(onPayment);
  };

  const onClickAddToCart = () => {
    if (user.isUser) {
      return onAddCart();
    }
    // if not login and guest checkout
    if (company?.allow_guest_checkout) {
      return guestCheckout(onAddCart);
    }
    return showLoginModal(onAddCart);
  };

  // login Popup
  const showLoginModal = (onSuccess) => {
    return dispatch(
      setAppModal({
        appModalVisible: true,
        type: "login",
        onSuccess
      })
    );
  };

  // call when first render & date change
  const getBookingTimeslotList = async (date, isAddCart) => {
    try {
      setLoading(true);
      const resp = await Service.call(
        "get",
        "/api/booking/v1/timeslot/list",
        {
          booking_category_id,
          start_time: date.startOf("day").unix(),
          end_time: date.endOf("day").unix(),
        }
      );

      if (resp?.status === 1) {
        const { bookingItemList: bookingItemListData } = resp.data;

        // calculate sum of capacity and usedCapacity for each room
        const list = bookingItemListData.map((item) => {
          const timeslotList = item?.timeslot?.map((i) => ({
            usedCapacity: i.usedCapacity,
            capacity: i.capacity,
          }));
          const sumOfUsedCapacityArray = timeslotList?.map((i) => i.usedCapacity).reduce((a, b) => a + b, 0) || 0;
          const sumOfCapacityArray = timeslotList?.map((i) => i.capacity).reduce((a, b) => a + b, 0) || 0;
          return ({
            ...item,
            sumOfUsedCapacity: sumOfUsedCapacityArray,
            sumOfCapacity: sumOfCapacityArray
          });
        });
        // console.log(list);
        const orderedList = _.orderBy(list, ["sorting"], ["desc"]);
        setBookingItemList(orderedList);
        setIsCalenderConfirm(false);

        if (isAddCart) {
          return setSelectedRoom(0);
        }

        if (selectedRoom?.booking_item_id) {
          const selectedRoomTimeslot = _.filter(bookingItemListData, [
            "booking_item_id",
            selectedRoom.booking_item_id,
          ])[0].timeslot;
          setTimeslot(selectedRoomTimeslot);
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // call when room change
  const getBookingItemDetail = async () => {
    try {
      setLoading(true);
      const resp = await Service.call(
        "post",
        "/api/booking/v1/item/detail",
        {
          booking_item_id: selectedRoom?.booking_item_id,
        }
      );

      if (resp?.status === 1) {
        const {
          bookingProductArr, productImageArr, timeslotList, bookingRulesetRc
        } = resp.data;
        const selectedRoomTimeslotList = bookingItemList.find((bookingItem) => bookingItem.booking_item_id === selectedRoom?.booking_item_id)?.timeslot;
        setTimeslot(selectedRoomTimeslotList);
        setBookingRuleSet(bookingRulesetRc);
        setProductList(bookingProductArr);
        setIsCalenderConfirm(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // call when checkout / add to cart failed
  const getBookingTimeslotDetail = async () => {
    try {
      setLoading(true);
      const resp = await Service.call(
        "post",
        "/api/booking/v1/timeslot/detail",
        {
          booking_item_id: selectedRoom?.booking_item_id,
          start_time: selectedCalendarDate.startOf("day").unix(),
          end_time: selectedCalendarDate.endOf("day").unix(),
        }
      );

      if (resp?.status === 1) {
        const { bookingProductArr, productImageArr, timeslot } = resp.data;
        setProductList(bookingProductArr);
        setTimeslot(timeslot);
        setIsCalenderConfirm(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // set calender language
  useEffect(() => {
    if (i18n.language === "zh-HK") {
      moment.locale("zh-hk");
      setLocale(calender_hk);
    } else {
      moment.locale("en-nz");
      setLocale(calender_en);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (isCalenderConfirm) {
      setCalenderDrawerVisible(false);
    }
    getBookingTimeslotList(selectedCalendarDate);
  }, [isCalenderConfirm]);

  useEffect(() => {
    if (selectedRoom?.booking_item_id) {
      getBookingItemDetail();
    }
  }, [selectedRoom?.booking_item_id]);

  useEffect(() => {
    setStart(false);
    setEnd(false);
  }, [selectedCalendarDate]);

  useEffect(() => {
    // check timeslot lowest capacity
    if (_.isEmpty(selectedTime)) {
      setSelectedDateTimeStatus(false);
      return;
    }
    const newSelectedTime = selectedTime.map((i) => {
      return {
        ...i,
        availability: new Decimal(i.capacity || 0).minus(i.usedCapacity || 0).toNumber(),
      };
    });
    const lowestTimeslotCapacityAmount = newSelectedTime?.reduce(
      (previous, current) => {
        return current.availability < previous.availability
          ? current
          : previous;
      }
    );
    setLowestTimeslotCapacity(lowestTimeslotCapacityAmount.availability);
  }, [selectedTime]);

  // set selected time to confirmed status
  useEffect(() => {
    if (availabilityDrawerVisible) {
      setSelectedTime(selectedTimeConfirm);
    } else {
      setSelectedTime([]);
    }
  }, [availabilityDrawerVisible]);

  // setInputNumber
  useEffect(() => {
    if (availabilityDrawerVisible) {
      if (inputNumber > lowestTimeslotCapacity) {
        setInputNumber(lowestTimeslotCapacity);
      }
    } else {
      setInputNumber(capacityAmount);
    }
  }, [
    inputNumber,
    lowestTimeslotCapacity,
    availabilityDrawerVisible,
  ]);

  useEffect(() => {
    // const sectionPrice = _.sumBy(selectedTimeConfirm, "section_price");
    const sectionPrice = _.sumBy(selectedTimeConfirm, ({ section_price }) => section_price * capacityAmount);
    const productPrice = _.sumBy(
      targetItems,
      ({ price, quantity }) => price * (quantity || 0)
    );

    setSectionPrice(sectionPrice);
    setProductPrice(productPrice);
    setTotalPrice(sectionPrice + productPrice);
  }, [selectedTime, targetItems]);

  // checkout status
  useEffect(() => {
    if (!_.isEmpty(selectedTimeConfirm)) {
      setCheckoutStatus(true);
    }
  }, [selectedTimeConfirm]);

  const checkTimeslotAvailability = (id, end_time, status) => {
    if (end_time < dayjs().unix()) {
      return notification.error({ message: t("booking_is_expired") });
    }
    if (!status) {
      return notification.error({ message: t("booking_is_full") });
    }
    selectTimelog(id);
  };

  return (
    <Spin spinning={loading} indicator={<LoadingIcon />} style={{ top: "50%" }}>
      <Row gutter={32}>
        <Col xs={24} lg={16}>
          <div className={!isMobile && "booking-option-web-wrapper"}>
            <Row style={{ ...styles.heading, paddingTop: 0 }}>
              {t("booking_options")}
            </Row>

            {/* Date Option */}
            <Row justify="space-between" align="middle" style={{ padding: "16px 0" }}>
              <Col style={{ ...styles.subHeading, margin: 0 }}>{t("date_option")}</Col>
              <Button
                type="link"
                onClick={() => setCalenderDrawerVisible(true)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  style={{
                    ...FONT.buttonRegular,
                    color: COLOR.primary.primary500,
                  }}
                >
                  {t("more")}
                </span>
                <RightOutlined style={{ ...styles.showArrow }} />
              </Button>
            </Row>
            <Carousel
              arrows={false}
              responsive={responsiveDay}
              transitionDuration={500}
              containerClass="carousel-container"
              itemClass="date-option-carousel-item"
              shouldResetAutoplay={false}
            >
              {_.map(suggestDateOptions, (day, index) => {
                return (
                  <Button
                    key={`date_option_${index}`}
                    className={
                      selectedCalendarDate.unix() === day.unix()
                        ? "day-option-button-active"
                        : "day-option-button"
                    }
                    onClick={() => onHandleDateOption(day)}
                  >
                    <Row justify="center">
                      <Col span={24}>
                        <Row justify="center">{i18n.language === "en" ? day.format("MMM DD") : day.format("MMMDo")}</Row>
                        <Row
                          justify="center"
                          style={{ color: COLOR.text.captionText }}
                        >
                          {day.format("dddd")}
                        </Row>
                      </Col>
                    </Row>
                  </Button>
                );
              })}
            </Carousel>

            {/* Room Options */}
            <Row>
              <Col style={{ ...styles.subHeading }}>{t("item_options")}</Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
              {_.map(availabilityList, (list) => (
                <Col
                  style={{ marginRight: 16 }}
                  key={`availability_${list.label}`}
                >
                  <Row align="middle">
                    <Col
                      style={{
                        ...styles.availabilityLabel,
                        background: list.color,
                      }}
                    />
                    <Col style={{ color: COLOR.text.captionText }}>
                      {t(list.label)}
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
            <Row gutter={[24, 24]}>
              {_.map(bookingItemList, (item, index) => {
                const {
                  item_name,
                  is_active,
                  booking_item_id,
                  sumOfCapacity,
                  sumOfUsedCapacity,
                } = item;
                const availabilityStatus = checkAvailabilityStatus(
                  sumOfCapacity,
                  sumOfUsedCapacity,
                  is_active === 1
                );
                const colorStyle = setColor(
                  booking_item_id,
                  selectedRoom?.booking_item_id,
                  availabilityStatus,
                  isMobile
                ).colorStyle;
                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={widerChoice ? 24 : 12}
                    lg={widerChoice ? 24 : 8}
                    xl={widerChoice ? 24 : 8}
                    key={`room-option-${booking_item_id}`}

                  >
                    <Button
                      disabled={
                        is_active === 0 || !sumOfCapacity || sumOfCapacity === sumOfUsedCapacity
                      }
                      className="availability-button"
                      style={{
                        ...colorStyle,
                        height: "auto"
                      }}
                      onClick={() => selectRoom(index, item)}
                    >
                      <Paragraph
                        ellipsis={{
                          rows: 2,
                        }}
                        style={{
                          color:
                            selectedRoom?.booking_item_id === booking_item_id
                              ? COLOR.text.primaryText
                              : COLOR.text.captionText,
                          font: FONT.smallText,
                          margin: "auto",
                          whiteSpace: "normal"
                        }}
                      >
                        {item[`item_name_${i18n.language}`] || item_name}
                      </Paragraph>
                    </Button>
                  </Col>
                );
              })}
            </Row>

            {/* Time slot */}
            <Row>
              <Col style={{ ...styles.subHeading }}>
                {t("please_select_time")}
              </Col>
            </Row>
            {_.isEmpty(selectedTimeConfirm) ? (
              <Row>
                <Button
                  className="availability-confirm-btn"
                  style={{ width: !isMobile && "unset" }}
                  onClick={() => setAvailabilityDrawerVisible(true)}
                  disabled={_.isEmpty(timeslot)}
                >
                  <CalendarOutlined />
                  <span>{t("check_availability")}</span>
                </Button>
              </Row>
            ) : (
              <Button
                className="availability-confirm-btn"
                onClick={() => setAvailabilityDrawerVisible(true)}
              >
                <ClockCircleOutlined />
                <span>
                  {`${moment
                    .unix(selectedTimeConfirm?.[0]?.start_time)
                    .format("HH:mm")} - ${moment
                      .unix(
                        selectedTimeConfirm?.[selectedTimeConfirm.length - 1]
                          ?.end_time
                      )
                      .format("HH:mm")}`}

                </span>
              </Button>
            )}

            {/* product section */}
            {!_.isEmpty(selectedTimeConfirm) && (
              <div style={{ marginBottom: 24 }}>
                <Row>
                  <Col style={{ ...styles.subHeading }}>{t("rentals")}</Col>
                </Row>
                <Row gutter={[10, 12]}>
                  {_.map(productList, (item, val) => {
                    const { product_item_id } = item;
                    return (
                      <Col span={24} key={`product-section-${val}`}>
                        <ProductButton
                          selected={_.find(targetItems, { product_item_id })}
                          setTargetItems={setTargetItems}
                          setSelectedProductList={setSelectedProductList}
                          item={item}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}

            {/* checkout section */}
            {isMobile && checkoutStatus && (
              <div>
                <Row justify="space-between" style={{ margin: "16px 0" }}>
                  <Col>{`HK$ ${totalPrice}`}</Col>
                  {/* <Col>point + 50</Col> */}
                </Row>
                <RenderCheckoutBtn
                  onClickAddToCart={onClickAddToCart}
                  onClickCheckout={onClickCheckout}
                />
              </div>
            )}

            {/* Calender Drawer */}
            <ModalDrawerContainer
              title={(
                <div style={{ ...styles.modalTitle }}>
                  {t("select_usage_time")}
                </div>
              )}
              visible={calenderDrawerVisible}
              onClose={() => setCalenderDrawerVisible(false)}
              footer={null}
            >
              <CalendarRender
                selectedCalendarDate={selectedCalendarDate}
                setSelectedCalendarDate={setSelectedCalendarDate}
                setIsCalenderConfirm={setIsCalenderConfirm}
                setSelectedTime={setSelectedTime}
                setTargetItems={setTargetItems}
                setSelectedDateTimeStatus={setSelectedDateTimeStatus}
                locale={locale}
                setSuggestDateOptions={(value) => setSuggestDateOptions(renderDateOptionList(value))}
              />
            </ModalDrawerContainer>

            {/* Timeslot Drawer */}
            <Drawer
              title={(
                <div style={{ ...styles.modalTitle }}>
                  {t("select_usage_time")}
                </div>
              )}
              placement="bottom"
              // closable={false}
              onClose={() => setAvailabilityDrawerVisible(false)}
              visible={availabilityDrawerVisible}
              className="calender-drawer"
              footer={(
                <Row>
                  <Col span={24}>
                    {_.isEmpty(selectedTime) ? (
                      <Row align="middle">
                        <InfoCircleOutlined
                          style={{
                            color: COLOR.text.captionText,
                            marginRight: 4,
                          }}
                        />
                        <span style={{ color: COLOR.text.captionText }}>
                          {t("prices_displaying_in_hkd")}
                        </span>
                      </Row>
                    ) : (
                      <Row justify="space-between" align="middle">
                        <Col>
                          {t("capacity_option")}
                          {" "}
                        </Col>
                        <Col>
                          <QuantityInput
                            inputNumber={inputNumber}
                            setInputNumber={setInputNumber}
                            plusRuleSet={inputNumber < lowestTimeslotCapacity}
                            max={lowestTimeslotCapacity}
                            min={1}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Button
                        className="calender-confirm-btn"
                        onClick={() => handleSelectedTimeConfirm()}
                        disabled={_.isEmpty(selectedTime) || inputNumber === 0}
                      >
                        {t("confirm")}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              )}
            >
              <Row gutter={[12, 12]}>
                {_.map(timeslot, (item) => {
                  let {
                    id,
                    start_time,
                    end_time,
                    section_price,
                    status,
                    capacity,
                    usedCapacity,
                  } = item;
                  if (end_time < dayjs().unix()) {
                    status = false;
                  }
                  const count = new Decimal(capacity)
                    .minus(usedCapacity)
                    .toNumber();
                  const selectedTimeMap = _.keyBy(selectedTime, "id");
                  const availabilityStatus = checkAvailabilityStatus(
                    capacity,
                    usedCapacity,
                    status
                  );
                  const colorStyle = setColor(
                    id,
                    selectedTimeMap[id]?.id,
                    availabilityStatus,
                    true
                  ).colorStyle;
                  const selectedTimeslotIconStyle = setColor(
                    id,
                    selectedTimeMap[id]?.id,
                    availabilityStatus
                  ).selectedTimeslotIconStyle;
                  return (
                    <Col span={24} key={`time-slot-button-${item.id}`}>
                      <Button
                        className="availability-button"
                        // disabled={end_time < dayjs().unix() || !status}
                        // onClick={() => selectTimelog(id)}
                        onClick={() => checkTimeslotAvailability(id, end_time, status)}
                        style={{
                          ...colorStyle,
                          height: "100%",
                          padding: "12px 16px",
                        }}
                      >
                        <Row align="middle" justify="space-between">
                          <Col>
                            <Row style={{ fontWeight: 700 }}>
                              {`${moment
                                .unix(start_time)
                                .format("HH:mm")} - ${moment
                                  .unix(end_time)
                                  .format("HH:mm")}`}
                            </Row>
                            <Row>{`$${section_price}`}</Row>
                          </Col>
                          <Col>
                            <img
                              src="/booking_detail_people.svg"
                              alt=""
                              style={{ ...selectedTimeslotIconStyle }}
                            />
                            <span>{count}</span>
                          </Col>
                        </Row>
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            </Drawer>
          </div>
        </Col>
        {!isMobile && selectedTimeConfirm.length > 0 && (
          <Col lg={8}>
            <div className="booking-detail">
              <BookingDetailItem
                name={t("item")}
                value={selectedRoom?.item_name}
              />
              <BookingDetailItem
                name={t("rentals")}
                value={
                  selectedProductList.filter((list) => list.quantity > 0)
                    .length > 0
                    ? selectedProductList
                      .filter((list) => list.quantity > 0)
                      .map((i) => (
                        <Row justify="end">{`${i.quantity} x ${i.product_name}`}</Row>
                      ))
                    : "-"
                }
              />
              <BookingDetailItem
                name={t("date")}
                value={selectedCalendarDate.format("DD-MM-YYYY")}
              />
              <BookingDetailItem
                name={t("time")}
                value={`${moment
                  .unix(selectedTimeConfirm?.[0]?.start_time)
                  .format("HH:mm")} - ${moment
                    .unix(
                      selectedTimeConfirm?.[selectedTimeConfirm.length - 1]
                        ?.end_time
                    )
                    .format("HH:mm")}`}
              />
              <BookingDetailItem
                name={t("rentals_price")}
                value={`$${productPrice}`}
              />
              <BookingDetailItem
                name={t("item_price")}
                value={`$${sectionPrice}`}
              />
              <BookingDetailItem name={t("capacity")} value={capacityAmount} />
              <Divider />
              <BookingDetailItem
                name={t("total")}
                value={`HKD$${totalPrice}`}
                blueColor
                style={{ marginBottom: 16 }}
              />
              <RenderCheckoutBtn
                onClickAddToCart={onClickAddToCart}
                onClickCheckout={onClickCheckout}
              />
            </div>
          </Col>
        )}
      </Row>
    </Spin>
  );
};

const BookingDetailItem = (props) => {
  const {
    name, value, blueColor, style
  } = props;
  return (
    <Row justify="space-between" style={{ ...style }}>
      <Col
        style={{ color: COLOR.text.captionText, fontSize: 12 }}
      >
        {`${name}:`}

      </Col>
      <Col
        style={{
          color: blueColor ? COLOR.text.primaryText : COLOR.text.blackText,
          fontWeight: blueColor && 700,
        }}
      >
        {value}
      </Col>
    </Row>
  );
};

const RenderCheckoutBtn = (props) => {
  const { onClickAddToCart, onClickCheckout } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Button
          style={{
            backgroundColor: COLOR.function.white,
            color: COLOR.primary.primary500,
            height: 48,
            borderRadius: 8,
            borderColor: COLOR.primary.primary500,
            ...FONT.buttonRegular,
            width: "100%",
          }}
          onClick={onClickAddToCart}
        >
          {t("add_to_cart")}
        </Button>
      </Col>
      <Col span={12}>
        <Button
          style={{
            backgroundColor: COLOR.primary.primary500,
            color: COLOR.text.whiteText,
            height: 48,
            borderRadius: 8,
            borderColor: COLOR.primary.primary500,
            ...FONT.buttonRegular,
            width: "100%",
          }}
          onClick={onClickCheckout}
        >
          {t("checkout")}
        </Button>
      </Col>
    </Row>
  );
};

const CalendarRender = (props) => {
  const {
    selectedCalendarDate,
    setSelectedCalendarDate,
    setIsCalenderConfirm,
    setSelectedTime,
    setTargetItems,
    setSelectedDateTimeStatus,
    locale,
    setSuggestDateOptions,
  } = props;

  const { t } = useTranslation();
  const calendarMonthOptionDisplayNo = 4;
  const carouselRef = useRef();
  const [focusOnSelect, setFocusOnSelect] = useState(false);
  const [nextYearIndex, setNextYearIndex] = useState();
  const [selectedDate, setSelectedDate] = useState(selectedCalendarDate);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: calendarMonthOptionDisplayNo,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current <= moment().subtract(1, "d").endOf("day");
  };

  const headerRender = ({ value, onChange }) => {
    let months = [];
    let monthsRequired = 12;

    for (let i = 0; i < monthsRequired; i++) {
      months.push(moment().add(i, "months").format("MMM"));
    }

    const thisYear = moment().endOf("day").format("YYYY");
    const nextYear = moment().endOf("day").add(1, "year").format("YYYY");

    months.find((month, index) => {
      if (
        month
        === moment().endOf("day").add(1, "year").set("month", 0)
          .format("MMM")
      ) {
        setNextYearIndex(index);
      }
    });

    return (
      <>
        <Row justify="space-between">
          <Col
            style={{
              color:
                value.format("YYYY") === thisYear
                  ? "#000"
                  : COLOR.text.captionText,
              cursor: "pointer",
            }}
            onClick={() => {
              if (value.format("YYYY") === thisYear) {
                return;
              }
              const newValue = moment().endOf("day").add(months[0], "month");
              onChange(newValue);
              carouselRef.current.goToSlide(0);
            }}
          >
            {thisYear}
          </Col>
          <Col
            style={{
              color:
                value.format("YYYY") === nextYear
                  ? "#000"
                  : COLOR.text.captionText,
              cursor: "pointer",
            }}
            onClick={() => {
              if (value.format("YYYY") === nextYear) {
                return;
              }
              const newValue = moment()
                .endOf("day")
                .add(1, "year")
                .set("month", 0);
              onChange(newValue);
              carouselRef.current.goToSlide(nextYearIndex);
            }}
          >
            {nextYear}
          </Col>
        </Row>
        <Carousel
          arrows={false}
          responsive={responsive}
          transitionDuration={500}
          containerClass="carousel-container"
          itemClass="carousel-item"
          shouldResetAutoplay={false}
          slidesToSlide={2}
          // focusOnSelect={focusOnSelect}
          ref={carouselRef}
        >
          {_.map(months, (month, index) => {
            return (
              <Row
                justify="center"
                onClick={() => {
                  const newValue = moment().endOf("day").add(index, "month");
                  onChange(newValue);
                  setFocusOnSelect(
                    index < months.length - calendarMonthOptionDisplayNo
                  );
                }}
                key={`month-${index}`}
              >
                <Col
                  style={{
                    background:
                      value.format("MMM") === month
                      && COLOR.function.background,
                    borderRadius: 12,
                    padding: "8px 16px",
                    cursor: "pointer",
                  }}
                >
                  {month}
                </Col>
              </Row>
            );
          })}
        </Carousel>
      </>
    );
  };

  const onPanelChange = (value, mode) => {
    setSelectedDate(moment(value).endOf("day"));
    // clear timeslot data arr
    setSelectedTime([]);

    // hide product session
    setTargetItems([]);
    setSelectedDateTimeStatus(false);
  };

  return (
    <>
      <Calendar
        fullscreen={false}
        locale={locale}
        value={selectedDate}
        disabledDate={disabledDate}
        onSelect={onPanelChange}
        headerRender={headerRender}
      />
      <Divider />
      <Button
        className="calender-confirm-btn"
        onClick={() => {
          setIsCalenderConfirm(true);
          setSelectedCalendarDate(selectedDate);
          setSuggestDateOptions(selectedDate);
        }}
        disabled={selectedCalendarDate === 0}
      >
        {t("confirm")}
      </Button>
    </>
  );
};

export const setColor = (item, target, status, isMobile) => {
  let colorStyle = {
    backgroundColor: isMobile
      ? COLOR.function.background
      : COLOR.function.white,
    color: COLOR.text.captionText,
    borderLeft: `4px solid ${COLOR.function.background}`,
  };
  let selectedTimeslotIconStyle = {
    filter: "grayscale(100%)",
  };
  if (status === "high") {
    colorStyle = {
      ...colorStyle,
      borderLeft: `4px solid ${COLOR.success.success200}`,
    };
  } else if (status === "medium") {
    colorStyle = {
      ...colorStyle,
      borderLeft: `4px solid ${COLOR.warning.warning200}`,
    };
  } else if (status === "low") {
    colorStyle = {
      ...colorStyle,
      borderLeft: `4px solid ${COLOR.negative.negative400}`,
    };
  } else {
    colorStyle = {
      ...colorStyle,
      borderLeft: `4px solid ${COLOR.gray.gray500}`,
    };
  }
  if (item === target && !_.isUndefined(item) && !_.isUndefined(target)) {
    colorStyle = {
      ...colorStyle,
      backgroundColor: COLOR.primary.primary50,
      color: COLOR.text.primaryText,
    };
    selectedTimeslotIconStyle = {
      filter: "none",
    };
  }
  return { colorStyle, selectedTimeslotIconStyle };
};

export const checkAvailabilityStatus = (capacity, usedCapacity, is_active, type = "booking") => {
  let status;
  // handle unlimited capacity
  if (type === "event" && capacity === 0) return "high";
  const availabilityStatus = new Decimal(capacity)
    .minus(usedCapacity)
    .dividedBy(capacity)
    .times(100)
    .toNumber();
  if (
    availabilityStatus === 1
    || availabilityStatus === 0
    || _.isNaN(availabilityStatus)
    || !is_active
  ) {
    status = "block";
  } else if (availabilityStatus < 100 / 3) {
    status = "low";
  } else if (
    availabilityStatus >= 100 / 3
    && availabilityStatus < (2 / 3) * 100
  ) {
    status = "medium";
  } else {
    status = "high";
  }
  return status;
};

const styles = {
  heading: {
    padding: "32px 0 24px 0",
    fontSize: 28,
    fontWeight: 700,
  },
  subHeading: {
    font: FONT.textMedium,
    color: COLOR.captionText,
    margin: "24px 0 16px 0",
  },
  showArrow: {
    color: COLOR.primary.primary500,
    fontSize: 10,
  },
  availabilityLabel: {
    width: 8,
    height: 12,
    borderRadius: 4,
    marginRight: 8,
  },
  modalTitle: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
  }
};

const availabilityList = [
  {
    label: "high_availability",
    color: COLOR.success.success300,
  },
  {
    label: "medium_availability",
    color: COLOR.warning.warning200,
  },
  {
    label: "low_availability",
    color: COLOR.negative.negative400,
  },
];

export default ReserveMobile;

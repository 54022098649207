import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox, Col, Form, Input, Modal, Row, Select
} from "antd";
import _ from "lodash";
import Button from "../Button";
import * as Service from "../../core/Service";
import { openNotificationWithIcon } from "./ErrorMessage";
import SendOTPButton from "../SendOTPButton";
import userIcon from "../../assets/user.svg";
import lockIcon from "../../assets/lock.svg";
import envelopeIcon from "../../assets/envelope.svg";
import mobileIcon from "../../assets/mobile-alt.svg";
import penIcon from "../../assets/pen.svg";
import { COLOR, FONT } from "../../Theme";
import * as Main from "../../core/Main";
import {
  setAppModal,
  setAuth,
  setOrderInfo,
  setShoppingCartAmount,
  setUser,
  setUserSession
} from "../../redux/actions/common";

const LOGIN_METHOD = {
  username: 1,
  email: 2,
  mobile: 3
};

const areaCodeList = [
  { label: "+852", value: "+852" },
  { label: "+86", value: "+86" },
];

const RegisterForm = (props) => {
  const [registerForm] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const [OTPMedia, setOTPMedia] = useState({});
  const [loading, setLoading] = useState(false);
  const { config } = useSelector((state) => state.app.client);
  const { companySiteMeta, company } = useSelector((state) => state.app);
  const {
    color_primary,
    color_secondary,
    login_method,
    login_password,
    register_otp
  } = app.company;

  const { onSuccess } = props;
  useEffect(() => {
    registerForm.resetFields();
    registerForm.setFieldsValue({
      mobilePrefix: "+852"
    });
  }, []);

  const onFinish = async (formData) => {
    setLoading(false);
    try {
      let postData = {
        ...formData,
      };
      if (login_method === LOGIN_METHOD.mobile) {
        postData.mobile = `${formData.mobilePrefix}-${formData.mobile}`;
      }
      let resp = await Service.call("post", "/api/user/register", postData);
      if (!resp || resp.status !== 1) {
        return openNotificationWithIcon("error", t("error"), t(`${resp.errorCode}`));
      }
      const {
        userSession,
        userInfo,
        orderList,
        shoppingCartItemArr
      } = resp;
      Main.setUserSession(userSession);
      dispatch(setAuth(true));
      dispatch(setOrderInfo(orderList));
      dispatch(setUser(userInfo));
      dispatch(setShoppingCartAmount(
        _.filter(shoppingCartItemArr, { parent_shopping_cart_item_id: 0 }).length
      ));
      dispatch(setUserSession(userSession));
      dispatch(setAppModal({
        appModalVisible: false,
        type: null
      }));
      onSuccess();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderLoginMethod = () => {
    if (login_method === LOGIN_METHOD.email) {
      return (
        <Form.Item
          name="email"
          rules={[{
            required: true,
            message: t("require_email"),
            type: "email"
          }]}
        >
          <Input
            placeholder={t("enter_email")}
            prefix={<img style={{ margin: "0px 5px", }} src={envelopeIcon} alt="prefix_icon" />}
            style={styles.inputContainer}
            allowClear
            bordered={false}
            onChange={(evt) => {
              if (register_otp) {
                setOTPMedia({
                  email: evt.target.value
                });
              }
            }}
          />
        </Form.Item>
      );
    }
    if (login_method === LOGIN_METHOD.mobile) {
      return (
        <Row gutter={[15, 0]} style={{ display: "flex", alignItems: "center" }}>
          <Col xs={6} sm={6} md={4}>
            <Form.Item
              name="mobilePrefix"
            >
              <Select
                showArrow={false}
                options={areaCodeList}
              />
            </Form.Item>
          </Col>

          <Col xs={18} sm={18} md={20}>
            <Form.Item
              name="mobile"
              rules={[{
                required: true,
                message: t("require_mobile"),
              }]}
            >
              <Input
                placeholder={t("enter_mobile")}
                prefix={<img style={{ margin: "0px 5px", }} src={mobileIcon} alt="prefix_icon" />}
                style={styles.inputContainer}
                allowClear
                bordered={false}
                onChange={(evt) => {
                  if (register_otp) {
                    setOTPMedia({
                      mobilePrefix: registerForm.getFieldValue("mobilePrefix"),
                      mobile: evt.target.value
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return (
      <Form.Item
        name="username"
        rules={[{
          required: true,
          message: t("require_username"),
        }]}
      >
        <Input
          placeholder={t("enter_username")}
          prefix={<img style={{ margin: "0px 5px", }} src={userIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
    );
  };

  return (
    <Form
      className="registerForm"
      form={registerForm}
      nme="UserForm"
      layout="vertical"
      onFinish={onFinish}
    >

      <p style={{ ...FONT.h3, margin: "30px 0" }}>
        {t("sign_up_to_continue")}
      </p>

      {renderLoginMethod()}
      {Boolean(register_otp) && (
        <Form.Item
          name="otp_code"
          rules={[{
            required: true,
            message: t("require_otp"),
          }]}
        >
          <Input
            placeholder={t("enter_otp")}
            prefix={<img style={{ margin: "0px 5px", }} src={penIcon} alt="prefix_icon" />}
            suffix={(
              <SendOTPButton
                data={OTPMedia}
                type={Main.OTP_TYPE.USER_REGISTER}
              />
            )}
            style={styles.inputContainer}
            allowClear
            bordered={false}
          />
        </Form.Item>
      )}
      <Form.Item
        name="nickname"
        rules={[{
          required: true,
          message: t("require_display_name"),
        }]}
      >
        <Input
          placeholder={t("enter_display_name")}
          prefix={<img style={{ margin: "0px 5px", }} src={userIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
      {Boolean(login_password) && (
        <>
          <Form.Item
            name="password"
            rules={[{
              required: true,
              message: t("require_password"),
            },
            {
              pattern: /\S{8,}/,
              message: t('password_validation')
            }]}
          >
            <Input.Password
              placeholder={t("enter_password")}
              prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
              style={styles.inputContainer}
              allowClear
              bordered={false}
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: t("require_confirm_password"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("password_not_match")));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={t("confirm_password")}
              prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
              style={styles.inputContainer}
              allowClear
              bordered={false}
            />
          </Form.Item>
        </>
      )}
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <Form.Item
            name="t_and_c_agreement"
            valuePropName="checked"
            rules={[{
              required: true,
              message: t("require_t_and_c"),
            }]}
          >
            <Checkbox>
              <p
                style={{
                  ...FONT.bodyText2Regular,
                  color: COLOR.captionText
                }}
              >
                {t("t_and_c_agree")}
                <a
                  href={`${config.STATIC_SERVER_URL}/${company.company_key}/terms/conditions`}
                  onClick={() => console.log(registerForm.getFieldValue("mobile"))}
                  style={{ color: color_primary }}
                >
                  {t("term_of_service")}
                </a>
              </p>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          style={{
            width: "100%",
            borderRadius: "8px"
          }}
          theme_color={color_primary}
          size="large"
          htmlType="submit"
          loading={loading}
        >
          {t("sign_up")}
        </Button>
      </Form.Item>
      <Row justify="center" gutter={[15, 0]}>
        <Col onClick={() => {
          dispatch(setAppModal({
            appModalVisible: true,
            type: Main.FORM_TYPE.LOGIN
          }));
        }}
        >
          <a style={{ color: color_primary }}>
            {t("login")}
          </a>
        </Col>
      </Row>
    </Form>
  );
};

export default RegisterForm;

const styles = {
  inputContainer: {
    borderRadius: "8px",
    backgroundColor: "#FBF9F9",
    height: "45px",
  }
};

import { Col, Row } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLOR } from "../Theme";

const AvailabilityList = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[0, 8]}>
      {_.map(availabilityList, (list) => (
        <Col style={{ marginRight: 16 }} key={`availability_${list.label}`}>
          <Row align="middle">
            <Col
              style={{ ...styles.availabilityLabel, background: list.color }}
            />
            <Col style={{ color: COLOR.text.captionText }}>{t(list.label)}</Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default AvailabilityList;

const availabilityList = [
  {
    label: "high_availability",
    color: COLOR.success.success300,
  },
  {
    label: "medium_availability",
    color: COLOR.warning.warning200,
  },
  {
    label: "low_availability",
    color: COLOR.negative.negative400,
  },
];

const styles = {
  availabilityLabel: {
    width: 8,
    height: 12,
    borderRadius: 4,
    marginRight: 8,
  },
};

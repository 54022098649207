import React, { useEffect, useState } from "react";
import { Grid, Divider, Spin, Form } from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";
import AppLayout from "../components/AppLayout";
import EditPasswordMobileView from "../components/profile/EditPasswordMobileView";
import { BREAKPOINT, COLOR, FONT } from "../Theme";
import * as Service from "../core/Service";
import LoadingIcon from "../components/LoadingIcon";
import { openNotificationWithIcon } from "../components/form/ErrorMessage";
import * as Main from "../core/Main";

const title = "edit_password";

const EditPasswordContainer = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { t } = useTranslation();
  const [editPasswordForm] = Form.useForm();
  const [isConfirmPasswordCorrect, setIsConfirmPasswordCorrect] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const onFinish = async (formData) => {
    setButtonLoading(true);
    try {
      const { original_password, new_password, confirm } = formData;
      if (original_password === new_password) {
        return openNotificationWithIcon("error", t("error"), t("new_password_cannot_be_same_as_original_password"));
      }

      if (new_password !== confirm) {
        setIsConfirmPasswordCorrect(false);
        return;
      }

      if (!isConfirmPasswordCorrect) {
        setIsConfirmPasswordCorrect(true);
      }

      let patchData = {
        password: original_password,
        new_password
      };
      let resp = await Service.call("patch", "/api/user/edit/password", patchData);
      if (!resp || resp.status !== 1) {
        return openNotificationWithIcon("error", t("error"), t(`${resp.errorCode}`));
      }
      openNotificationWithIcon("success", t("success"), t("change_password_success"));
      let { userSession } = resp;
      Main.setUserSession(userSession);
      editPasswordForm.resetFields();
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <AppLayout className="edit-profile-container">
      <div style={{ padding: !isMobile && "2% 15%" }}>
        <span
          style={{
            ...FONT.h4,
            color: COLOR.text.primaryText,
          }}
        >
          {t(title)}
        </span>
        <Divider />
        {isMobile ? (
          <EditPasswordMobileView
            onFinish={onFinish}
            form={editPasswordForm}
            isConfirmPasswordCorrect={isConfirmPasswordCorrect}
            buttonLoading={buttonLoading}
          />
        ) : (
          <EditPasswordMobileView
            onFinish={onFinish}
            form={editPasswordForm}
            isConfirmPasswordCorrect={isConfirmPasswordCorrect}
            buttonLoading={buttonLoading}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default EditPasswordContainer;

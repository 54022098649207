import _ from "lodash";
import axios from "axios";
import moment from "moment-timezone";
import { ActionCreators } from "../redux/actions";
import { getStore } from "../redux/store/configureStore";
import * as Main from "./Main";

// const API_ORIGIN = getStore().getState().app.config.STATIC_SERVER_URL;
// const API2_ORIGIN = getStore().getState().app.config.API_URL;

export async function call(_method, _endpoint, _postObj, _opts, header = {}) {
  let company = getStore().getState().app.company;
  try {
    let opts = _opts || {};

    let method = _.toString(_method).toLowerCase();
    let endpoint = _.toString(_endpoint);
    let postObj = _.clone(_postObj) || {};
    let params = {};
    let baseURL = getStore().getState().app.config.API_URL;
    console.log("opts >>>", opts);

    console.log("baseURL >>>", baseURL);
    let headers = {
      ...header,
      "x-access-token": Main.getUserSession().access_token || "",
      "x-company-id": getStore().getState().app.company.company_id || null,
      "x-company-key": getStore().getState().app.company.company_key || null,
    };

    postObj = {
      ...postObj,
      // remove after revamp
      company_id: getStore().getState().app.company.company_id,
      // company_user_id: getStore().getState().app.user.company_user_id || 0,
      ts: moment().unix(),
    };

    // console.log(`getStore().getState() >> `, getStore().getState())

    if (method === "get") {
      params = postObj;
      postObj = null;
    }

    console.log('endpoint >>> ', endpoint);

    // let resp = await axios[method](endpoint, data);
    let resp = await axios({
      method,
      baseURL,
      url: endpoint,
      headers,
      // timeout: 5000,
      params, // GET
      data: postObj, // POST/PUT
      ...opts
      // withCredentials,
    });
    let respData = resp.data;

    let {
      status, errorCode, errorMessage, result
    } = respData;

    console.log(`respData >> `, respData);

    if (status <= 0) {
      console.error(`Service.call() Error :: ${errorCode} :: ${errorMessage}`);
      if (errorCode === -101) {
        window.location = `${getStore().getState().app.config.STATIC_SERVER_URL}/${company.company_key}`;
        return;
      }
      let errorObj = {
        ...respData,
        status,
        errorCode,
        errorMessage
      };
      return errorObj;
    }

    return respData;
  } catch (err) {
    console.error(err);
  }
  return null;
}

export function createURL(action, endpoint, content) {
  content = content || {};
  let url = "";
  url += endpoint;
  if (action.toLowerCase() === "get") {
    let queryStr = "";
    _.each(content, (val, key) => {
      queryStr += `${key}=${val}&`;
    });
    url += `?${queryStr}`;
  }
  return url;
}

// export async function getUser() {
//   let userData = await call('get', '/api/user');
//   if (userData) {
//     getStore().dispatch(ActionCreators.setAuth(true));
//     getStore().dispatch(ActionCreators.setAdmin(userData));
//   }
//   return userData;
// }

// export async function getCompany() {
//   let companyData = await call('get', '/api/company');
//   if (companyData) {
//     getStore().dispatch(ActionCreators.setCompany(companyData.result));
//   }
//   return companyData;
// }

let loading = false;

export async function quickAccess(history, back_to_home = false) {
  if (!loading) {
    try {
      loading = true;
      const localUserSession = Main.getLocalStorageUserSession();
      if (!_.isNull(localUserSession) && !_.isUndefined(localUserSession)) {
        const { session_token } = JSON.parse(localUserSession);
        const loginResp = await call("get", `/api/user/quickaccess?session_token=${session_token}`);
        if (loginResp?.status !== 1) {
          Main.clearUserSession();
          getStore().dispatch(ActionCreators.setAuth(false));
          getStore().dispatch(ActionCreators.setOrderInfo({}));
          getStore().dispatch(ActionCreators.setUser({}));
          getStore().dispatch(ActionCreators.setShoppingCartAmount(0));
          getStore().dispatch(ActionCreators.setUserSession({}));
          localStorage.removeItem("contactInfo");
          return history.push(`/${getStore().getState().app.company.company_key}`);
        }
        const {
          userSession,
          userInfo,
          orderList,
          shoppingCartItemArr
        } = loginResp;
        Main.setUserSession(userSession);
        getStore().dispatch(ActionCreators.setAuth(true));
        getStore().dispatch(ActionCreators.setOrderInfo(orderList));
        getStore().dispatch(ActionCreators.setUser(userInfo));
        getStore().dispatch(ActionCreators.setShoppingCartAmount(
          _.filter(shoppingCartItemArr, { parent_shopping_cart_item_id: 0 }).length
        ));
        getStore().dispatch(ActionCreators.setUserSession(userSession));
        return userInfo;
      }
      getStore().dispatch(ActionCreators.setAuth(false));
      if (back_to_home) return history.push(`/${getStore().getState().app.company.company_key}`);
    } catch (error) {
      console.error(error);
    } finally {
      loading = false;
    }
  }
}

export async function logout() {
  let result = await call("post", "/api/login/admin/logout");
  getStore().dispatch(ActionCreators.setAuth(false));
  getStore().dispatch(ActionCreators.setAdmin({}));
  return result;
}

export default call;


import React from "react";
import TokenMobileView from "./TokenMobileView";

const TokenDesktopView = ({
  ...others
}) => {
  return (
    <div style={{
      padding: "0 50px"
    }}
    >
      <TokenMobileView
        {...others}
      />
    </div>
  );
};

export default TokenDesktopView;

/* eslint-disable react/no-unescaped-entities */
import { Checkbox, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLOR } from "../../Theme";
import moment from "moment";

const ReminderModal = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const onCheckboxChange = (e) => {
    console.log({checked: e.target.checked});
    setChecked(e.target.checked);
  };

  const setRemindModalPopTime = () => {
    if (checked) {
      localStorage.setItem("paymentPopWarning", JSON.stringify({expire_time: moment().add(24, "hour").unix()}));
    };
  };

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      closable
      onCancel={() => { setRemindModalPopTime(); setVisible(false); }}
      footer={false}
      centered
      className="remind-modal"
    >
      <div style={{ textAlign: "center", marginTop: 16 }}>
        <div style={{ fontSize: 16, fontWeight: "bold" }}>
          {t("booking_reminder_title")}
        </div>
        <div style={{ padding: "16px 0", color: COLOR.captionText }}>
          {t("booking_reminder_desc")}
        </div>
        <Checkbox
          checked={checked}
          style={{ color: COLOR.captionText }}
          onChange={onCheckboxChange}
        >
          {t("booking_reminder_checkbox")}
        </Checkbox>
      </div>
    </Modal>
  );
};

export default ReminderModal;

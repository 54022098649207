import React, { useEffect, useState } from "react";
import {
  Grid,
  Modal,
  notification
} from "antd";
import _ from "lodash";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Elements } from "@stripe/react-stripe-js";
import moment from "moment";
import { useWindowWidth } from "@react-hook/window-size";
import AppLayout from "../components/AppLayout";
import PaymentMobilePage from "./PaymentMobilePage";
import PaymentWebPage from "./PaymentWebPage";
import { setLoading } from "../redux/actions/common";
import * as Service from "../core/Service";
import { BREAKPOINT, COLOR } from "../Theme";
import { ITEM_TYPE } from "../constants";

const PaymentPageContainer = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const { company, user } = app;
  const { token_ratio } = company;
  const { company_key, order_id: params } = useParams();
  const order_id = _.toInteger(params);
  const history = useHistory();
  const { t } = useTranslation();

  const [stripePromise, setStripePromise] = useState(null);
  const [step, setStep] = useState(1);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [orderDetail, setOrderDetail] = useState([]);

  const [useToken, setUseToken] = useState(false);
  const [token, setToken] = useState("");
  const [paymentType, setPaymentType] = useState("pre-payment");
  const [balance, setBalance] = useState(0);
  const [total, setTotal] = useState(
    orderDetail?.final_price || orderInfo?.final_price || 0
  );
  const [contactInfo, setContactInfo] = useState({
    nickname: "",
    no: "+852",
    mobile: "",
    email: "",
  });
  const [isAgree, setIsAgree] = useState(false);
  const [reminderVisible, setReminderVisible] = useState(false);

  const showReminderModal = () => {
    const localData = localStorage.getItem("paymentPopWarning");
    if (!localData) {
      setReminderVisible(true);
      return;
    }
    const { expire_time } = JSON.parse(localData);
    if (expire_time < moment().unix()) {
      setReminderVisible(true);
    }
  };

  useEffect(() => {
    if (user.company_user_id > 0 && !_.isEmpty(orderInfo) && !user.is_guest) {
      const [prefix, number] = orderInfo?.mobile ? _.split(orderInfo?.mobile, "-") : _.split(user?.mobile, "-");
      setContactInfo({
        nickname: (orderInfo?.nickname || user?.nickname) || "",
        no: _.startsWith(prefix, "+0") ? "+852" : prefix,
        mobile: _.startsWith(prefix, "+0") ? "" : number,
        email: _.startsWith(user?.email, "user") && _.endsWith(user?.email, "@vms.io") ? "" : (orderInfo?.email || user?.email) || "",
        special_requests: orderInfo?.remarks
      });
    }
  }, [user, orderInfo]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (company.company_id) {
      initData();
    }
  }, [company.company_id]);

  useEffect(() => {
    if (user.company_user_id > 0) {
      fetchData();
    }
  }, [order_id, needUpdate, user]);

  const fetchData = async () => {
    if (order_id === 0) {
      return history.replace(`/${company_key}`);
    }
    if (needUpdate) {
      window.scrollTo({ top: 0 });
    }
    getOrderDetail();
  };

  const initData = async () => {
    await initStripe(company.publishable_key);
  };

  const initStripe = async (publishable_key) => {
    if (!publishable_key) {
      return;
    }
    const stripe = await loadStripe(publishable_key);

    setStripePromise(stripe);
  };

  const getOrderDetail = async () => {
    dispatch(setLoading(true));
    let dataSource = [];
    let orderInfoSource = {};
    try {
      // server action
      const resp = await Service.call("get", "/api/order", { order_id });
      if (resp?.status === 1) {
        const { orderItemList, orderRc } = resp;
        orderInfoSource = orderRc;
        const { order_id, is_active, status } = orderRc;
        if (is_active === 0) {
          history.push(`/${company_key}`);
        }
        if (status !== "placed") {
          history.replace(`/${company_key}/order/${order_id}`);
        }
        const childCartItemGroup = _.groupBy(
          orderItemList,
          "parent_order_item_id"
        );

        let isBooking = false;

        dataSource = _.map(
          _.filter(orderItemList, { is_active: 1 }),
          (item) => {
            const {
              order_id,
              item_id,
              item_category_name,
              item_name,
              order_item_id,
              parent_order_item_id,
              final_price,
              start_time,
              end_time,
              quantity,
              item_desc,
              item_type,
              token_amount,
            } = item;

            if (item_type === 1) {
              isBooking = true;
            }

            const product_price = _.sumBy(
              childCartItemGroup[order_item_id],
              ({ final_price }) => final_price
            );
            const session_price = final_price;
            if (parent_order_item_id !== 0) {
              return null;
            }
            return {
              item_id,
              order_id,
              order_item_id,
              category_name: item_category_name,
              booking_item_name: item_name,
              start_time,
              end_time,
              product_price,
              session_price,
              item_desc,
              // total price
              price: product_price + session_price,
              childItems: childCartItemGroup[order_item_id] || [],
              quantity,
              item_type,
              token_amount
            };
          }
        );
        dataSource = _.compact(dataSource);
        dataSource.sort((a, b) => a.start_time - b.start_time);
        setOrderInfo(orderInfoSource);
        if (_.first(orderInfoSource?.order_type) === ITEM_TYPE.TOKEN) {
          setStep(2);
        }
        setOrderDetail(dataSource);

        if (isBooking) {
          showReminderModal();
        }
        return;
      }
      // fail case
      history.replace(`/${company_key}`);
    } catch (err) {
      console.error(err);
    } finally {
      setNeedUpdate(false);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (!_.isEmpty(orderInfo) && balance === 0 && total === 0) {
      const currentBalance = orderInfo.final_price;
      setTotal(currentBalance);
      setBalance(currentBalance);
    } else {
      let currentBalance = total;
      if (useToken && token > 0 && token * token_ratio >= 1) {
        currentBalance -= token * token_ratio;
      }
      // if (couponValid && discount > 0) {
      //   currentBalance -= discount;
      // }
      if (currentBalance < 0) {
        currentBalance = 0;
      }
      setBalance(currentBalance);
    }
  }, [total, useToken, token, balance, step]);

  const confirmPayment = async () => {
    try {
      let user_payment_type = 0;
      let token_used = 0;
      if (paymentType === "monthly-payment") {
        user_payment_type = 1;
      }
      let user_payment_method = [];

      if (useToken) {
        user_payment_method.push("token");
        token_used = token || 0;
      }

      if (balance === 0 && token_used > 0) {
        user_payment_method = ["token"];
      }

      const putObj = {
        order_key: orderInfo?.order_key,
        user_payment_type,
        user_payment_method,
        token_used,
        company_payment_method_id: 0,
      };
      const resp = await Service.call("put", "/api/payment", putObj);
      if (resp?.status === 1) {
        notification.success({ message: t("success") });
        history.push(`/${company.company_key}/order/${order_id}`);
        return;
      }
      // fail case
      if (resp?.errorCode === -60007) {
        return Modal.error({
          title: t("not_enough_token"),
          okButtonProps: {
            style: {
              borderColor: COLOR.brandSecondary,
              backgroundColor: COLOR.brandSecondary,
              color: COLOR.whiteSecondary,
            },
          },
        });
      }
      notification.error({ message: t(resp?.errorMessage) });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AppLayout className="payment-page-layout">
      <Elements stripe={stripePromise} options={{ locale: "en" }}>
        {isMobile ? (
          <PaymentMobilePage
            stripePromise={stripePromise}
            setStep={setStep}
            step={step}
            setNeedUpdate={setNeedUpdate}
            orderInfo={orderInfo}
            orderDetail={orderDetail}
            confirmPayment={confirmPayment}
            paymentType={paymentType}
            useToken={useToken}
            setUseToken={setUseToken}
            token={token}
            setToken={setToken}
            total={total}
            balance={balance}
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
            setIsAgree={setIsAgree}
            setReminderVisible={setReminderVisible}
            isAgree={isAgree}
            reminderVisible={reminderVisible}
          />
        ) : (
          <PaymentWebPage
            stripePromise={stripePromise}
            setStep={setStep}
            step={step}
            setNeedUpdate={setNeedUpdate}
            orderInfo={orderInfo}
            orderDetail={orderDetail}
            confirmPayment={confirmPayment}
            paymentType={paymentType}
            useToken={useToken}
            setUseToken={setUseToken}
            token={token}
            setToken={setToken}
            total={total}
            balance={balance}
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
            setIsAgree={setIsAgree}
            setReminderVisible={setReminderVisible}
            isAgree={isAgree}
            reminderVisible={reminderVisible}
          />
        )}
      </Elements>
    </AppLayout>
  );
};

export default PaymentPageContainer;

import { Button, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import COLOR from '../../Theme/color';

const PaymentStatus = ({ status, order_id, setStatus }) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const { company } = app;
  const statusInfo = {
    fail: {
      title: t("payment_failed"),
      desc: t("payment_failed_message"),
      icon: <img src="/payment/fail.svg" width={26} height={26} alt="" />,
      color: COLOR.negative.negative500,
      // url: `/${company.company_key}/payment/${order_id}`
    },
    pending: {
      title: t("payment_pending"),
      desc: t("payment_confirm_message"),
      icon: <img src="/payment/pending.svg" width={26} height={26} alt="" />,
      color: "#FFA31F",
      url: `/${company.company_key}/order/${order_id}`
    },
    processing: {
      title: t("payment_processing"),
      desc: t("payment_processing_message"),
      icon: <Spin />,
      color: COLOR.brandPrimary,
    },
    success: {
      title: t("payment_success"),
      desc: t("payment_confirm_message"),
      icon: <img src="/payment/success.svg" width={26} height={26} alt="" />,
      color: COLOR.success.success400,
      url: `/${company.company_key}/order/${order_id}`
    },
  };

  const {
    title,
    desc,
    icon,
    color,
    url
  } = statusInfo[status] || {};
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 650
      }}
    >
      <div>
        <div style={{ color, textAlign: "center" }}>
          {icon}
          <div style={{ padding: 8 }}>{title}</div>
        </div>
        <div style={{ color: COLOR.captionText }}>{desc}</div>
        {(status === "fail" || status === "success" || status === "pending") && (
          <Button
            type="primary"
            htmlType="submit"
            disabled={false}
            className="action-btn"
            style={{ marginTop: 16 }}
            onClick={() => {
              if (url) {
                return history.push(url);
              }
              setStatus(null);
            }}
          >
            {status === "fail" && t("back_to_pay")}
            {(status === "success" || status === "pending") && t("check_detail")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaymentStatus;

import { getStore } from '../store/configureStore';
import * as Service from '../../core/Service';

export const getBookingData = async () => {
  let { selectedItemCategory, selectedItem, selectedDate } = getStore().getState().app
  let response = [];

  if (selectedItemCategory !== 0 && selectedItem !== 0 && selectedDate !== 0) {
    response = await Service.call('post', `/api/app/booking/bydate/${selectedItem}/${selectedDate}`)
    console.log('response>>>', response)
    if (response.status > 0) {
      return response.result
    }
  }
  return response
}

export const getRuleSet = async () => {
  let { selectedItemCategory, selectedItem, selectedDate } = getStore().getState().app
  let response = [];

  if (selectedItemCategory !== 0 && selectedItem !== 0) {
    response = await Service.call('get', `/api/app/item/detail`, {item_id: selectedItem})
    console.log('response>>>', response)
    if (response.status > 0) {
      return response.ruleset
    }
  }
  return response
}

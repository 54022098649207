import React, { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Button
} from "antd";
import { FONT, COLOR } from "../Theme";

const ConfirmModal = ({
  title,
  visible,
  children,
  setModalVisible,
  confirmButtonStr,
  cancelButtonStr,
  onCancel,
  onConfirm,
  cancelButtonStyle,
  confirmButtonStyle,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose
      onCancel={() => setModalVisible(false)}
    >
      <Row
        gutter={[20, 20]}
      >
        <Col
          span={24}
          align="middle"
          style={{
            ...FONT.h6,
            color: COLOR.text.blackText
          }}
        >
          {title}
        </Col>
        <Col
          span={24}
          align="middle"
        >
          {children}
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            gap: "10px"
          }}
        >
          <Button
            loading={buttonLoading}
            style={{
              ...FONT.buttonRegular,
              backgroundColor: COLOR.function.white,
              color: COLOR.text.primaryText,
              borderRadius: 8,
              borderColor: COLOR.text.primaryText,
              height: 48,
              width: "100%",
              ...cancelButtonStyle
            }}
            onClick={() => onCancel()}
          >
            {cancelButtonStr}
          </Button>
          <Button
            loading={buttonLoading}
            style={{
              ...FONT.buttonRegular,
              backgroundColor: COLOR.primary.primary500,
              color: COLOR.text.whiteText,
              borderColor: COLOR.text.primaryText,
              borderRadius: 8,
              height: 48,
              width: "100%",
              ...confirmButtonStyle
            }}
            onClick={async () => {
              setButtonLoading(true);
              try {
                await onConfirm();
                setModalVisible(false);
              } catch (error) {
                console.error(error);
              } finally {
                setButtonLoading(false);
              }
            }}
          >
            {confirmButtonStr}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmModal;

import React from "react";
import { useTranslation } from "react-i18next";
import OrderCardWrapper from "../components/order/OrderCardWrapper";
import {
  Button,
  Divider
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { COLOR, FONT } from "../Theme";

const NeedHelpCard = ({
  title,
  description
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { company_key } = useParams();

  return (
    <OrderCardWrapper title={title} style={{ backgroundColor: COLOR.function.background }}>
      {
        description && (
          <div
            style={{
              ...FONT.bodyText2Regular,
              color: COLOR.text.captionText,
              marginTop: 24
            }}
          >
            {description}
            <Divider />
          </div>
        )
      }
      <Button
        className="ghost-btn"
        type="primary"
        ghost
        onClick={() => {
          history.push(`/${company_key}/contact/us`);
        }}
      >
        {t("contact_us")}
      </Button>
    </OrderCardWrapper>
  )
}

export default NeedHelpCard;
/* eslint-disable implicit-arrow-linebreak */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Button, Col, Row, Space, Spin, Tabs, Grid } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useWindowWidth } from "@react-hook/window-size";

import { setLoading } from "../redux/actions/common";
import AppLayout from "../components/AppLayout";

import { BREAKPOINT, COLOR } from "../Theme";

import * as Service from "../core/Service";
import * as Main from "../core/Main";

const OrderReceiptPage = () => {
  const { user } = useSelector((state) => state.app);
  const [orderList, setOrderList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.company_user_id > 0) {
      window.scrollTo({ top: 0 });
      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      let url = "/api/user/order";
      let resp = await Service.call("GET", url);
      if (!resp || resp.status !== 1) {
        return;
      }
      const { orderList: orderArr } = resp.data;
      setOrderList(_.orderBy(orderArr, ["ctime"], ["desc"]));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <AppLayout className="order-layout">
      <OrderReceiptSection orderList={orderList} />
    </AppLayout>
  );
};

export default OrderReceiptPage;

const OrderReceiptSection = ({ orderList }) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  return (
    <div style={{ margin: !isMobile && "2% 15%" }}>
      <div className="order-title-layout">
        <div className="order-title">{t("order_receipt")}</div>
      </div>
      <div className="order-receipt-layout">
        <Tabs className="order-receipt-tabs" defaultActiveKey="1">
          <Tabs.TabPane tab={t("all")} key="1">
            <OrderReceiptList orderList={orderList} filter="all" />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("pending")} key="2">
            <OrderReceiptList orderList={orderList} filter="pending" />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("complete")} key="3">
            <OrderReceiptList orderList={orderList} filter="complete" />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("cancelled")} key="4">
            <OrderReceiptList orderList={orderList} filter="cancelled" />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const OrderReceiptList = ({ orderList, filter }) => {
  const { company_key } = useParams();

  switch (filter) {
    case "pending":
      orderList = _.filter(
        orderList,
        ({ status }) => status === "pending_approve"
      );
      break;
    case "complete":
      orderList = _.filter(
        orderList,
        ({ status }) => status === "payment_confirmed"
      );
      break;
    case "cancelled":
      orderList = _.filter(
        orderList,
        ({ status }) =>
          status === "cancelled" ||
          status === "expired" ||
          status === "voided" ||
          status === "payment_refund" ||
          status === "payment_refund_token" ||
          status === "payment_refund_token_offline" ||
          status === "partial_payment_refund" ||
          status === "partial_payment_refund_token" ||
          status === "partial_payment_refund_offline"
      );
      break;
    default:
      break;
  }
  return (
    <>
      {_.isEmpty(orderList) ? (
        <NoRecord minHeight="70vh" />
      ) : (
        <>
          {_.map(orderList, (order) => (
            <OrderDetailCard
              key={order?.order_key}
              data={order}
              company_key={company_key}
            />
          ))}
        </>
      )}
    </>
  );
};

const OrderDetailCard = ({ data, company_key }) => {
  const {
    order_id,
    order_key,
    status,
    ctime,
    order_type,
    total_item = 0,
    token_earned = null,
  } = data;

  const { t } = useTranslation();
  const history = useHistory();
  const { displayStr, color } = Main.getPaymentStatus(status);

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  return (
    <Row gutter={[0, 6]} className="order-receipt-card">
      <Col span={24}>
        <ListItem
          title={t("order_number")}
          value={`#${order_key}`}
          titleStyle={{ color: "#000" }}
        />
      </Col>
      <Col span={24}>
        <ListItem
          title={t("type")}
          value={
            <Space>
              {_.map(order_type, (type) => `${t(Main.ITEM_TYPE[type])}`)}
            </Space>
          }
        />
      </Col>
      <Col span={24}>
        <ListItem title={t("item")} value={total_item} />
      </Col>
      <Col span={24}>
        <ListItem
          title={t("book_on")}
          value={moment.unix(ctime).format("DD-MM-YYYY HH:mm")}
        />
      </Col>

      <Col span={24}>
        <ListItem
          title={t("payment_status")}
          value={t(displayStr)}
          valueStyle={{ color }}
        />
      </Col>
      {!_.isNil(token_earned) && (
        <Col span={24}>
          <Space size={4} style={{ color: COLOR.captionText }}>
            {t("you_get")}
            <span style={{ fontWeight: "bold", color: COLOR.brandPrimary }}>
              {token_earned}
            </span>
            {t("point_for_this_order")}
          </Space>
        </Col>
      )}
      <Col span={24} style={{ textAlign: "right" }}>
        <Button
          className={`ghost-btn${!isMobile ? " order-ghost-btn" : ""}`}
          type="primary"
          ghost
          onClick={() => {
            return history.push(`/${company_key}/order/${order_id}`);
          }}
        >
          {t("review_booking")}
        </Button>
      </Col>
    </Row>
  );
};

export const ListItem = ({ title, value, titleStyle = {}, valueStyle = {} }) => {
  return (
    <Row justify="space-between" align="middle">
      <Col style={{ color: COLOR.captionText, ...titleStyle }}>{title}:</Col>
      <Col style={{ color: "#000", ...valueStyle }}>{value}</Col>
    </Row>
  );
};

export const NoRecord = ({ minHeight }) => {
  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight, flexDirection: "column" }}
    >
      <ExclamationCircleOutlined />
      <span style={{ marginTop: 8 }}>No Record</span>
    </Row>
  );
};
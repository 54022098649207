import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Affix,
  Image,
  Popover,
  List,
  Menu,
  Grid,
  Button,
  Drawer,
  Space,
  Divider,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import {
  MenuOutlined,
  ShoppingCartOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {useWindowWidth} from "@react-hook/window-size";
import {
  setAppModal,
  setDrawerVisible,
  setQRCodeVisible,
  setSelectedDate,
  setSelectedItem,
  setSelectedItemCategory,
} from "../redux/actions/common";
import TNContent from "../TNCore/TNContent";
import RegisterModal from "./register/RegisterModal";
import ShoppingCart from "./ShoppingCart";
import ShoppingCartDrawer from "./ShoppingCartDrawer";
import * as Main from "../core/Main";
import CompanyLogo from "./CompanyLogo";
import { COLOR, FONT, BREAKPOINT } from "../Theme";
import Navbar from "./Navbar";

const default_logo_img = require("../assets/default_logo.png");

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const AppHeader = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  return (
    <Affix>
      <div style={{ padding: "10px 20px", background: "white" }}>
        {isMobile ? <MobileHeader /> : <Navbar />}
      </div>
    </Affix>
  );
};

const MobileHeader = (props) => {
  const { isMobile, closeHeader = () => { } } = props;
  const [visible, setVisible] = useState(false);
  const {
    shoppingCartAmount
  } = useSelector((state) => state.app);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    client,
    config,
    user,
    company
  } = useSelector((state) => state.app);

  const { STATIC_SERVER_URL } = config;

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <CompanyLogo customStyle={{ maxWidth: 150 }} />
        </Col>
        <Col>
          {!company?.allow_guest_checkout && (
          <Button
            type="link"
            icon={(
              <img
                src={`${client?.config?.STATIC_SERVER_URL}/qr_code.svg`}
                width="22"
                alt=""
              />
            )}
            onClick={() => dispatch(setQRCodeVisible(true))}
          />
          )}
          <Button
            type="link"
            icon={(
              <img
                src={shoppingCartAmount > 0
                  ? `${client?.config?.STATIC_SERVER_URL}/cart_active.svg`
                  : `${client?.config?.STATIC_SERVER_URL}/cart.svg`}
                width="22"
                alt="cart"
              />
            )}
            onClick={() => history.push(`/${company.company_key}/shopping_cart`)}
          />
          <Button
            type="link"
            onClick={() => setVisible(true)}
            icon={(
              <img
                src={`${STATIC_SERVER_URL}/drawer.svg`}
                width="20"
                alt="drawerlogo"
              />
            )}
          />
        </Col>
      </Row>
      <Drawer
        placement="top"
        height={1000}
        visible={visible}
        closable={false}
        style={{
          backgroundColor: "#F3EEEA"
        }}
      >
        <Row justify="space-between">
          <Col>
            <CompanyLogo customStyle={{ maxWidth: 150 }} />
          </Col>
          <Col>
            <Button
              type="link"
              onClick={() => setVisible(false)}
              icon={(
                <img
                  src={`${STATIC_SERVER_URL}/times.svg`}
                  width="12"
                  alt="drawerlogo"
                />
              )}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col span={24}>
            <UpperHeaderMenu
              onClose={() => setVisible(false)}
            />
          </Col>
          {/* <Col span={24}>
            <MediaHeaderMenu />
          </Col> */}
        </Row>
        {/* <Row justify="start" style={{ marginTop: "5vw", marginBottom: "50px" }}>
          <Col span={24}>{htmlToReactParser.parse(meta.copy_right)}</Col>
        </Row> */}
      </Drawer>
    </>
  );
};

const MediaHeaderMenu = () => {
  const { companySiteMeta, config, company } = useSelector(
    (state) => state.app
  );
  const { STATIC_SERVER_URL } = config;

  const { media_facebook = "", media_instagram = "" } = companySiteMeta;

  return (
    <>
      <Row justify="center" align="middle" style={{ fontSize: 16 }}>
        <a target="_blank" href={media_instagram} rel="noopener noreferrer">
          <Col>
            <Space style={{ color: "black" }}>
              <img
                src={`${STATIC_SERVER_URL}/instagram.png`}
                height="20"
                width="20"
              />
              {company.company_name}
            </Space>
          </Col>
        </a>
      </Row>

      <Row justify="center" align="middle" style={{ fontSize: 16 }}>
        <a target="_blank" href={media_facebook} rel="noopener noreferrer">
          <Col>
            <Space style={{ color: "black" }}>
              <img
                src={`${STATIC_SERVER_URL}/facebook.png`}
                height="20"
                width="20"
              />
              {company.company_name}
            </Space>
          </Col>
        </a>
      </Row>
    </>
  );
};

// This is for mobile /
const UpperHeaderDrawer = (props) => {
  const { client, config } = useSelector((state) => state.app);
  const { meta } = client;
  const {
    newSection = null,
    webIntroductionSection = null,
    facilitiesSection = null,
    aboutUsSection = null,
    contactUsSection = null,
  } = props;
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("top");
  const { STATIC_SERVER_URL } = config;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer
      // placement={placement}
      height={1000}
      visible={visible}
      closable={false}
      style={{ backgroundColor: "#F3EEEA" }}
      bodyStyle={{ padding: "0px 20px" }}
    >
      <Row justify="center" style={{ marginTop: "40px" }}>
        <UpperHeaderMenu
          onClose={onClose}
          newSection={newSection}
          webIntroductionSection={webIntroductionSection}
          facilitiesSection={facilitiesSection}
          aboutUsSection={aboutUsSection}
          contactUsSection={contactUsSection}
        />
      </Row>
      <Row justify="center" style={{ marginTop: "100px" }}>
        <Col>
          <MediaHeaderMenu />
        </Col>
      </Row>
      {!_.isEmpty(meta)
              && meta.copy_right
              && (
                <Row justify="start" style={{ marginTop: "5vw", marginBottom: "50px" }}>
                  <Col span={24}>{htmlToReactParser.parse(meta.copy_right)}</Col>
                </Row>
              )}
    </Drawer>
  );
};

const UpperHeaderMenu = (props) => {
  const {
    company, config, user, companySiteMeta, auth
  } = useSelector((state) => state.app);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const history = useHistory();
  const { company_key } = useParams();
  let {
    onClose = null,
    // newSection = null,
    // webIntroductionSection = null,
    // facilitiesSection = null,
    // aboutUsSection = null,
    // contactUsSection = null,
  } = props;

  return (
    <Menu
      className="menu-list"
      style={{
        width: isMobile ? "100%" : "12vw",
        fontSize: "16px",
        // fontFamily: "Noto Sans TC",
        textAlign: "center",
        background: isMobile ? "none" : "",
        marginTop: isMobile ? 0 : 20,
        fontWeight: "400",
        color: "#000000",
      }}
      mode="inline"
    >

      <Menu.Item
        onClick={() => {
          history.push(`/${company.company_key}`);
          if (isMobile) onClose();
        }}
      >
        <span
          style={styles.menuItem}
        >
          {t("home")}
        </span>
      </Menu.Item>
      {Boolean(company?.allow_booking) && (
        <Menu.Item
          onClick={() => {
            history.push(`/${company.company_key}/booking`);
            if (isMobile) onClose();
          }}
        >
          <span style={styles.menuItem}>
            {t("booking")}
          </span>
        </Menu.Item>
      )}
      {Boolean(company?.allow_token) && (
        <Menu.Item
          onClick={() => {
            history.push(`/${company.company_key}/token`);
            if (isMobile) onClose();
          }}
        >
          <span style={styles.menuItem}>
            {t("token")}
          </span>
        </Menu.Item>
      )}
      {Boolean(company?.allow_event) && (
        <Menu.Item
          onClick={() => {
            history.push(`/${company.company_key}/event/list`);
            if (isMobile) onClose();
          }}
        >
          <span style={styles.menuItem}>
            {t("event")}
          </span>
        </Menu.Item>
      )}
      {Boolean(company?.allow_membership) && (
      <Menu.Item
        onClick={() => {
          history.push(`/${company.company_key}/membership/list`);
          if (isMobile) onClose();
        }}
      >
        <span style={styles.menuItem}>
          {t("membership")}
        </span>
      </Menu.Item>
      )}
      {Boolean(_.toInteger(companySiteMeta.blog_post_enable)) && (
        <Menu.Item
          onClick={() => {
            history.push(`/${company.company_key}/blog_post`);
            if (isMobile) onClose();
          }}
        >
          <span
            style={styles.menuItem}
          >
            {t("blog")}
          </span>
        </Menu.Item>
      )}
      {Boolean(_.toInteger(companySiteMeta.news_enable)) && (
        <Menu.Item
          onClick={() => {
            history.push(`/${company.company_key}/news`);
            if (isMobile) onClose();
          }}
        >
          <span
            style={styles.menuItem}
          >
            {t("news")}
          </span>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          history.push(`/${company_key}/contact/us`);
          if (isMobile) onClose();
        }}
      >
        <span
          style={styles.menuItem}
        >
          {t("contact_us")}
        </span>
      </Menu.Item>

      <Divider
        style={{
          marginTop: "100px",
        }}
      />
      <Button
        style={{
          backgroundColor: "transparent",
          color: i18n.language === "zh-HK"
            ? COLOR.text.primaryText : COLOR.function.disable,
          // padding: '0px 40px',
          height: 50,
          marginBottom: "10px",
          borderRadius: 0,
          borderWidth: "2px",
          border: "none",
          borderColor: "#9E8677",
          fontSize: "14px",
          fontWeight: 400
        }}
        onClick={() => {
          i18n.changeLanguage("zh-HK");
        }}
      >
        中文
      </Button>
      <Button
        style={{
          backgroundColor: "transparent",
          color: i18n.language === "en"
            ? COLOR.text.primaryText : COLOR.function.disable,
          // padding: "0px 40px",
          height: 50,
          marginBottom: "10px",
          borderRadius: 0,
          borderWidth: "2px",
          border: "none",
          borderColor: "#9E8677",
          fontSize: "14px",
          fontWeight: 400
        }}
        onClick={() => {
          i18n.changeLanguage("en");
        }}
      >
        ENG
      </Button>
      {
        auth && user?.company_user_id > 0 && !company?.allow_guest_checkout && (
          <Menu.Item
            onClick={() => {
              if (isMobile) onClose();
            }}
          >
            <Link
              to={{
                pathname: `/${company.company_key}/profile`,
              }}
              style={styles.menuItem}
            >
              {t("my_account")}
            </Link>
          </Menu.Item>
        )
      }
      {!company?.allow_guest_checkout
        ? auth && !user.is_guest ? (
          <Menu.Item>
            <Button
              type="link"
              style={{
                ...styles.menuItem,
                color: COLOR.text.primaryText
              }}
              onClick={() => Main.logout(history, company.company_key)}
            >
              {t("logout")}
            </Button>
          </Menu.Item>
        ) : (
          <>
            <Menu.Item>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: COLOR.primary.primary500,
                  color: "#FFFFFF",
                  height: "48px",
                  borderRadius: "10px"
                }}
                onClick={() => dispatch(setAppModal({
                  type: "register",
                  appModalVisible: true
                }))}
              >
                {t("sign_up")}
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Button
                style={{
                  ...FONT.titleTextRegular,
                  color: COLOR.text.primaryText,
                  height: "48px",
                  border: 0,
                  width: "100%",
                }}
                onClick={() => dispatch(setAppModal({
                  type: "login",
                  appModalVisible: true
                }))}
              >
                {t("login")}
              </Button>
            </Menu.Item>
          </>
        ) : null}
      {/* {user?.company_user_id > 0 ? (
        <>
          <Menu.Item
            onClick={() => {
              if (isMobile) onClose();
            }}
          >
            <Link
              to={{
                pathname: `/${company.company_key}/profile`,
              }}
              style={styles.menuItem}
            >
              {t("my_account")}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Button
              type="link"
              style={{
                ...styles.menuItem,
                color: COLOR.text.primaryText
              }}
              onClick={() => Main.logout(history, company.company_key)}
            >
              {t("logout")}
            </Button>
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item>
            <Button
              style={{
                width: "100%",
                backgroundColor: COLOR.primary.primary500,
                color: "#FFFFFF",
                height: "48px",
                borderRadius: "10px"
              }}
              onClick={() => dispatch(setAppModal({
                type: "register",
                appModalVisible: true
              }))}
            >
              {t("sign_up")}
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              style={{
                ...FONT.titleTextRegular,
                color: COLOR.text.primaryText,
                height: "48px",
                border: 0,
                width: "100%",
              }}
              onClick={() => dispatch(setAppModal({
                type: "login",
                appModalVisible: true
              }))}
            >
              {t("login")}
            </Button>
          </Menu.Item>
        </>
      )} */}
    </Menu>
  );
};

export default AppHeader;

const colResponsiveDesktop = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 24,
  xl: 24,
  xxl: 24,
};

const colResponsiveMobile = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 0,
  xl: 0,
  xxl: 0,
};

const styles = {
  logo: {
    width: "auto",
    height: "55px",
    objectFit: "contain",
    paddingBottom: "5px",
  },
  logoSpan: {
    margin: "0 20px",
    color: "black",
    fontSize: "20px",
    lineHeight: "75px",
  },
  logoMobile: {
    width: "auto",
    height: "40px",
    objectFit: "contain",
    paddingBottom: "5px",
    maxWidth: "180px",
  },
  container: {
    backgroundColor: "#FFFFFF",
    height: "76px",
    padding: "0px 5%",
    // borderBottom: '1px solid #F5F5F5',
  },
  menuItem: {
    ...FONT.titleTextRegular,
    color: COLOR.text.captionText
  }
};

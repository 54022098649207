import React, { Component } from 'react';
import { Spin, message } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import { ActionCreators } from './redux/actions';
import LoadingIcon from './components/LoadingIcon';

// export default function PrivateRoute(props) {
//   const app = useSelector((state) => state.app);

//   return (
//     <Route
//       render={() => {
//         return (
//           app.auth === true
//             ? <Component />
//             : <Redirect to="/login" />
//         )}}
//     />
//   );
// }

class PrivateRoute extends Component {
  render() {
    // const {component: Component, app, ...rest} = this.props;
    const {
      component: AppComponent,
      // privilege,
      app,
    } = this.props;

    if (app.auth === null) {
      return (
        <div style={{position: 'absolute', top: '50%', left: '50%'}}>
          <Spin indicator={<LoadingIcon />} />
        </div>
      )
    }
    // let logoutWay = app.logoutWay;

    return (
      <Route
        render={(props) => {
          if (app.auth && app.user.company_user_id) {
            return <AppComponent />
          }
          return <Redirect to="/login" />

          //   return (
          //     app.auth
          //     ? <AppComponent {...props}/>
          //     : <Redirect to="/admin/login" />
          // )
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);

import { Button, Col, Form, Input, Row, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const RegistrationForm = ({
  eventMeta,
  buttonStyle,
  buttonTitle,
  onFinish,
  loading,
}) => {
  const { event_registration_form_fields } = eventMeta;

  const { t } = useTranslation();
  const [formFields, setFormFields] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (event_registration_form_fields) {
      const form_fields = JSON.parse(event_registration_form_fields);
      setFormFields(form_fields.sort((a, b) => a.sorting - b.sorting));
    }
  }, []);

  const onSubmit = (values) => {
    if (_.has(values, "mobile")) {
      values.mobile = values.mobile ? values.no.concat(values.mobile) : "";
      delete values.no;
    }
    return onFinish(values);
  };

  return (
    <Form
      form={form}
      className="event-registration-form"
      name="registration-form"
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ no: "+852" }}
    >
      <div style={{ color: "#000", fontWeight: "bold", marginBottom: 24 }}>
        {t("registration_info")}
      </div>
      {_.map(formFields, (field) => {
        return field.field_type === "mobile" ? (
          <Row gutter={16} align="middle" key={field.field_type}>
            <Col>
              <Form.Item name="no" label={t("no")}>
                <Select>
                  <Option value="+852">+852</Option>
                  <Option value="+86">+86</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label={t(field.field_type)}
                name={field.field_type}
                rules={[{ required: field.mandatory, message: `${t(field.field_type)} is required` }]}
              >
                <Input allowClear placeholder={t(field.field_type)} />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Form.Item
            key={field.field_type}
            label={t(field.field_type)}
            name={field.field_type}
            rules={[{ required: field.mandatory, message: `${t(field.field_type)} is required`}]}
          >
            <Input allowClear placeholder={t(field.field_type)} type={field.field_type === "email" ? "email" : "text"} />
          </Form.Item>
        );
      })}
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          type="primary"
          style={{ ...buttonStyle }}
          htmlType="submit"
          loading={loading}
        >
          {buttonTitle}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegistrationForm;

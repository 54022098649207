import React, { Component } from "react";
import {
  BrowserRouter, Redirect, Switch, Route
} from "react-router-dom";
import "antd/dist/antd.min.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "./redux/actions";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import NotFound from "./components/NotFound";

import LandingPage from "./pages/LandingPage";
import BookingCategoryDetailPage from "./pages/BookingCategoryDetailPage";

import BookingCategoryPage from "./pages/BookingCategoryPage";
import BlogPostListPage from "./pages/BlogPostListPage";
import BlogPostInfoPage from "./pages/BlogPostInfoPage";

import NewsListPage from "./pages/NewsListPage";
import NewsPostInfoPage from "./pages/NewsPostInfoPage";

import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import ContactUsPage from "./pages/ContactUsPage";

import PaymentPageContainer from "./pages/PaymentPageContainer";
import OrderReceiptPage from "./pages/OrderReceiptPage";
import OrderDetailPage from "./pages/OrderDetailPage";
import ShoppingCartContainer from "./pages/ShoppingCartContainer";

import ProfilePageContainer from "./pages/ProfilePageContainer";
import EditProfileContainer from "./pages/EditProfileContainer";
import EditPasswordContainer from "./pages/EditPasswordContainer";
import EventPageContainer from "./pages/EventPageContainer";
import EventListContainer from "./pages/EventListContainer";

import TokenPage from "./pages/TokenPage";
import PrivacyAndPolicyPage from "./pages/PrivacyAndPolicyPage";
import MembershipListContainer from "./pages/MembershipListContainer";

class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          <Route restricted path="/404" component={NotFound} exact />
          <PublicRoute restricted path="/:company_key" component={LandingPage} exact />
          <PublicRoute restricted path="/:company_key/booking" component={BookingCategoryPage} exact />
          <PublicRoute restricted path="/:company_key/blog_post" component={BlogPostListPage} exact />
          <PublicRoute restricted path="/:company_key/blog_post/:blog_post_id" component={BlogPostInfoPage} exact />
          <PublicRoute restricted path="/:company_key/news" component={NewsListPage} exact />
          <PublicRoute restricted path="/:company_key/news/:blog_post_id" component={NewsPostInfoPage} exact />
          <PublicRoute restricted path="/:company_key/terms/conditions" component={TermsAndConditionPage} exact />
          <PublicRoute restricted path="/:company_key/privacy/policy" component={PrivacyAndPolicyPage} exact />
          <PublicRoute restricted path="/:company_key/contact/us" component={ContactUsPage} exact />
          <PublicRoute restricted path="/:company_key/category/:category" component={BookingCategoryDetailPage} exact />
          <PublicRoute restricted path="/:company_key/event/list" component={EventListContainer} exact />
          <PublicRoute restricted path="/:company_key/membership/list" component={MembershipListContainer} exact />
          <PublicRoute restricted path="/:company_key/event/:event_key" component={EventPageContainer} exact />
          <PublicRoute restricted path="/:company_key/token" component={TokenPage} exact />
          {/* user route */}
          <PublicRoute restricted path="/:company_key/shopping_cart" component={ShoppingCartContainer} privilege="user" exact />
          <PublicRoute restricted path="/:company_key/payment/:order_id" component={PaymentPageContainer} privilege="user" exact />
          <PublicRoute restricted path="/:company_key/profile" component={ProfilePageContainer} privilege="user" no_guest exact />
          <PublicRoute restricted path="/:company_key/profile/edit" component={EditProfileContainer} privilege="user" no_guest exact />
          <PublicRoute restricted path="/:company_key/profile/edit_password" component={EditPasswordContainer} privilege="user" no_guest exact />
          <PublicRoute restricted path="/:company_key/order/:order_id" component={OrderDetailPage} privilege="user" exact />
          <PublicRoute restricted path="/:company_key/order" component={OrderReceiptPage} privilege="user" exact />
          <Redirect exact from="/*" to="/404" />
        </Switch>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);

import React, { useState } from "react";
import { Modal, Row, Grid, Col } from "antd";
import ImageCarousel from "./bookingDetail/ImageCarousel";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useWindowWidth } from "@react-hook/window-size";
import { BREAKPOINT } from "../Theme";

const ImageBanner = ({ imgArr = [] }) => {
  const { MEDIA_URL } = useSelector((state) => state.app.client.config);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [imgIndex, setImgIndex] = useState(1);

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  return (
    <div>
      <Row style={{ position: "relative" }}>
        <img
          alt="cover_image"
          src={`${MEDIA_URL}/${imgArr?.[0]}`}
          style={{
            width: "100%",
            height: isMobile ? "56.25vw" : 380,
            objectFit: "contain",
          }}
        />
        {!_.isEmpty(imgArr) && (
          <Row
            className="show-image-carousel"
            onClick={() => setImageModalVisible(true)}
          >
            {`+ ${imgArr.length} photo`}
          </Row>
        )}
      </Row>
      <Modal
        visible={imageModalVisible && !_.isEmpty(imgArr)}
        footer={null}
        onCancel={() => setImageModalVisible(false)}
        wrapClassName="image-carousel-modal"
      >
        <ImageCarousel
          coverImgArr={imgArr}
          setImageIndex={setImgIndex}
          showDots={false}
        />
        <div className="image-carousel-detail">
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              padding: 16,
            }}
          >
            <Row justify="space-between">
              <Col>{`${imgIndex} / ${imgArr.length}`}</Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImageBanner;

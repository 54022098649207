import _ from "lodash";
import React, { useRef, useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Select,
  Tabs,
  Grid,
  Pagination,
  Button
} from "antd";
import Carousel from "react-multi-carousel";
import { ArrowRightOutlined } from "@ant-design/icons";
// import * as Service from '../../lib-client/service';
import { useSelector } from "react-redux";
// import { useRouter } from 'next/router';
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";
import AppLayout from "../AppLayout";
import TNContent from "../../TNCore/TNContent";
import { BREAKPOINT, COLOR, FONT } from "../../Theme";
import dayjs from "dayjs";

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const { Paragraph } = Typography;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const NewsCarousel = (props) => {
  const { newsList } = props;
  const [activeTab, setActiveTab] = useState("all");
  const [currentStart, setCurrentStart] = useState(0);
  const [currentEnd, setCurrentEnd] = useState(12);
  const [currentSize, setCurrentSize] = useState(0);
  const [current, setCurrent] = useState(1);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.sm;
  const app = useSelector((state) => state.app);
  const tabRef = useRef();
  //   const router = useRouter();

  useEffect(() => {
    // setActiveTab('all');
    // setCurrentStart(0);
    // setCurrentEnd(isMobile ? 3 : 12);
    // setCurrentSize(0);
    // setCurrent(1);
    // setCurrentSize(postList.all.length);
  }, [isMobile]);

  return (<CategoryCarousel postList={newsList} />);
};

const CategoryCarousel = ({ postList }) => {
  const app = useSelector((state) => state.app);
  const carouselNewItem = useRef(null);
  const { t, i18n } = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.sm;
  const history = useHistory();

  const getSelectedPostsCard = () => {
    return _.map(
      _.orderBy(postList, ["sorting"], ["desc"]),
      (eachPost, index) => {
        const {
          title,
          post_date,
          description,
          banner,
          blog_post_id,
        } = eachPost;
        return (
          <Row
            key={index}
            align="middle"
            style={{ minHeight: 300 }}
            gutter={[{ md: 20, lg: 28 }, 24]}
          >
            <Col xs={24} sm={24} md={12} lg={12} style={{ minHeight: 210 }}>
              <div
                draggable="false"
                style={{
                  width: "100%",
                  // maxHeight: 300,
                  paddingTop: "56.25%",
                  backgroundImage: `url(${app.client.config.MEDIA_URL}/${banner})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 12
                }}
                title={eachPost[`title_${i18n.language}`] || title}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Row>
                <p style={{ ...FONT.captionRegular, color: COLOR.text.captionText }}>
                  {dayjs.unix(post_date).format("YYYY-MM-DD")}
                </p>
              </Row>
              <Row>
                <p style={{ ...FONT.titleTextRegular, color: COLOR.text.blackText }}>
                  {eachPost[`title_${i18n.language}`] || title}
                </p>
              </Row>
              <Row style={{ marginTop: 32 }}>
                <Paragraph
                  style={{ ...FONT.bodyText2Regular, color: COLOR.text.captionText }}
                  ellipsis={
                    {
                      rows: 6,
                      expandable: true,
                      symbol: t("more"),
                      onExpand: () => history.push(`/${app.company.company_key}/news/${blog_post_id}`)
                    }
                  }
                >
                  {htmlToReactParser.parse(eachPost[`description_${i18n.language}`] || description)}
                </Paragraph>
              </Row>
              <Row style={{ marginTop: 32 }}>
                <Col xs={24} sm={24} md={11} lg={8}>
                  <Link
                    to={{
                      pathname: `/${app.company.company_key}/news/${blog_post_id}`
                    }}
                  >
                    <Button style={{
                      backgroundColor: COLOR.primary.primary500, width: "100%", borderRadius: 8, height: 48, color: COLOR.text.whiteText, fontSize: 16
                    }}
                    >
                      {t("learn_more")}
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }
    );
  };

  return (
    <div
      style={{
        paddingBottom: isMobile ? 0 : 45,
        ...styles.selectedPostSection,
      }}
    >
      <Row justify="center">
        <Col span={24} style={{ margin: "auto" }}>
          <Row align="middle" justify="center" style={{ margin: 0 }}>
            <Col span={24}>
              <Carousel
                ref={carouselNewItem}
                autoPlay
                autoPlaySpeed={5000}
                swipeable
                draggable={false}
                renderButtonGroupOutside={false}
                responsive={responsive}
                ssr
                infinite
                keyBoardControl
                customTransition="transform 500ms ease-in-out"
              >
                {getSelectedPostsCard()}
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  banner: {
    fontWeight: "600",
    color: "#fff",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bannerText: {
    fontSize: "calc(1rem + 1vw)",
    position: "absolute",
  },
  gridImg: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxHeight: "400px",
  },
  headerTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
    color: "white",
  },
  titleTxt: {
    marginTop: 25,
    color: "black",
    fontSize: "26px",
    fontWeight: "600",
    // textShadow: "1px 1px #AAAAAA",
  },
  selectedPostSection: {
    // maxWidth: '800px',
    width: "100%",
    paddingTop: 24,
  },
  tabButton: {
    border: "1px solid #3461A3",
    borderRadius: 50,
    color: "#3461A3",
  },
  tabButtonActive: {
    border: "1px solid #3461A3",
    borderRadius: 50,
    background: "#3461A3",
    color: "#F4F4F4",
  },
  subCatPostSection: {},
};

export default NewsCarousel;

import React, { useState } from "react";
import {
  Button, Row, Col, Typography, Grid
} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useWindowWidth } from "@react-hook/window-size";
import { COLOR, FONT } from "../../Theme";
import { useTranslation } from "react-i18next";

const HtmlToReactParser = require('html-to-react').Parser;

const htmlToReactParser = new HtmlToReactParser();
const { Paragraph } = Typography;

const CollaspeContainer = ({
  content = '',
  displayRow = 5,
  showButtonJustify = "center"
}) => {
  const [ellipsis, setEllipsis] = useState(true);
  const { t } = useTranslation();

  return (
    // TODO:
    // Find replacement for the ellipsis issue on mobile
    <div>
      <Paragraph
        // ellipsis={
        //   ellipsis ? { rows: displayRow, expandable: false }
        //     : false
        // }
        style={styles.textStyle}
      >
        <div className="parser-container">
          {htmlToReactParser.parse(content)}
        </div>
      </Paragraph>
      {/* <Row justify={showButtonJustify} align="middle">
        <Button type="link" onClick={() => setEllipsis(!ellipsis)}>
          <span style={{ ...FONT.buttonRegular, color: COLOR.primary.primary500, margin: '0 5px' }}>{ellipsis ? t("show_more") : t("show_less")}</span>
          {ellipsis ? <DownOutlined style={{ ...styles.showArrow }} /> : <UpOutlined style={{ ...styles.showArrow }} />}
        </Button>
      </Row> */}
    </div>
  );
};

const styles = {
  textStyle: {
    ...FONT.bodyText1Regular,
  },
  priceBar: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLOR.brandPrimary,
    padding: 10
  },
  showArrow: {
    color: COLOR.primary.primary500,
    fontSize: 10
  }
}

export default CollaspeContainer;

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  notification
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formItemLayout } from "../ModalLayout";
import lockIcon from "../../assets/lock.svg";
import { COLOR, FONT } from "../../Theme";
import Button from "../Button";

const EditPasswordMobileView = ({
  form,
  onFinish,
  isConfirmPasswordCorrect,
  buttonLoading
}) => {
  const { company } = useSelector((state) => state.app);

  const {
    color_primary,
  } = company;
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      name="editPasswordForm"
      layout="vertical"
      onFinish={onFinish}
      style={{ padding: "16px 0" }}
    >
      <Form.Item
        name="original_password"
        rules={[{
          required: true,
          message: t("require_original_password"),
        }]}
      >
        <Input.Password
          placeholder={t("enter_original_password")}
          prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
      <Form.Item
        name="new_password"
        rules={[{
          required: true,
          message: t("require_password"),
        }, {
          pattern: /\S{8,}/,
          message: t("password_validation")
        }]}
      >
        <Input.Password
          placeholder={t("enter_new_password")}
          prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={["new_password"]}
        extra={isConfirmPasswordCorrect ? "" : (
          <div style={{ color: "#ff4d4f" }}>
            {t("password_not_match")}
          </div>
        )}
        rules={[
          {
            required: true,
            message: t("require_confirm_password"),
          }
        ]}
      >
        <Input.Password
          placeholder={t("confirm_password")}
          prefix={<img style={{ margin: "0px 5px", }} src={lockIcon} alt="prefix_icon" />}
          style={styles.inputContainer}
          allowClear
          bordered={false}
        />
      </Form.Item>
      <Form.Item>
        <Button
          style={{
            width: "100%",
            borderRadius: "8px"
          }}
          theme_color={color_primary}
          size="large"
          htmlType="submit"
          loading={buttonLoading}
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

const styles = {
  inputContainer: {
    borderRadius: "8px",
    backgroundColor: "#FFF",
    height: "46px",
  }
};

export default EditPasswordMobileView;

import React, { Component } from "react";
import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";
import AppContainer from "./AppContainer";
import "react-multi-carousel/lib/styles.css";
import "react-spring-bottom-sheet/dist/style.css";
import "./style.css";
import MetaPixel from "./utils/MetaPixel";

const store = configureStore();

export default function App(props) {
  return (
    <Provider store={store}>
      {/* <MetaPixel /> */}
      <AppContainer />
    </Provider>
  );
}

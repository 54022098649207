import React from "react";
import {
  Row, Col, Affix, Grid, Layout, Typography
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import debugLib from "debug";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {useWindowWidth} from "@react-hook/window-size";
import TNContent from "../TNCore/TNContent";
import { COLOR, FONT, BREAKPOINT } from "../Theme";
import CompanyLogo from "./CompanyLogo";

const { Footer } = Layout;
const { Title, Text, Link } = Typography;

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const AppFooter = () => {
  // const user = useSelector((state) => state.app.user);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { meta, config } = useSelector((state) => state.app.client);
  const { VERSION_NO, STATIC_SERVER_URL, MEDIA_URL } = config;
  const { companySiteMeta, company } = useSelector((state) => state.app);
  // const [t, i18n] = useTranslation('header');
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.md;
  const isTablet = windowWidth < BREAKPOINT.lg;

  const checkShouldShowSocialMedia = () => {
    let shouldShowSocialMedia = false;
    _.forEach(companySiteMeta, (val, key) => {
      if (_.startsWith(key, "media") && val) {
        shouldShowSocialMedia = true;
        return false;
      }
    });
    return shouldShowSocialMedia;
  };

  const shouldShowSocialMedia = checkShouldShowSocialMedia();

  const renderSocialMedia = () => {
    let socialMedia = [];
    let keys = (_.keys(companySiteMeta)).sort();

    _.forEach((keys), (meta_key) => {
      let val = companySiteMeta[meta_key];
      if (_.startsWith(meta_key, "media") && val) {
        let key = _.split(meta_key, "_")[1];
        let obj = {};
        obj[key] = val;
        socialMedia.push(obj);
      }
    });

    return (
      <>
        {_.map(socialMedia, (item, index) => {
          let key = _.keys(item)[0];
          return (
            <Link
              href={item[key]}
              target="_blank"
              key={key}
            >
              <img
                src={STATIC_SERVER_URL + "/" + key + ".png"}
                alt={key + "icon"}
                style={{
                  width: 24,
                  marginLeft: index === 0 ? 0 : 8,
                  cursor: "pointer"
                }}
              />
            </Link>
          );
        })}
      </>
    );
  };

  return (
    // <TNContent
    //   fullWidth
    //   color="#EEE"
    // >
    //   <Row
    //     justify="center"
    //     style={{paddingBottom: 20, paddingTop: 20}}
    //   >
    //     <Col>
    //       Smart Booking | Product of <a href="https://technine.io">technine.io</a>
    //     </Col>
    //   </Row>
    // </TNContent>
    <TNContent fullWidth color="#9E8677">
      <Footer style={styles.lowerFooter}>
        <Row style={{ marginBottom: 50 }} gutter={[25, 15]}>
          {!_.isEmpty(companySiteMeta[`about_us_${i18n.language}`]) && (
            <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: "start" }}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <span style={{ ...FONT.captionBold, color: COLOR.text.blackText }}>
                    {t("about")}
                  </span>
                </Col>
                <Col span={24}>
                  {htmlToReactParser.parse(companySiteMeta[`about_us_${i18n.language}`])}
                </Col>
              </Row>
            </Col>
          )}
          {!_.isEmpty(companySiteMeta[`company_address_${i18n.language}`]) && (
            <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: "start" }}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <span style={{ ...FONT.captionBold, color: COLOR.text.blackText }}>
                    {t("address")}
                  </span>
                </Col>
                <Col span={24}>
                  {htmlToReactParser.parse(companySiteMeta[`company_address_${i18n.language}`])}
                </Col>
              </Row>
            </Col>
          )}
          {!_.isEmpty(companySiteMeta[`company_contact_${i18n.language}`]) && (
            <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: "start" }}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <span style={{ ...FONT.captionBold, color: COLOR.text.blackText }}>
                    {t("contact")}
                  </span>
                </Col>
                <Col span={24}>
                  {htmlToReactParser.parse(companySiteMeta[`company_contact_${i18n.language}`])}
                </Col>
              </Row>
            </Col>
          )}
          {shouldShowSocialMedia && (
            <Col xs={24} sm={24} md={24} lg={6} style={{ textAlign: "start" }}>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <span style={{ ...FONT.captionBold, color: COLOR.text.blackText }}>
                    {t("social_media")}
                  </span>
                </Col>
                <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                  {renderSocialMedia()}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row align="middle" justify={!isMobile ? "space-between" : "center"}>
          <Col
            xs={14}
            sm={14}
            md={8}
            style={{ textAlign: !isMobile ? "start" : "" }}
          >
            <a
              href="https://ninesmart.io/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                width="360vw"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  maxWidth: "140px"
                }}
                src={!_.isEmpty(meta)
                  && meta.company_logo
                  ? `${MEDIA_URL}/${meta.company_logo}`
                  : null}
                alt="company_logo"
              />
            </a>
            <Text
              style={{
                ...FONT.captionLimitedRegular,
                display: !isMobile ? "" : "block",
                textAlign: !isTablet ? "" : "center",
                color: COLOR.text.captionText,
                verticalAlign: "sub",
                marginLeft: !isMobile ? 8 : 0,
                marginTop: !isMobile ? 0 : 4
              }}
            >
              ver
              {" "}
              {htmlToReactParser.parse(config.VERSION_NO)}
            </Text>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            style={{ textAlign: !isMobile || !isTablet ? "center" : "" }}
          >
            {!_.isEmpty(meta)
              && meta.copy_right && (
              <Text
                style={{
                  ...FONT.captionLimitedRegular,
                  color: COLOR.text.captionText,
                }}
              >
                {htmlToReactParser.parse(meta.copy_right)}
              </Text>
            )}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            style={{ textAlign: !isMobile ? "end" : "" }}
          >
            <Text
              style={{
                ...FONT.captionLimitedRegular,
                color: COLOR.text.captionText,
              }}
            >
              <a
                href={`${config.STATIC_SERVER_URL}/${company.company_key}/terms/conditions`}
                style={{
                  ...FONT.captionLimitedRegular,
                  color: COLOR.text.captionText,
                  textDecoration: "underline",
                }}
              >
                {t("terms_and_conditions_only")}
              </a>
              {" "}
              {t("and")}
              {" "}
              <a
                href={`${config.STATIC_SERVER_URL}/${company.company_key}/privacy/policy`}
                style={{
                  ...FONT.captionLimitedRegular,
                  color: COLOR.text.captionText,
                  textDecoration: "underline",
                }}
              >
                {t("privacy_policy")}
              </a>
            </Text>
          </Col>
        </Row>
      </Footer>
    </TNContent>
  );
};

const styles = {
  text: {
    // fontWeight: 'bold',
    fontSize: 14,
    color: "#000",
  },
  middleFooter: {
    textAlign: "center",
    margin: 0,
    padding: "50px 0",
    // paddingBottom: 0,
    backgroundColor: "#FFFFFF",
  },
  lowerFooter: {
    textAlign: "center",
    margin: 0,
    paddingLeft: "4%",
    paddingRight: "4%",
    // padding: '10px 5% 30px 5%',
    // marginLeft: '5%',
    // marginRight: '5%',
    backgroundColor: "#FBF9F9",
  },
  icon: {
    color: "#FFFFFF",
    marginRight: 50,
    fontSize: 24,
    transform: "translateY(20%)",
  },
};

export default AppFooter;

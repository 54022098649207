import { getStore } from "../store/configureStore";
import * as Service from "../../core/Service";

// const getCompanyData = async () => {
//   let { selectedItemCategory, selectedItem, selectedDate } = getStore().getState().app
//   let response = [];

//   if (selectedItemCategory !== 0 && selectedItem !== 0 && selectedDate !== 0) {
//     response = await Service.call('post', `/api/app/booking/bydate/${selectedItem}/${selectedDate}`)
//     console.log('response>>>', response)
//     if (response.status > 0) {
//       return response.result
//     }
//   }
//   return response
// }

export const getCompanyData = async (company_key) => {
  let result = await Service.call("get", `/api/app/company`, { company_key });

  // console.log('companyResp >>>', result)

  if (!result || result.status !== 1) {
    return;
  }
  const {
    companyInfo, holidayList, itemCategoryList, itemList
  } = result;
  const { color_primary, color_secondary } = companyInfo;

  companyInfo.color_primary = `#${color_primary}`;
  companyInfo.color_secondary = `#${color_secondary}`;

  return {
    companyInfo,
    itemCategory: itemCategoryList,
    item: itemList,
    holidayList
  };
};

export const getCompanyInfo = async (company_key) => {
  let result = await Service.call("get", `/api/company`, { company_key });

  // console.log('companyResp >>>', result)

  if (!result || result.status !== 1) {
    return;
  }
  const {
    companyInfo,
    siteMetaMap
  } = result;

  const { color_primary, color_secondary } = companyInfo;

  companyInfo.color_primary = `#${color_primary}`;
  companyInfo.color_secondary = `#${color_secondary}`;

  return { companyInfo, siteMetaMap };
};

import React, { useEffect, useState } from "react";
import { Row, Col, Grid, Button, Spin, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useWindowWidth } from "@react-hook/window-size";
import * as Service from "../core/Service";
import AppLayout from "../components/AppLayout";
import { FONT, COLOR, BREAKPOINT } from "../Theme";
import LoadingIcon from "../components/LoadingIcon";
import { setLoading } from "../redux/actions/common";

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const NewsPostInfoPage = () => {
  const [dataList, setDataList] = useState({
    blogPostRc: {},
    blogPostHeader: {},
    newsPostCategoryRc: {},
  });
  const {
    company,
    config,
    companySiteMeta
  } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { company_key, blog_post_id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (company.company_id) {
      getBlogPostRecord();
    }
  }, [company]);

  const getBlogPostRecord = async () => {
    dispatch(setLoading(true));
    try {
      const resp = await Service.call(
        "get",
        `/api/company/news/id/${blog_post_id}`
      );
      if (resp.status === 1) {
        const { blogPostObj, newsCategoryObj } = resp.data;
        if (_.isEmpty(blogPostObj)) return history.replace(`/${company.company_key}`);
        setDataList({
          blogPostRc: {
            ...blogPostObj,
            category_name: newsCategoryObj.category_name,
          },
          newsPostCategoryRc: newsCategoryObj,
          blogPostHeader: {
            banner: companySiteMeta.news_banner || null,
            title: companySiteMeta.news_title || null
          },
        });
        return;
      }
      return history.replace(`/${company.company_key}`);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <AppLayout>
      {!_.isEmpty(dataList.blogPostHeader) && (
        <div style={{ position: "relative" }}>
          <Row
            span={24}
            style={{
              backgroundImage: `url(${`${config.MEDIA_URL}/${dataList.blogPostHeader.banner}`})`,
              backgroundColor: "rgba(0,0,0,0.5)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "darken",
              backgroundPosition: "center",
              opacity: 2,
              width: "100%",
              height: "300px"
            }}
            align="middle"
            justify="center"
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
              style={{ textAlign: "center" }}
            >
              <span
                style={{
                  color: COLOR.warning.warning300,
                  ...FONT.titleTextBold,
                }}
              >
                {t("news")}
              </span>
              <br />
              <span
                style={{
                  color: COLOR.text.whiteText,
                  ...FONT.h3,
                }}
              >
                {dataList.blogPostHeader.title}
              </span>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              bottom: -1,
              width: "100%",
              backgroundColor: "white",
              height: 30,
              borderTopRightRadius: 45,
              borderTopLeftRadius: 45,
            }}
          />
        </div>
      )}
      <div style={{ padding: !isMobile ? "2% 25%" : "2% 2%" }}>
        <Row
          style={{ marginBottom: "20px", ...FONT.captionRegular }}
          justify="center"
        >
          <Col style={{ color: COLOR.text.captionText }}>
            {dayjs.unix(dataList.blogPostRc?.post_date).format("YYYY-MM-DD")}
          </Col>
          <Col style={{ marginLeft: 32, color: COLOR.text.primaryText }}>
            {dataList.blogPostRc.category_name}
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: "20px",
            ...FONT.h3,
            color: COLOR.text.blackText,
          }}
          justify="center"
        >
          <Col style={{ textAlign: "center" }}>{dataList.blogPostRc.title}</Col>
        </Row>
        <Divider style={{ margin: "60px 0", color: COLOR.function.divider }} />
        <Row justify="center">
          <img
            src={`${config.MEDIA_URL}/${dataList.blogPostRc.banner}`}
            style={{
              width: "80%",
              backgroundSize: "contain",
            }}
          />
        </Row>
        <Row style={{ marginTop: 60 }}>
          {htmlToReactParser.parse(dataList.blogPostRc.description)}
        </Row>
        <Row justify="center" style={{ marginTop: 32 }}>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Link
              to={{
                pathname: `/${company_key}/news`,
              }}
            >
              <Button
                style={{
                  backgroundColor: COLOR.primary.primary500,
                  ...FONT.buttonRegular,
                  color: COLOR.text.whiteText,
                  width: "100%",
                  height: 48,
                  borderRadius: 8,
                }}
              >
                {t("back")}
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};

export default NewsPostInfoPage;

import React, { useState, useEffect } from "react";
import { Button, Row, Col, InputNumber, Grid } from "antd";
import _ from "lodash";
import { useWindowWidth } from "@react-hook/window-size";
import { useSelector } from "react-redux";
import { BREAKPOINT, COLOR, FONT } from "../../Theme";
import { Decimal } from "decimal.js";

const ProductButton = ({ setTargetItems, setSelectedProductList, selected, item }) => {
  const [inputNumber, setInputNumber] = useState(0);
  const { STATIC_SERVER_URL, MEDIA_URL } = useSelector(
    (state) => state.app.client.config
  );
  const {
    product_item_id,
    image,
    price,
    product_name,
    ignore_stock,
    stock_number,
  } = item;
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.md;
  const textStyle = isMobile ? FONT.text : FONT.textMedium;

  useEffect(() => {
    if (selected && inputNumber >= 0) {
      const newItems = { ...item, quantity: inputNumber };
      setTargetItems((prev) => {
        if (_.find(prev, { product_item_id })) {
          const newArr = _.reject(prev, { product_item_id });
          setSelectedProductList([...newArr, newItems])
          return [...newArr, newItems];
        }
        return prev;
      });
    }
  }, [inputNumber]);

  const onTarget = () => {
    setTargetItems((prev) => {
      // if (_.find(prev, { product_item_id })) {
      //   return [...prev, item]
      // }
      return [...prev, item];
    });
  };

  return (
    <Row
      justify="space-between"
      align="middle"
    >
      <Col span={10} style={{ color: COLOR.text.blackText }}>
        {product_name}
      </Col>
      <Col className="product-button-quantity-input">
        <span
          style={{ marginRight: 14, color: COLOR.text.captionText }}
        >{`+ $${new Decimal(price).times(inputNumber)}`}</span>
        <span>
          <QuantityInput
            inputNumber={inputNumber}
            setInputNumber={setInputNumber}
            plusRuleSet={ignore_stock || inputNumber < stock_number}
            max={!ignore_stock ? stock_number : undefined}
            onTarget={onTarget}
            productButton
          />
        </span>
      </Col>
    </Row>
  )
};

export const QuantityInput = (props) => {
  const {
    inputNumber,
    setInputNumber,
    plusRuleSet,
    removeRuleSet,
    min,
    max,
    onTarget,
    productButton,
  } = props;

  const { STATIC_SERVER_URL } = useSelector(
    (state) => state.app.client.config
  );
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.md;

  const inputNumberFunction = (type) => {
    let calculate = 0;

    if (type === "plus" && plusRuleSet) {
      calculate = inputNumber + 1;
      setInputNumber(calculate);
      return;
    }
    if (type === "remove" && (removeRuleSet || inputNumber !== 0)) {
      calculate = inputNumber - 1;
      setInputNumber(calculate);
    }
  };

  return (
    <Row
      justify="space-around"
      style={{
        margin: "10px 0",
        border: isMobile ? `1px solid ${COLOR.function.border}` : "none",
        backgroundColor: isMobile ? COLOR.function.background : COLOR.function.white,
        borderRadius: 10,
      }}
    >
      <Col
        onClick={(e) => {
          e.stopPropagation();
          inputNumberFunction("remove");
          if (productButton) {
            onTarget();
          }
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <img src={`${STATIC_SERVER_URL}/remove.svg`} alt="" />
      </Col>
      <Col span={15} style={{ maxWidth: isMobile && 60 }}>
        <InputNumber
          onClick={(e) => e.stopPropagation()}
          bordered={false}
          onChange={(value) => {
            setInputNumber(_.isInteger(value) ? value : 0);
            onTarget();
          }}
          min={min || 0}
          max={max}
          value={inputNumber}
          style={{
            textAlign: "center",
            width: "100%",
            backgroundColor: "transparent",
            color: isMobile ? COLOR.text.blackText : COLOR.text.captionText,
          }}
        />
      </Col>
      <Col
        onClick={(e) => {
          e.stopPropagation();
          inputNumberFunction("plus");
          if (productButton) {
            onTarget();
          }
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <img src={`${STATIC_SERVER_URL}/plus.svg`} alt="" />
      </Col>
    </Row>
  );
};

export default ProductButton;
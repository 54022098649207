import React from "react";
import { Space } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { COLOR, FONT } from "../../Theme";
import { ListItem } from "../../pages/OrderReceiptPage";

const default_token_img = require("../../assets/default_token_img.png");

const TokenOrderDetail = ({ data }) => {
  const { t } = useTranslation();
  const { companySiteMeta, client } = useSelector((state) => state.app);
  const { MEDIA_URL } = client.config;

  let imagePath = companySiteMeta?.token_img
    ? `${MEDIA_URL}/media/${companySiteMeta.token_img}`
    : default_token_img;

  const { token_amount } = data;

  return (
    <ListItem
      title={(
        <Space>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imagePath}
              alt="token pic"
              style={{
                maxWidth: 30,
                maxHeight: 30,
              }}
            />
          </div>

          <div style={{ ...FONT.bodyText1Bold, color: COLOR.text.primaryText }}>
            {companySiteMeta?.token_name}
          </div>
        </Space>
      )}
      value={(
        <Space>
          {token_amount}
          {t("token")}
        </Space>
      )}
    />
  );
};

export default TokenOrderDetail;

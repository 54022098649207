import _ from "lodash";
import React, { useRef, useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import StartBookingCategoryItem from "./Landing/StartBookingCategoryItem";
import * as Service from "../core/Service";
import { useParams } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const OtherItemCarousel = () => {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const { company_key } = useParams();

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    try {
      setLoading(true);
      const resp = await Service.call(
        "get",
        `/api/company/landing/${company_key}`
      );
      if (resp.status === 1) {
        const { bookingCategoryArr, bookingCategoryImageArr } = resp.data;
        let keyByBookingCategoryImageObj = _.keyBy(
          bookingCategoryImageArr,
          "booking_category_id"
        );
        let bookingCategoryList = _.map(bookingCategoryArr, (eachItem) => {
          let imageRc =
            keyByBookingCategoryImageObj[eachItem.booking_category_id] || {};
          return {
            ...eachItem,
            filepath: imageRc.filepath || "",
          };
        });
        setDataList(bookingCategoryList);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const carouselItem = useRef();
  return (
    <Carousel
      swipeable
      ref={carouselItem}
      responsive={responsive}
      arrows={false}
      infinite
      keyBoardControl
      showDots={false}
      pauseOnHover
      itemClass="carousel-item"
    >
      {_.map(dataList, (item) => {
        return (
          <StartBookingCategoryItem
            key={item?.booking_category_id}
            bookingCategoryRc={item}
          />
        );
      })}
    </Carousel>
  );
};

export default OtherItemCarousel;

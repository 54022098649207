import React, { useEffect, useState } from "react";
import { Row, Col, Grid, Spin, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { keyBy } from "lodash";
import { useWindowWidth } from "@react-hook/window-size";
import * as Service from "../core/Service";
import AppLayout from "../components/AppLayout";
import { FONT, COLOR, BREAKPOINT } from "../Theme";
import LoadingIcon from "../components/LoadingIcon";
import { setLoading } from "../redux/actions/common";

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const PrivacyAndPolicyPage = () => {
  const [content, setContent] = useState(null);
  const { company, companySiteMeta } = useSelector((state) => state.app);
  const app = useSelector((state) => state.app);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  useEffect(() => {
    window.scrollTo({ top: 0 });
    // if (company.company_id) {
    //   getBlogPostRecord();
    // }
    setContent(companySiteMeta[`tc_privacy_content_${i18n.language}`]);
    dispatch(setLoading(false));
  }, [company, i18n.language]);

  return (
    <AppLayout>
      <div style={{ padding: "2% 15%" }}>
        <Row>
          <Col span={24} style={{ ...FONT.h3, color: COLOR.text.primaryText }}>
            {t("terms_and_conditions")}
          </Col>
          <Divider />
          {htmlToReactParser.parse(content)}
        </Row>
      </div>
    </AppLayout>
  );
};

export default PrivacyAndPolicyPage;

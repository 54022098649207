import React, { useEffect, useState } from "react";
import {
  Grid,
  Divider,
  notification
} from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";
import AppLayout from "../components/AppLayout";
import EditProfileMobileView from "../components/profile/EditProfileMobileView";
import EditProfileDesktopView from "../components/profile/EditProfileDesktopView";
import { BREAKPOINT, COLOR, FONT } from "../Theme";
import * as Service from "../core/Service";
import { useHistory, useParams } from "react-router-dom";
import { setCompanyInfo, setLoading, setUser } from "../redux/actions/common";

const title = "edit_personal_info";

const EditProfileContainer = () => {
  const user = useSelector((state) => state.app.user);
  const {
    mobile,
    nickname,
    email: emailAddress,

  } = user;
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFinish = async (formData) => {
    try {
      const dialCode = mobile?.split("-")?.[0];
      const phoneNumber = mobile?.split("-")?.[1];
      const newFormData = {};
      // const newFormData = {
      //   nickname: formData.nickname,
      //   mobile: !_.isEmpty(dialCode) && !_.isEmpty(phoneNumber)
      //     ? `${formData.mobilePrefix}-${formData.mobile}` : mobile,
      //   email: !_.isEmpty(formData.email) ? formData.email : emailAddress
      // };
      if (!_.isEmpty(formData.nickname) && nickname !== formData.nickname) {
        newFormData.nickname = formData.nickname;
      }
      if (!_.isEmpty(formData.email) && emailAddress !== formData.email) {
        newFormData.email = formData.email;
      }
      if (!_.isEmpty(formData.mobile)
        && (dialCode !== formData.mobilePrefix || phoneNumber !== formData.mobile)) {
        newFormData.mobile = `${formData.mobilePrefix}-${formData.mobile}`;
      }

      if (_.isEmpty(newFormData)) {
        return notification.info({
          description: t(`nothing_changed_in_personal_info`),
        });
      }
      let resp = await Service.call("patch", "/api/user/edit", newFormData);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(`${resp.errorCode}`),
        });
      }
      notification.success({
        message: t("success"),
      });
      dispatch(setUser({ ...user, ...resp.data }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppLayout className="edit-profile-container">
      <div style={{ padding: !isMobile && "2% 15%" }}>
        <span
          style={{
            ...FONT.h4,
            color: COLOR.text.primaryText,
          }}
        >
          {t(title)}
        </span>
        <Divider />
        {isMobile ? (
          <EditProfileMobileView
            handleSubmit={onFinish}
          />
        ) : (
          <EditProfileDesktopView
            handleSubmit={onFinish}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default EditProfileContainer;

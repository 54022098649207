import Immutable from "seamless-immutable";
import moment from "moment";
import * as types from "../actions/types";

const initialState = Immutable({
  auth: null,
  config: {
    STATIC_SERVER_URL: null,
  },
  loading: true,
  // admin: {
  //   admin_id: 0,
  //   username: null,
  //   first_name: '',
  //   last_name: '',
  //   nickname: '',
  //   avatar_file: null,
  //   email: '',
  //   mobile: '',
  // },
  holidayList: [],
  admin: {},
  company_admin: {},
  origin: "",
  logoutWay: "",
  client: {
    config: {
      API_URL: undefined
    },
    meta: {},
    header: {},
    footer: {},
  },
  data: {},
  user: {
    isUser: false,
    company_user_id: 0,
    checked: false,
    token_own: 0,
    token_expiry_time: 0,
    tokenDetailList: []
  },
  shoppingCartAmount: 0,
  application: {
    application_key: null
  },
  adminWebcast: {
    webcast_event_id: 0,
  },
  drawerVisible: false,
  rentForm: {},
  smSites: [],
  company: {},
  itemCategory: [],
  item: [],
  booking: [],
  selectedItemCategory: 0,
  selectedItem: 0,
  selectedDate: 0,
  selectedCapacity: 1,
  selectedBooking: {},
  ruleset: {},
  userSession: {},
  errorRedirect: false,
  shoppingCartStatus: 0,
  appModal: {
    appModalVisible: false,
    type: null
  },
  companySiteMeta: {},
  userQRCodeVisible: false
});

const orderState = Immutable({
  orderInfo: {
    order_id: 0
  },
  bookingPurpose: "",
  orderItemList: []
});

const appReducer = {
  app: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_CONFIG: {
        return { ...state, config: action.data };
      }
      case types.SET_AUTH:
        state = { ...state, auth: action.data };
        return state;
      case types.SET_LOADING:
        state = { ...state, loading: action.data };
        return state;
      case types.SET_ADMIN:
        if (!action.data || Object.keys(action.data).length === 0) {
          state = {
            ...state,
            admin: initialState.admin,
          };
        } else {
          state = { ...state, admin: action.data };
        }
        return state;
      case types.SET_COMPANY_ADMIN:
        if (!action.data || Object.keys(action.data).length === 0) {
          state = {
            ...state,
            company_admin: initialState.company_admin,
          };
        } else {
          state = { ...state, company_admin: action.data };
        }
        return state;
      case types.SET_ORIGIN:
        state = { ...state, origin: action.data };
        return state;
      case types.SET_LOGOUTWAY:
        state = { ...state, logoutWay: action.data };
        return state;

      case types.SET_CLIENT_DATA:
        state = {
          ...state,
          client: action.data,
        };
        return state;
      case types.APP_DATA:
        state = {
          ...state,
          langauge: action.data
        };
        return state;
      case types.SET_USER:
        state = {
          ...state,
          user: action.data
        };
        return state;
      case types.SET_APPLICATION_KEY:
        state = {
          ...state,
          application: Object.assign(state.application, {
            application_key: action.data
          })
        };
        return state;
      case types.SET_ADMIN_WEBCAST_EVENT_ID:
        state = {
          ...state,
          adminWebcast: Object.assign(state.adminWebcast, {
            webcast_event_id: action.data
          })
        };
        return state;
      case types.SET_DRAWER:
        state = {
          ...state,
          drawerVisible: action.data
        };
        return state;
      case types.SET_RENT_FORM:
        state = {
          ...state,
          rentForm: action.data
        };
        return state;
      case types.SET_SM_SITES: {
        state = {
          ...state,
          smSites: action.data,
        };
        return state;
      }
      case types.SET_COMPANY: {
        state = {
          ...state,
          company: action.data,
        };
        return state;
      }
      case types.SET_ITEM_CATEGORY: {
        state = {
          ...state,
          itemCategory: action.data,
        };
        return state;
      }
      case types.SET_SELECTED_ITEM_CATEGORY: {
        state = {
          ...state,
          selectedItemCategory: action.data,
        };
        return state;
      }
      case types.SET_ITEM: {
        state = {
          ...state,
          item: action.data,
        };
        return state;
      }
      case types.SET_SELECTED_ITEM: {
        state = {
          ...state,
          selectedItem: action.data,
        };
        return state;
      }
      case types.SET_SELECTED_DATE: {
        state = {
          ...state,
          selectedDate: action.data,
        };
        return state;
      }
      case types.SET_SELECTED_BOOKING: {
        state = {
          ...state,
          selectedBooking: action.data,
        };
        return state;
      }
      case types.SET_SELECTED_CAPACITY: {
        state = {
          ...state,
          selectedCapacity: action.data,
        };
        return state;
      }
      case types.SET_BOOKING: {
        state = {
          ...state,
          booking: action.data,
        };
        return state;
      }
      case types.SET_HOLIDAY: {
        state = {
          ...state,
          holidayList: action.data,
        };
        return state;
      }
      case types.SET_RULE_SET: {
        state = {
          ...state,
          ruleset: action.data,
        };
        return state;
      }
      case types.SET_USER_SESSION: {
        state = {
          ...state,
          userSession: action.data,
        };
        return state;
      }
      case types.SET_ERROR_REDIRECT:
        state = {
          ...state,
          errorRedirect: action.data
        };
        return state;
      case types.SET_SHOPPING_CART_AMOUNT:
        state = {
          ...state,
          shoppingCartAmount: action.data
        };
        return state;
      case types.SET_SHOPPING_CART_STATUS:
        state = {
          ...state,
          shoppingCartStatus: action.data
        };
        return state;
      case types.SET_APP_MODAL:
        state = {
          ...state,
          appModal: action.data
        };
        return state;
      case types.SET_COMPANY_SITE_META:
        state = {
          ...state,
          companySiteMeta: action.data
        };
        return state;
      case types.SET_QR_CODE_VISIBLE:
        state = {
          ...state,
          userQRCodeVisible: action.data
        };
        return state;
      default:
        return state;
    }
  },
  order: (state = orderState, action) => {
    switch (action.type) {
      case types.SET_ORDER_INFO: {
        state = { ...state, orderInfo: action.data };
        return state;
      }
      case types.SET_BOOKING_PURPOSE: {
        state = { ...state, bookingPurpose: action.data };
        return state;
      }
      case types.SET_ORDER_ITEM_LIST: {
        state = { ...state, orderItemList: action.data };
        return state;
      }
      case types.RESET_ORDER: {
        state = orderState;
        return state;
      }
      default:
        return state;
    }
  },
};

export default appReducer;

import React, { Component, useState, useEffect } from "react";
import { Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  setConfig,
  setClientData,
  setLoading,
} from "./redux/actions/common";
import * as Service from "./core/Service";
import AppRoute from "./AppRoute";
import LoadingIcon from "./components/LoadingIcon";
import { getStore } from "./redux/store/configureStore";
// import * as Service from './core/Service';

message.config({
  top: "75%",
  maxCount: 1,
});

const AppContainer = () => {
  const { loading, client } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getConfig();
    await getSiteMetaObj();
  };

  const getConfig = async () => {
    let configResp = await Service.call("get", "/api/config");

    if (configResp.status !== 1) {
      console.error(configResp.errorMessage);
      return;
    }
    const { config } = configResp;

    dispatch(setClientData({
      config,
      header: {
      },
      footer: {
      },
    }));
    dispatch(setConfig(config));
  };

  const getSiteMetaObj = async () => {
    let siteMetaObj = {};
    const resp = await Service.call("get", "/api/site_meta");
    if (resp.status === 1) {
      let metaData = resp.data;
      let keyBySiteMeta = _.keyBy(metaData, "meta_key");
      _.each(keyBySiteMeta, (value, key) => {
        siteMetaObj[key] = value.meta_value;
      });
    }
    dispatch(setClientData({
      ...getStore().getState().app.client,
      meta: siteMetaObj,
    }));
  };

  return (
    <div>
      {_.isUndefined(client?.config?.STATIC_SERVER_URL) ? (
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Spin indicator={<LoadingIcon />} />
        </div>
      ) : <AppRoute />}
    </div>
  );
};

export default AppContainer;

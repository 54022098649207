import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { find, map, isEmpty } from "lodash";
import { useWindowWidth } from "@react-hook/window-size";
import { COLOR, FONT, BREAKPOINT } from "../Theme";
import defaultBanner from "../assets/membership_default_banner.svg";
import MembershipCard from "../components/membership/MembershipCard";

const MembershipListMobilePage = (props) => {
  const [bannerPath, setBannerPath] = useState(defaultBanner);
  const {
    config,
    companySiteMeta
  } = useSelector((state) => state.app);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { MEDIA_URL } = config;

  const {
    membershipList,
    handleChangeLanguage
  } = props;

  useEffect(() => {
    if (!isEmpty(companySiteMeta.membership_banner)) {
      setBannerPath(`${MEDIA_URL}/${companySiteMeta.membership_banner}`);
    }
  }, [companySiteMeta]);

  return (
    <div style={{
      ...styles.outerContainer
    }}
    >
      <img
        src={bannerPath}
        alt="token banner"
        style={{
          ...styles.banner
        }}
      />
      {/** Page Description */}
      <div>
        <div dangerouslySetInnerHTML={{ __html: handleChangeLanguage(companySiteMeta, "membership_intro") }} />
      </div>
      <div
        style={styles.container}
      >
        <div
          style={{
            ...FONT.h3
          }}
        >
          {handleChangeLanguage(companySiteMeta, "membership_title")}
        </div>
        <div
          style={{ ...FONT.bodyText2Regular }}
          dangerouslySetInnerHTML={{ __html: handleChangeLanguage(companySiteMeta, "membership_desc") }}
        />

      </div>
      <div
        style={membershipList.length === 1 || !isMobile
          ? {
            ...styles.cardContainer,
            justifyContent: "center"
          }
          : {
            ...styles.cardContainer,
          }}
      >
        {map(membershipList, (eachMembership) => {
          const {
            membership_plan_id,
            membershipPlanDetailList
          } = eachMembership;

          const monthlyRc = find(membershipPlanDetailList, (record) => record.duration === "monthly");

          return (
            <MembershipCard
              {...eachMembership}
              key={membership_plan_id}
              price={monthlyRc ? monthlyRc.final_price : 0}
              handleChangeLanguage={handleChangeLanguage}
            />
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "32px 16px 32px 16px"
  },
  section: {
    backgroundColor: COLOR.function.background,
    padding: "16px",
    marginBottom: 24,
  },
  outerContainer: {
    width: "calc(100% + 20px)",
    marginLeft: -10
  },
  banner: {
    objectFit: "contain",
    marginBottom: "10px",
    width: "100%"
  },
  nextButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 24px",
    width: "100%",
    height: 48,
    borderRadius: 8,
    backgroundColor: COLOR.primary.primary500,
    color: "white",
    marginBottom: 24
  },
  cardContainer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    margin: "50px 25px",
    gap: "16px",
    flexWrap: "wrap",
    // overflowY: "scroll",
  }
};

export default MembershipListMobilePage;

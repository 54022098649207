import React, { useEffect, useState } from "react";
import { Grid } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import AppLayout from "../components/AppLayout";
import * as Service from "../core/Service";
import EventListMobilePage from "./EventListMobilePage";
import EventListWebPage from "./EventListWebPage";
import { setLoading } from "../redux/actions/common";
import { BREAKPOINT } from "../Theme";

const EventListContainer = () => {
  const { company, auth } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const [eventList, setEventList] = useState([]);
  const [eventCategoryList, setEventCategoryList] = useState([]);

  useEffect(() => {
    if (company.company_id && auth !== null) {
      getEventList();
    }
  }, [company, auth]);

  const getEventList = async () => {
    dispatch(setLoading(true));
    try {
      let url = "/api/v1/event/list";
      let resp = await Service.call("GET", url);
      if (!resp || resp.status !== 1) {
        return;
      }
      const { eventList: list, eventCategoryList: categoryList } = resp.data;
      setEventList(list);
      setEventCategoryList(categoryList);
    } catch (err) {
      console.error(err.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <AppLayout>
      {isMobile ? (
        <EventListMobilePage
          eventList={eventList}
          eventCategoryList={eventCategoryList}
        />
      ) : (
        <EventListWebPage
          eventList={eventList}
          eventCategoryList={eventCategoryList}
        />
      )}
    </AppLayout>
  );
};

export default EventListContainer;

import React, { useEffect, useState } from "react";
import { Grid, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useWindowWidth } from "@react-hook/window-size";
import * as Service from "../core/Service";
import { setAppModal, setLoading } from "../redux/actions/common";
import AppLayout from "../components/AppLayout";
import TokenMobileView from "../components/token/TokenMobileView";
import TokenDesktopView from "../components/token/TokenDesktopView";
import LoadingIcon from "../components/LoadingIcon";
import { useTranslation } from "react-i18next";
import * as Main from "../core/Main";
import { BREAKPOINT } from "../Theme";

const TokenPage = () => {
  const { t } = useTranslation();
  const { company, user } = useSelector((state) => state.app);
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const { company_key } = useParams();
  const history = useHistory();
  const [tokenPackageArr, setTokenPackageArr] = useState({});
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  useEffect(() => {
    if (company.company_id) {
      fetchData();
    }
  }, [company]);

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      let url = "/api/token/package/list";
      let resp = await Service.call("GET", url);
      if (!resp || resp.status !== 1) {
        return;
      }
      const { data } = resp;
      setTokenPackageArr(data);
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onClickNext = (selectedTokenPackage) => {
    if (user.is_guest) {
      notification.warning({ message: t("guest_cannot_buy_token") });
      return;
    }

    if (!selectedTokenPackage?.token_package_id) {
      notification.warning({ message: t("no_token_package_selected") });
      return;
    }
    if (!user?.company_user_id > 0) {
      dispatch(setAppModal({
        appModalVisible: true,
        type: Main.FORM_TYPE.LOGIN,
        onSuccess: () => createTokenPackageOrder(selectedTokenPackage),
      }));
    } else {
      createTokenPackageOrder(selectedTokenPackage);
    }
  };

  const createTokenPackageOrder = async (selectedTokenPackage) => {
    setButtonLoading(true);
    const putObj = {
      item_type: 5,
      item_id: selectedTokenPackage?.token_package_id,
    };

    try {
      let url = "/api/order";
      let response = await Service.call("PUT", url, putObj);

      if (response.status && response.status < 1) {
        notification.error({
          message: t("error"),
          description: t(response.errorCode),
        });
        return;
      }

      if (response.status && response.status > 0) {
        history.push(`/${company_key}/payment/${response?.orderRc?.order_id}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <AppLayout>
      {isMobile ? (
        <TokenMobileView
          tokenPackageArr={tokenPackageArr}
          clickNext={onClickNext}
          buttonLoading={buttonLoading}
        />
      ) : (
        <TokenDesktopView
          tokenPackageArr={tokenPackageArr}
          clickNext={onClickNext}
          buttonLoading={buttonLoading}
        />
      )}
    </AppLayout>
  );
};

export default TokenPage;

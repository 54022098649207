import React from "react";
import { Drawer, Modal, Grid } from "antd";
import { useWindowWidth } from "@react-hook/window-size";
import { BREAKPOINT } from "../../Theme";

const ModalDrawerContainer = (props) => {
  const { title, visible, onClose, footer, height, headerStyle = {}, bodyStyle = {}, contentWrapperStyle = {} } = props;
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  return isMobile ? (
    <Drawer
      title={title}
      placement="bottom"
      onClose={onClose}
      visible={visible}
      footer={footer}
      className="calender-drawer"
      height={height}
      headerStyle={headerStyle}
      bodyStyle={bodyStyle}
      contentWrapperStyle={contentWrapperStyle}
    >
      {props.children}
    </Drawer>
  ) : (
    <Modal title={title} visible={visible} onCancel={onClose} footer={footer}>
      {props.children}
    </Modal>
  );
};

export default ModalDrawerContainer;

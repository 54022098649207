import React, { useRef, useState, useEffect } from "react";
import { Col } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { FONT, COLOR } from "../Theme";
import { openNotificationWithIcon } from "./form/ErrorMessage";
import * as Main from "../core/Main";
import * as Service from "../core/Service";

const SendOTPButton = ({
  data,
  type
}) => {
  const { t } = useTranslation();
  const INITIAL_COUNT = 60;
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [coolDown, setCoolDown] = useState(false);

  // const secondsToDisplay = secondsRemaining % 60;
  // const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  // const minutesToDisplay = minutesRemaining % 60;
  // const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;
  const displayTime = (num) => String(num);
  const STATUS = {
    STARTED: "Started",
    STOPPED: "Stopped",
  };

  const handleStart = () => {
    setStatus(STATUS.STARTED);
    setCoolDown(true);
  };
  const handleReset = () => {
    setStatus(STATUS.STOPPED);
    setSecondsRemaining(INITIAL_COUNT);
    setCoolDown(false);
  };

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
        handleReset();
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );

  const sendOTP = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      if (_.isEmpty(data)) {
        return openNotificationWithIcon("error", t("error"), t("require_otp_media"));
      }
      let postData = {};
      if (data.email) {
        let validateEmail = Main.validateEmail(data.email);
        if (!validateEmail) {
          return openNotificationWithIcon("error", t("error"), t("incorrect_email"));
        }
        postData.otp_media = data.email;
      }
      if (data.mobile) {
        let validateMobile = Main.validateMobile(data.mobile, data.mobilePrefix);
        if (!validateMobile) {
          return openNotificationWithIcon("error", t("error"), t("incorrect_mobile"));
        }
        postData.otp_media = `${data.mobilePrefix}-${data.mobile}`;
      }
      postData.otp_type = type;
      let resp = await Service.call("post", "/api/user/get_otp", postData);
      if (resp && resp.status === -2) {
        return openNotificationWithIcon("warning", t("reminder"), t("otp_maximum_attempt", { remain_time: resp.remain_time }));
      }
      if (!resp || resp.status !== 1) {
        return openNotificationWithIcon("error", t("error"), t(`${resp.errorCode}`));
      }
      openNotificationWithIcon("success", t("success"), t("otp_sent"));
      handleStart();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <a>
      <Col
        style={{
          borderLeft: "1px solid #E7E8E8",
          padding: "3px 10px"
        }}
        onClick={coolDown ? null : sendOTP}
      >
        <p style={{
          ...FONT.captionRegular,
          color: COLOR.brandPrimary,
          alignSelf: "center",
          margin: 0
        }}
        >
          {coolDown ? `(${displayTime(secondsRemaining)} ${t("second")}) ${t("resend_code")}` : t("send_code")}
        </p>
      </Col>
    </a>
  );
};

export default SendOTPButton;

import React, { useRef } from "react";
import { Col, Grid, Card, Button, Typography } from "antd";
import Icon from "@ant-design/icons";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { COLOR, FONT } from "../../Theme";

const { Paragraph } = Typography;

const StartBookingCategoryItem = (props) => {
  const { bookingCategoryRc } = props;
  const { t, i18n } = useTranslation();
  const { config, company } = useSelector((state) => state.app);
  // const

  // booking_item_id

  return (
    <Link
      to={{
        pathname: `/${company.company_key}/category/${bookingCategoryRc.booking_category_id}`,
      }}
    >
      <Card
        // hoverable
        style={{
          borderRadius: 12,
          backgroundColor: COLOR.function.background,
          borderWidth: 0,
          width: "100%",
          height: "100%",
        }}
        cover={
          <img
            alt="booking"
            src={`${config.MEDIA_URL}/${bookingCategoryRc.filepath}`}
            style={{
              width: "100%",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              backgroundSize: "contain",
              objectFit: "contain",
              maxHeight: "50vh"
            }}
          />
        }
      >
        <Paragraph
          ellipsis={{
            rows: 1,
          }}
          style={{
            ...FONT.titleTextBold,
            color: COLOR.text.blackText,
            marginTop: 12,
          }}
        >
          {bookingCategoryRc[`category_name_${i18n.language}`] || bookingCategoryRc.category_name || ""}
        </Paragraph>
        <Paragraph
          ellipsis={{
            rows: 2,
          }}
          style={{
            ...FONT.bodyText2Regular,
            color: COLOR.text.blackText,
            marginTop: 12,
            height: 40,
          }}
        >
          {bookingCategoryRc[`short_desc_${i18n.language}`] || bookingCategoryRc.short_desc || ""}
        </Paragraph>
        <Paragraph
          ellipsis={{
            rows: 1,
          }}
          style={{
            ...FONT.captionRegular,
            color: COLOR.text.primaryText,
            marginTop: 12,
          }}
        >
          {bookingCategoryRc[`short_desc_2_${i18n.language}`] || bookingCategoryRc.short_desc_2 || ""}
        </Paragraph>
      </Card>
    </Link>
  );
};

export default StartBookingCategoryItem;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Divider,
  Row,
  Grid,
  Col,
  Modal,
  Spin,
  Button
} from "antd";
import { useWindowWidth } from "@react-hook/window-size";
import { FONT, COLOR, BREAKPOINT } from "../Theme";
import ImageCarousel from "../components/bookingDetail/ImageCarousel";
import CollapseContainer from "../components/bookingDetail/CollaspeContainer";
import LoadingIcon from "../components/LoadingIcon";
import ReserveMobile from "../components/ReserveMobile";
import { useTranslation } from "react-i18next";

const BookingCategoryDetailWebPage = ({
  categoryDetail,
  itemImgList,
  loading
}) => {
  const { t, i18n } = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const [isImageModelVisible, setIsImageModalVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(1);

  return (
    <div
      className="booking-category-detail-mobile-page"
      style={{ marginBottom: isMobile && 50, padding: "4%" }}
    >
      <Row style={{ position: "relative" }} gutter={[8]}>
        {renderImage(itemImgList, itemImgList.length)}
        <div
          className="show-image-carousel"
          onClick={() => setIsImageModalVisible(true)}
        >{`+ ${itemImgList.length} ${t("photo")}`}</div>
      </Row>
      <Row style={{ ...styles.heading }}>{categoryDetail[`category_name_${i18n.language}`] || categoryDetail.category_name || ""}</Row>
      <CollapseContainer
        content={categoryDetail[`category_desc_${i18n.language}`] || categoryDetail.category_desc || ""}
        displayRow={10}
        showButtonJustify="end"
      />
      <Divider
        style={{
          borderTopColor: COLOR.function.divider,
          width: "100%",
        }}
      />
      <ReserveMobile />
      <Modal
        visible={isImageModelVisible}
        footer={null}
        onCancel={() => setIsImageModalVisible(false)}
        wrapClassName="image-carousel-modal"
      >
        <div className="image-carousel-wrapper-web">
          <div className="image-carousel-web">
            <ImageCarousel
              coverImgArr={itemImgList}
              setImageIndex={setImageIndex}
              imageIndex={imageIndex}
              showDots={false}
              customButtonGroup
            />
            <div className="image-carousel-detail">
              <Row
                justify="space-between"
                style={{
                  position: "absolute",
                  width: "100%",
                  padding: 16,
                  bottom: 0,
                }}
              >
                <Col>{categoryDetail[`category_name_${i18n.language}`] || categoryDetail.category_name || ""}</Col>
                <Col>{`${imageIndex} / ${itemImgList.length}`}</Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const renderImage = (imgArr, length) => {
  const windowWidth = useWindowWidth();
  const notBigScreen = windowWidth < BREAKPOINT.xl;

  let type;
  if (length < 3) {
    type = 1;
  } else if ((length >= 3 && length < 5) || notBigScreen) {
    type = 2;
  } else {
    type = 3;
  }
  const Image = ({ index, lastIndex }) => {
    const config = useSelector((state) => state.app.client.config);
    return (
      <img alt={`cover_image_${index}`} src={`${config.MEDIA_URL}/${imgArr?.[index]}`} style={lastIndex ? styles.lastImage : styles.imageCover} />
    );
  };

  const ImageCol = ({ col, index, lastIndex }) => {
    return (
      <Col span={col === 2 ? 8 : col === 3 ? 6 : 0}>
        <Row style={{ height: 500 }}>
          <Col span={24} style={{ height: "50%", paddingBottom: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Image index={index - 1} />
          </Col>
          <Col span={24} style={{ height: "50%", paddingTop: 4, position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Image index={index} lastIndex={lastIndex} />
            {lastIndex && <div className="last-index-image" />}
          </Col>
        </Row>
      </Col>
    );
  };

  switch (type) {
    case 1:
      return (
        <Col span={24} style={{ height: 500 }}>
          <Image index={0} />
        </Col>
      );
    case 2:
      return (
        <>
          <Col span={16} style={{ height: 500 }}>
            <Image index={0} />
          </Col>
          <ImageCol col={2} index={2} lastIndex />
        </>
      );
    case 3:
      return (
        <>
          <Col span={12}>
            <Image index={0} />
          </Col>
          <ImageCol col={3} index={2} />
          <ImageCol col={3} index={4} lastIndex />
        </>
      );
    default:
      break;
  }
};

const styles = {
  heading: {
    padding: "32px 0 24px 0",
    fontSize: 28,
    fontWeight: 700,
  },
  subHeading: {
    font: FONT.textMedium,
    color: COLOR.captionText,
  },
  showArrow: {
    color: COLOR.primary.primary500,
    fontSize: 10,
  },
  imageCover: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    borderRadius: 8,
    display: "block",
    margin: "auto"
  },
  lastImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    borderRadius: 8,
    display: "block",
    margin: "auto"
  }
};

export default BookingCategoryDetailWebPage;

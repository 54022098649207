import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import dayjs from "dayjs";
import * as Service from "../core/Service"
import { setCompanyInfo, setLoading, setUser } from "../redux/actions/common";
import AppLayout from "../components/AppLayout";
import ProfileMobileView from "../components/profile/ProfileMobileView";
import ProfileDesktopView from "../components/profile/ProfileDesktopView";
import { BREAKPOINT, COLOR, FONT } from "../Theme";
import { useTranslation } from "react-i18next";

const ProfilePageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, company } = useSelector((state) => state.app);
  const [membershipList, setMembershipList] = useState([]);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      const url = "/api/user/profile";
      const resp = await Service.call("GET", url);
      if (!resp || resp.status !== 1) {
        return;
      }
      const { userInfo: userData, userMembershipList } = resp;
      let tokenOwnFromDetail = 0;
      let { tokenDetailList } = resp;
      if (!_.isEmpty(tokenDetailList)) {
        tokenDetailList = _.filter(tokenDetailList, (item) => item.token_remain > 0);
        tokenDetailList = _.orderBy(tokenDetailList, ["token_expiry_time"], ["asc"]);
        _.map(tokenDetailList, (eachRc) => {
          if (eachRc.token_expiry_time > dayjs().unix()) {
            tokenOwnFromDetail += eachRc.token_remain;
          }
        });
      }
      setMembershipList(userMembershipList);
      dispatch(setUser({
        ...userData,
        token_own: tokenOwnFromDetail,
        tokenDetailList
      }));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const renderTokenExpiryDate = (tokenList, token_expiry_time) => {
    if (_.isEmpty(tokenList)) {
      return (
        <div>
          {token_expiry_time ? dayjs.unix(token_expiry_time).format("DD-MM-YYYY") : "-"}
        </div>
      );
    }
    const firstExpiryRc = _.first(tokenList);
    const lastExpiryRc = _.last(tokenList);
    return (
      <Row>
        <Col span={24}>
          <span style={{
            ...FONT.titleTextBold,
            color: company.color_primary || COLOR.textPrimary
          }}
          >
            {firstExpiryRc.token_remain}
          </span>
          {" "}
          {t("token_will_be_expired_at", {
            date: dayjs.unix(firstExpiryRc.token_expiry_time).format("DD-MM-YYYY")
          })}
        </Col>
        {firstExpiryRc !== lastExpiryRc && lastExpiryRc && (
          <Col span={24}>
            {t("other_tokens_will_be_expired", {
              date: dayjs.unix(lastExpiryRc.token_expiry_time).format("DD-MM-YYYY")
            })}
          </Col>
        )}
      </Row>
    );
  };

  const renderMembershipDetail = (list) => {
    let plan_name = "-";
    let expiry_date = "-";
    if (!_.isEmpty(list)) {
      const [currentMembershipRc] = list;
      plan_name = currentMembershipRc.plan_name;
      expiry_date = dayjs.unix(currentMembershipRc.end_time).format("YYYY-MM-DD");
    }
    return (
      <Row
        style={{
          ...FONT.titleTextBold,
          color: company.color_primary || COLOR.captionText
        }}
      >
        <Col span={24}>
          {t("profile_membership_name")}
          {" "}
          <span
            style={{
              ...FONT.titleTextBold,
              color: company.color_primary || COLOR.textPrimary
            }}
          >
            {t(plan_name)}
          </span>
        </Col>
        <Col span={24}>
          {t("profile_membership_expiry_date")}
          <span
            style={{
              ...FONT.titleTextBold,
              color: company.color_primary || COLOR.textPrimary
            }}
          >
            {t(expiry_date)}
          </span>
        </Col>
      </Row>
    );
  };

  return (
    <AppLayout>
      {isMobile ? (
        <ProfileMobileView
          userInfo={user}
          renderTokenExpiryDate={renderTokenExpiryDate}
          renderMembershipDetail={renderMembershipDetail}
          userMembershipList={membershipList}
        />
      ) : (
        <ProfileDesktopView
          userInfo={user}
          renderTokenExpiryDate={renderTokenExpiryDate}
          renderMembershipDetail={renderMembershipDetail}
          userMembershipList={membershipList}
        />
      )}
    </AppLayout>
  );
};

export default ProfilePageContainer;

import React, { useRef } from "react";
import { Col, Grid, Card, Button, Typography } from "antd";
import Icon from "@ant-design/icons";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { COLOR, FONT } from "../../Theme";

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const BlogItem = (props) => {
  const { blogItemRc } = props;
  const { t, i18n } = useTranslation();
  const { config, company } = useSelector((state) => state.app);

  const directPath = (rc) => {
    let directLink = "";
    switch (rc.type_name) {
      case "blog":
        directLink = `/${company.company_key}/blog_post/${rc.blog_post_id}`;
        break;
      case "news":
        directLink = `/${company.company_key}/news/${rc.blog_post_id}`;
        break;
      default:
        directLink = `/${company.company_key}`;
        break;
    }
    return directLink;
  };

  return (
    <Link
      to={{
        pathname: directPath(blogItemRc),
      }}
    >
      <Card
        style={{
          borderRadius: 12,
          backgroundColor: COLOR.function.background,
          borderWidth: 0,
          height: "100%"
        }}
        cover={
          <img
            alt="example"
            src={
              blogItemRc.banner
                ? `${config.MEDIA_URL}/${blogItemRc.banner}`
                : `${config.STATIC_SERVER_URL}/image-not-available.png`
            }
            style={{
              width: "100%",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              backgroundSize: "contain",
              objectFit: "contain",
              maxHeight: "50vh"
            }}
          />
        }
      >
        <div
          style={{
            ...FONT.captionRegular,
            color: COLOR.text.blackText,
            marginTop: 16,
          }}
        >
          <span style={{ color: COLOR.text.captionText, fontSize: 12 }}>
            {dayjs.unix(blogItemRc.post_date).format("YYYY-MM-DD")}
          </span>
          <span style={{ color: COLOR.text.primaryText, marginLeft: 8 }}>
            {blogItemRc[`category_name_${i18n.language}`] || blogItemRc.category_name || ""}
          </span>
        </div>
        <div
          style={{
            ...FONT.titleTextRegular,
            color: COLOR.text.blackText,
            marginTop: 16,
          }}
        >
          {blogItemRc[`title_${i18n.language}`] || blogItemRc.title || ""}
        </div>
        <div
          style={{
            ...FONT.bodyText2Regular,
            color: COLOR.text.captionText,
            marginTop: 16,
          }}
        >
          <Typography.Paragraph
            ellipsis={{
              rows: 5,
              expandable: true,
              symbol: t("more")
            }}
            className="parser-container"
          >
            {htmlToReactParser.parse(blogItemRc.description)}
          </Typography.Paragraph>
        </div>
      </Card>
    </Link>
  );
};

export default BlogItem;

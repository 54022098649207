import React, { useEffect, useState } from "react";
import { Row, Col, Grid, Button, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import * as Service from "../core/Service";
import AppLayout from "../components/AppLayout";
import { FONT, COLOR, BREAKPOINT } from "../Theme";
import MainImageCarousel from "../components/Landing/MainImageCarousel";
import { setShoppingCartStatus, setCompanyInfo, setLoading } from "../redux/actions/common";
import NewsCarousel from "../components/Landing/NewsCarousel";
import BlogList from "../components/Landing/BlogList";
import StartBookingCategoryItem from "../components/Landing/StartBookingCategoryItem";
import BrandBanner from "../components/Landing/BrandBanner";
import LoadingIcon from "../components/LoadingIcon";
import default_token_img from "../assets/default_token_img.svg";
import default_membership_img from "../assets/default_membership_img.svg";
import default_booking_img from "../assets/default_booking_img.svg";
import default_event_img from "../assets/default_event_img.svg";

const LandingPage = () => {
  const [dataList, setDataList] = useState({
    coverImgArr: [],
    newsList: [],
    blogList: [],
    bookingCategoryList: [],
    siteMetaObj: {},
    landingButtonList: [],
  });
  const { company, user } = useSelector((state) => state.app);
  const config = useSelector(state => state.app.client.config);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { company_key } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (company.company_id) {
      fetchData();
    }
  }, [company]);


  const fetchData = async () => {
    await getCategoryList();
  };

  const getCategoryList = async () => {
    try {
      dispatch(setLoading(true));
      const resp = await Service.call(
        "get",
        `/api/company/landing/${company_key}`
      );
      if (resp.status === 1) {
        const {
          bannerArr,
          blogPostArr,
          newsArr,
          bookingCategoryArr,
          bookingCategoryImageArr,
          landingButtonList,
        } = resp.data;
        let keyByBookingCategoryImageObj = _.keyBy(
          bookingCategoryImageArr,
          "booking_category_id"
        );
        // console.log(_.orderBy(newsArr, ["post_date"], ["desc"]));
        setDataList({
          coverImgArr: _.map(bannerArr, "banner"),
          blogList: blogPostArr,
          newsList: _.orderBy(newsArr, ["post_date"], ["desc"]),
          bookingCategoryList: _.map(bookingCategoryArr, (eachItem) => {
            let imageRc = keyByBookingCategoryImageObj[eachItem.booking_category_id]
              || {};
            return {
              ...eachItem,
              filepath: imageRc.filepath || "",
            };
          }),
          landingButtonList,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const renderMoreButton = (type) => {
    let redirectUrl = "";
    switch (type) {
      case "blogs":
        redirectUrl = `/${company.company_key}/blog_post`;
        break;
      case "start_booking":
        redirectUrl = `/${company.company_key}/booking`;
        break;
      default:
        redirectUrl = `/${company.company_key}`;
        break;
    }
    return (
      <Row justify="center" style={{ margin: 32 }}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Link
            to={{
              pathname: redirectUrl,
            }}
          >
            <Button
              style={{
                backgroundColor: COLOR.primary.primary500,
                ...FONT.buttonRegular,
                color: COLOR.text.whiteText,
                width: "100%",
                height: 48,
                borderRadius: 8,
              }}
            >
              {t("more")}
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };

  return (
    <AppLayout>
      <div className="landing-page">
        <MainImageCarousel
          coverImgArr={dataList.coverImgArr}
        />
      </div>
      <div style={{ padding: "16px 0" }}>
        {!_.isEmpty(dataList.landingButtonList) && (
          <Row gutter={[24]} style={{ marginTop: 36 }}>
            {_.map(dataList.landingButtonList, (btn, index) => {
              let {
                company_button_id,
                button_img,
                button_title,
                link
              } = btn;
              let default_image = null;
              if (_.isEmpty(button_img)) {
                if (link.includes("token")) default_image = default_token_img;
                if (link.includes("membership")) default_image = default_membership_img;
                if (link.includes("booking")) default_image = default_booking_img;
                if (link.includes("event")) default_image = default_event_img;
              }
              return (
                <Col
                  span={6}
                  key={`${company_button_id}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/${company_key}${link}`)}
                >
                  <Row justify="center">
                    <img
                      style={{
                        width: isMobile ? 28 : 100,
                        height: isMobile ? 28 : 100,
                      }}
                      key={index}
                      draggable={false}
                      alt="btn_image"
                      src={!_.isEmpty(button_img)
                        ? `${config.MEDIA_URL}/${button_img}`
                        : default_image}

                    />
                  </Row>
                  <Row
                    justify="center"
                    style={{
                      ...FONT.bodyText2Regular,
                      color: COLOR.text.blackText,
                      marginTop: 10,
                      fontSize: isMobile ? "14px" : "18px"
                    }}
                  >
                    {t(_.toLower(button_title))}
                  </Row>
                </Col>
              );
            })}
          </Row>
        )}
      </div>

      {/* News */}
      {!_.isEmpty(dataList.newsList) && (
        <div className="landing-page landing-page-news">
          <div
            style={{
              color: COLOR.text.primaryText,
              ...FONT.h5,
              margin: "32px 0 0px",
              padding: "0 16px"
            }}>
            {t("news")}
          </div>
          <NewsCarousel newsList={dataList.newsList} />
        </div>
      )}

      {/* Blog */}
      {!_.isEmpty(dataList.blogList) && (
        <div style={{ padding: "0 16px" }} className="landing-page">
          <div
            style={{
              color: COLOR.text.primaryText,
              ...FONT.h5,
              margin: "32px 0 24px",
            }}
          >
            {t("blogs")}
          </div>
          <Row
            gutter={[{ md: 20, lg: 28 }, 24]}
          >
            <BlogList blogList={dataList.blogList} />
          </Row>
          {/* More button */}
          {renderMoreButton("blogs")}
        </div>
      )}

      <div style={{ padding: "0 16px" }} className="landing-page">
        {/* Start booking */}
        <div
          style={{
            color: COLOR.text.primaryText,
            ...FONT.h5,
            margin: "32px 0 24px",
          }}
        >
          {t("start_booking")}
        </div>
        <Row
          gutter={[{ md: 20, lg: 28 }, 24]}
        >
          {_.map(
            _.slice(dataList.bookingCategoryList, 0, 4),
            (eachBookingCategoryRc, key) => {
              return (
                <Col xs={24} sm={24} md={24} lg={8} xl={6} key={key}>
                  <StartBookingCategoryItem
                    bookingCategoryRc={eachBookingCategoryRc}
                  />
                </Col>
              );
            }
          )}
        </Row>
        {/* More button */}
        {renderMoreButton("start_booking")}
      </div>
    </AppLayout>
  );
};

export default LandingPage;

import _ from "lodash";
import * as types from "./types";
// import AsyncStorage from '@react-native-community/async-storage';
// import * as Main from '../../core/Main';
import * as bookingAPI from "../api/booking";
import * as companyApi from "../api/company";

export function setConfig(appConfig) {
  return {
    type: types.SET_CONFIG,
    data: appConfig,
  };
}

export function setAdmin(admin) {
  return {
    type: types.SET_ADMIN,
    data: admin
    // data: _.pick(admin, [
    //   'admin_id',
    //   'email',
    //   'mobile',
    //   'level',
    //   'admin_photo',
    //   'admin_role',
    //   'is_active',
    //   'status',
    //   'username',
    //   'nickname'
    // ])
  };
}

export function setCompanyAdmin(company_admin) {
  return {
    type: types.SET_COMPANY_ADMIN,
    data: company_admin
  };
}

export function setAuth(state) {
  return {
    type: types.SET_AUTH,
    data: state
  };
}

export function setOrigin(admin) {
  return {
    type: types.SET_ORIGIN,
    data: admin
  };
}

export function setLoading(loading) {
  return {
    type: types.SET_LOADING,
    data: loading
  };
}

export function setLogoutWay(logoutWay) {
  return {
    type: types.SET_LOGOUTWAY,
    data: logoutWay
  };
}

// export function setCompany(company) {
//   return {
//     type: types.SET_COMPANY,
//     data: company
//   };
// }

export function setClientData(clientData) {
  return {
    type: types.SET_CLIENT_DATA,
    data: clientData,
  };
}

export function setAppData(data) {
  return {
    type: types.APP_DATA,
    data
  };
}

export function getSession() {
  return {
    type: types.GET_SESSION,
    data: null
  };
}

export function setUser(user) {
  if (!user) { return; }
  user.isUser = false;
  // user.isAdmin = false
  user.checked = true;
  if (_.toInteger(user.company_user_id) > 0) {
    user.isUser = true;
  }
  // if (_.toInteger(user.admin_id) > 0) {
  //   user.isAdmin = true
  // }
  return {
    type: types.SET_USER,
    data: user
  };
}

export function setDrawerVisible(_visible) {
  return {
    type: types.SET_DRAWER,
    data: _visible
  };
}

export function toggleLoading(_loading) {
  return {
    type: types.SET_LOADING,
    data: _loading
  };
}

export function setRentForm(_data) {
  return {
    type: types.SET_RENT_FORM,
    data: _data
  };
}

export function setSMSites(smSites) {
  return {
    type: types.SET_SM_SITES,
    data: smSites,
  };
}

export function setCompanyBooking(company_key) {
  return async (dispatch) => {
    try {
      let result = await companyApi.getCompanyData(company_key);
      if (!result) return;
      const {
        companyInfo,
        itemCategory,
        item,
        holidayList
      } = result;
      dispatch({
        type: types.SET_COMPANY,
        data: companyInfo,
      });
      dispatch({
        type: types.SET_ITEM_CATEGORY,
        data: itemCategory,
      });
      dispatch({
        type: types.SET_ITEM,
        data: item,
      });
      dispatch({
        type: types.SET_HOLIDAY,
        data: holidayList,
      });
    } catch (error) {
      console.error("setCompanyBooking error msg ----------", error);
      dispatch({
        type: types.SET_ERROR_REDIRECT,
        data: true,
      });
    }
  };
}

export function setCompany(company) {
  return {
    type: types.SET_COMPANY,
    data: company,
  };
}

export function setItemCategory(itemCategory) {
  return {
    type: types.SET_ITEM_CATEGORY,
    data: itemCategory,
  };
}

export function setSelectedItemCategory(selectedItemCategory) {
  return {
    type: types.SET_SELECTED_ITEM_CATEGORY,
    data: selectedItemCategory,
  };
}

export function setItem(item) {
  return {
    type: types.SET_ITEM,
    data: item,
  };
}

export function setSelectedItem(selectedItem) {
  return {
    type: types.SET_SELECTED_ITEM,
    data: selectedItem,
  };
}

export function setSelectedDate(selectedDate) {
  return {
    type: types.SET_SELECTED_DATE,
    data: selectedDate,
  };
}

export function setSelectedBooking(selectedBooking) {
  return {
    type: types.SET_SELECTED_BOOKING,
    data: selectedBooking,
  };
}

export function setSelectedCapacity(selectedCapacity) {
  return {
    type: types.SET_SELECTED_CAPACITY,
    data: selectedCapacity,
  };
}

export function setBooking() {
  return async (dispatch) => {
    let booking = await bookingAPI.getBookingData();
    dispatch({
      type: types.SET_BOOKING,
      data: booking,
    });
  };
}

export function setRuleSet() {
  return async (dispatch) => {
    dispatch({
      type: types.SET_RULE_SET,
      data: {},
    });
    let ruleset = await bookingAPI.getRuleSet();
    dispatch({
      type: types.SET_RULE_SET,
      data: ruleset,
    });
  };
}

export function setHoliday(holidayList) {
  return {
    type: types.SET_HOLIDAY,
    data: holidayList,
  };
}

export function setUserSession(data) {
  return {
    type: types.SET_USER_SESSION,
    data,
  };
}

export function setErrorRedirect(bool) {
  return {
    type: types.SET_ERROR_REDIRECT,
    data: bool,
  };
}

export function resetOrderState() {
  return {
    type: types.RESET_ORDER,
  };
}

export function setBookingPurpose(purpose) {
  return {
    type: types.SET_BOOKING_PURPOSE,
    data: purpose,
  };
}

export function setOrderInfo(data) {
  return {
    type: types.SET_ORDER_INFO,
    data,
  };
}

export function setOrderItemList(data) {
  return {
    type: types.SET_ORDER_ITEM_LIST,
    data,
  };
}

export function setShoppingCartAmount(data) {
  return {
    type: types.SET_SHOPPING_CART_AMOUNT,
    data,
  };
}

export function setShoppingCartStatus(data) {
  return {
    type: types.SET_SHOPPING_CART_STATUS,
    data
  };
}

export function setCompanyInfo(company_key, history) {
  return async (dispatch) => {
    try {
      let {
        companyInfo,
        siteMetaMap
      } = await companyApi.getCompanyInfo(company_key);
      if (!companyInfo) {
        return history.replace("/404");
      }
      let companyMetaObj = {};
      let keyBySiteMeta = _.keyBy(siteMetaMap, "meta_key");
      _.each(keyBySiteMeta, (value, key) => {
        companyMetaObj[key] = value.meta_value;
      });
      dispatch({
        type: types.SET_COMPANY,
        data: companyInfo,
      });
      dispatch({
        type: types.SET_COMPANY_SITE_META,
        data: companyMetaObj
      });
    } catch (error) {
      console.error("setCompanyInfo error msg ----------", error);
      return history.replace("/404");
    }
  };
}

export function setAppModal({
  appModalVisible,
  type,
  onSuccess = () => null
}) {
  return {
    type: types.SET_APP_MODAL,
    data: {
      appModalVisible,
      type,
      onSuccess
    },
  };
}

export function setQRCodeVisible(visible) {
  return {
    type: types.SET_QR_CODE_VISIBLE,
    data: visible,
  };
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button
} from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import CardItem from "./CardItem";
import { FONT, COLOR } from "../../Theme";

const EventOrderDetail = ({
  data,
  itemStyle = {},
  orderStatus,
  onDelete = () => null,
  setCancelTarget,
  setCancelModalVisible,
}) => {
  const { t } = useTranslation();
  const {
    order_item_id,
    item_name,
    quantity,
    start_time,
    end_time,
    is_active: orderItemStatus
  } = data;

  return (
    <div>
      <div className="order-detail-card-wrapper">
        <div style={{ fontWeight: 'bold' }}>{item_name}</div>
      </div>
      <Row gutter={[0, 12]}>
        <CardItem title={t("quantity")} value={t(quantity)} />
        <CardItem
          title={t("date")}
          value={`${dayjs.unix(start_time).format("DD-MM-YYYY")} - ${dayjs
            .unix(end_time)
            .format("DD-MM-YYYY")}`}
        />
        <CardItem
          title={t("time")}
          value={`${dayjs.unix(start_time).format("HH:mm")} - ${dayjs
            .unix(end_time)
            .format("HH:mm")}`}
        />
      </Row>
      {_.includes([
        "partial_payment_refund",
        "partial_payment_refund_token",
        "partial_payment_refund_offline",
        "payment_confirmed",
        "payment_refund",
        "payment_refund_token",
        "payment_refund_offline",
        "cancelled"
      ], orderStatus)
        && (
          <Col
            span={24}
            style={{
              ...FONT.buttonRegular,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="ghost"
              style={{
                border: "none",
                color: "#9c1d14",
                backgroundColor: "white"
              }}
              onClick={() => {
                setCancelTarget({
                  order_item_id
                });
                setCancelModalVisible(true);
              }}
              disabled={orderItemStatus !== 1}
            >
              <span
                style={orderItemStatus === 1 ? {
                  textDecoration: "underline",
                } : {
                  textDecoration: "underline",
                  color: COLOR.gray.gray400,
                }}
              >
                {orderItemStatus === 1 ? t("cancel") : t("cancelled")}
              </span>
            </Button>
          </Col>
        )}
    </div>
  );
};

export default EventOrderDetail;

import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function ImageDot({ onClick, ...rest }) {
  const {
    index,
    product_image,
  } = rest;

  const [productImages, setProductImages] = useState([]);
  const config = useSelector((state) => state.app.client.config)

  useEffect(() => {
    const productImages = [];
    product_image.map((item) => {
      productImages.push((
        <div style={{
          width: '100%', borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto'
        }}
        >
          <img style={{ width: '100%', height: 120, objectFit: 'cover' }} src={`${config.MEDIA_URL}/${item}`} alt="cover_img_preview" />
        </div>
      ));
    });

    setProductImages(productImages);
  }, []);

  return (
    <div
      style={{
        marginTop: 25,
        width: '100%',
        marginRight: index !== (productImages.length - 1) ? 20 : 0,
        cursor: 'pointer'
      }}
      onClick={() => onClick()}
    >
      {productImages[index]}
    </div>
  );
}

export default ImageDot;

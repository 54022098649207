import React from "react";

const OrderCardWrapper = ({ title, children, style = {}, titleStyle = {} }) => {
  return (
    <div className="order-detail-card" style={{...style}}>
      <div className="order-title" style={{...titleStyle}}>{title}</div>
      {children}
    </div>
  );
};

export default OrderCardWrapper;

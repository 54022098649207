import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CompanyLogo = (props) => {
  const defaultStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  };

  const { meta, config } = useSelector((state) => state.app.client);
  const company = useSelector((state) => state.app.company);
  const history = useHistory();
  const { MEDIA_URL } = config;
  const {
    customStyle,
    width
  } = props;
  const [logoStyle, setLogoStyle] = useState(
    !_.isUndefined(customStyle) ? { ...defaultStyle, ...customStyle } : defaultStyle
  );
  return (
    <a onClick={() => history.push(`/${company.company_key}`)}>
      <img width={width} style={logoStyle} src={!_.isEmpty(company) ? `${MEDIA_URL}/${company.company_logo}` : null} alt="company_logo" />
    </a>
  );
};

export default CompanyLogo;

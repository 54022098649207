import { Grid } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { map } from "lodash";
import { useWindowWidth } from "@react-hook/window-size";
import AppLayout from "../components/AppLayout";
import * as Service from "../core/Service";
import EventMobilePage from "./EventMobilePage";
import EventWebPage from "./EventWebPage";
import { setLoading } from "../redux/actions/common";
import { BREAKPOINT } from "../Theme";

const EventPageContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { company, user } = useSelector((state) => state.app);
  const { company_key, event_key } = useParams();
  const [eventInfo, setEventInfo] = useState({
    eventInfo: {},
    eventMeta: {},
    eventSection: [],
    eventBannerList: []
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (company.company_id) {
      fetchData();
    }
  }, [company]);

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      let url = `/api/v1/event/detail/${event_key}`;
      let resp = await Service.call("GET", url);
      if (!resp || resp.status !== 1) {
        return history.replace(`/${company_key}/event/list`);
      }
      console.log("resp", resp);
      const { eventInfo: eventData } = resp.data;
      const {
        eventBannerList
      } = eventData;
      const bannerList = map(eventBannerList, "filepath");
      setEventInfo({
        ...eventData,
        eventBannerList: bannerList
      });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <AppLayout className="event-page-layout">
      {isMobile ? (
        <EventMobilePage eventData={eventInfo} />
      ) : (
        <EventWebPage eventData={eventInfo} />
      )}
    </AppLayout>
  );
};

export default EventPageContainer;

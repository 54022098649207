import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Grid,
  Button,
  Divider,
  Input,
  Select,
  Form,
  Checkbox,
  notification,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useParams } from "react-router";
import { useWindowWidth } from "@react-hook/window-size";
import * as Service from "../core/Service";
import AppLayout from "../components/AppLayout";
import { FONT, COLOR } from "../Theme";
import TNContent from "../TNCore/TNContent";
import { setLoading } from "../redux/actions/common";

const { TextArea } = Input;

const inputStyle = {
  height: "45px",
  backgroundColor: COLOR.function.background,
  borderRadius: "8px",
};

const areaCodeList = [
  { label: "+852", value: "+852" },
  { label: "+86", value: "+86" },
];

const ContactUsPage = () => {
  const [dataList, setDataList] = useState({
    contactUsHeader: {},
    map: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const {
    companySiteMeta,
    config,
    company
  } = useSelector((state) => state.app);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { company_key } = useParams();
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();

  // useEffect(() => {
  //   if (company.company_id) {
  //     getCategoryList();
  //   }
  // }, [company]);

  // const getCategoryList = async () => {
  //   dispatch(setLoading(true));
  //   try {
  //     const resp = await Service.call("get", "/api/company/contact/us");
  //     if (resp.status === 1) {
  //       const { companySiteMetaArr } = resp.data;
  //       const keyByCompanySiteMeta = _.keyBy(companySiteMetaArr, "meta_key");
  //       setDataList({
  //         contactUsHeader: {
  //           banner: keyByCompanySiteMeta.contact_us_banner?.meta_value,
  //           title: keyByCompanySiteMeta.contact_us_en?.meta_value,
  //         },
  //         map: keyByCompanySiteMeta.contact_us_map?.meta_value,
  //       });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     dispatch(setLoading(false));
  //   }
  // };

  const onFinish = async (submitRecord) => {
    try {
      setButtonLoading(true);
      let postData = {
        ...submitRecord,
        mobile: `${submitRecord.mobile_prefix}-${submitRecord.mobile_subfix}`,
        name: `${submitRecord.first_name} ${submitRecord.last_name}`,
        question: _.toString(submitRecord.message),
        first_name: submitRecord.first_name,
        last_name: submitRecord.last_name
      };
      const resp = await Service.call(
        "post",
        "/api/company/contact/us",
        postData
      );
      if (resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setChecked(false);
      form.resetFields();
      return notification.success({
        message: t("success"),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <AppLayout>
      <TNContent>
        <div style={{ position: "relative" }}>
          <Row
            span={24}
            style={{
              backgroundImage: `url(${`${config.MEDIA_URL}/${companySiteMeta.contact_us_banner}`})`,
              backgroundColor: "rgba(0,0,0,0.5)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "darken",
              backgroundPosition: "center",
              opacity: 2,
              width: "100%",
              height: "300px"
            }}
            align="middle"
            justify="center"
          >
            <Col
              xs={24}
              sm={16}
              md={12}
              lg={8}
              style={{ textAlign: "center" }}
            >
              <span
                style={{
                  ...FONT.h3,
                  color: COLOR.warning.warning300,
                }}
              >
                {t("contact_us")}
              </span>
              <br />
              <span
                style={{
                  color: COLOR.text.whiteText,
                  ...FONT.bodyText2Regular,
                }}
              >
                {companySiteMeta[`contact_us_${i18n.language}`]}
              </span>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              bottom: -1,
              width: "100%",
              backgroundColor: "white",
              height: 30,
              borderTopRightRadius: 45,
              borderTopLeftRadius: 45,
            }}
          />
        </div>

        <Row
          style={{
            ...FONT.captionRegular,
            color: COLOR.text.captionText,
            padding: "2% 4%",
          }}
          gutter={[20, 20]}
        >
          <Col xs={24} sm={24} md={12} lg={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <iframe
                title={t("location")}
                style={{ height: "100%", width: "100%" }}
                src={companySiteMeta?.contact_us_map}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form form={form} className="contactUsForm" onFinish={onFinish}>
              <Row>{t("contact_us_questions")}</Row>
              <Divider style={{ color: COLOR.function.divider }} />
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  {t("first_name")}
                  <span style={{ color: COLOR.negative.negative500 }}>*</span>
                </Col>
                <Col span={12}>
                  {t("last_name")}
                  <span style={{ color: COLOR.negative.negative500 }}>*</span>
                </Col>
              </Row>

              {/* First last name */}
              <Row gutter={[20, 20]} style={{ marginTop: "5px" }}>
                <Col span={12}>
                  <Form.Item
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: t("contact_require_first_name"),
                        pattern: "[a-zA-Z]+"
                      },
                    ]}
                  >
                    <Input placeholder={t("first_name")} style={inputStyle} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: t("contact_require_last_name"),
                        pattern: "[a-zA-Z]+"
                      },
                    ]}
                  >
                    <Input placeholder={t("last_name")} style={inputStyle} />
                  </Form.Item>
                </Col>
              </Row>

              {/* Company name */}
              <Row gutter={[20, 20]} style={{ marginTop: "5px" }}>
                <Col span={24}>{t("company_name")}</Col>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <Col span={24}>
                  <Form.Item name="company_name">
                    <Input placeholder={t("company_name")} style={inputStyle} />
                  </Form.Item>
                </Col>
              </Row>

              {/* Phone number */}
              <Row gutter={[20, 20]} style={{ marginTop: "5px" }}>
                <Col>
                  {t("phone_number")}
                  <span style={{ color: COLOR.negative.negative500 }}>*</span>
                </Col>
              </Row>
              <Row gutter={[20, 20]} style={{ marginTop: "5px" }}>
                <Col xs={5} sm={4} md={5} lg={4}>
                  <Form.Item
                    name="mobile_prefix"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      style={{ ...inputStyle, width: "100%" }}
                      showArrow={false}
                      className="mobile_prefix"
                      options={areaCodeList}
                    />
                  </Form.Item>
                </Col>
                <Col xs={19} sm={20} md={19} lg={20}>
                  <Form.Item
                    name="mobile_subfix"
                    rules={[
                      {
                        required: true,
                        message: t("contact_require_phone_number"),
                        pattern: /^\d{0,13}$/i,
                      },
                    ]}
                  >
                    <Input placeholder={t("phone_number")} style={inputStyle} />
                  </Form.Item>
                </Col>
              </Row>

              {/* Email address */}
              <Row gutter={[20, 20]} style={{ marginTop: "5px" }}>
                <Col>
                  {t("email")}
                  <span style={{ color: COLOR.negative.negative500 }}>*</span>
                </Col>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("contact_require_email"),
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder={t("email")}
                      style={inputStyle}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Message */}
              <Row gutter={[20, 20]} style={{ marginTop: "5px" }}>
                <Col>
                  {t("message")}
                  <span style={{ color: COLOR.negative.negative500 }}>*</span>
                </Col>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <Col span={24}>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: t("contact_require_question"),
                      },
                    ]}
                  >
                    <TextArea
                      type="text"
                      placeholder={t("hello,")}
                      style={{ ...inputStyle, height: 150 }}
                      rows={5}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 20]} style={{ marginTop: "10px" }}>
                <Col>
                  <Checkbox
                    onChange={(value) => {
                      setChecked(value.target.checked);
                    }}
                    checked={checked}
                  >
                    {t("i_agree")}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${config.STATIC_SERVER_URL}/${company_key}/terms/conditions`}
                      style={{
                        color: COLOR.text.primaryText,
                        marginLeft: "3px",
                      }}
                    >
                      {t("terms_of_service")}
                    </a>
                  </Checkbox>
                </Col>
              </Row>
              <Row style={{ margin: "16px 0" }}>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      style={{
                        backgroundColor: checked ? COLOR.function.white : (COLOR.grey && COLOR.grey.grey100),
                        color: checked ? COLOR.primary.primary500 : COLOR.text.white,
                        borderColor: checked ? COLOR.primary.primary500 : (COLOR.grey && COLOR.grey.grey100),
                        height: "48px",
                        width: "100%",
                        borderRadius: "8px",
                      }}
                      disabled={!checked}
                      loading={buttonLoading}
                    >
                      {t("submit")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </TNContent>
    </AppLayout>
  );
};

export default ContactUsPage;

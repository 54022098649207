import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Grid,
  Collapse,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";
import { useTranslation } from "react-i18next";
import { BREAKPOINT, COLOR } from "../../Theme";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import * as Service from "../../core/Service";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const ContactInfoSection = ({
  step,
  setStep,
  contactInfo,
  setContactInfo,
  order_id
}) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  return (
    <>
      {step === 1 && (
        <div
          className="section-card"
          style={{
            marginBottom: 24,
            marginTop: !isMobile && 0,
            borderRadius: !isMobile && 8,
          }}
        >
          <div className="section-title">{t("enter_information")}</div>
          <div>{t("enter_info_desc")}</div>
          <Divider className="medium-divider" />
          <ContactInfoForm
            setStep={setStep}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            order_id={order_id}
          />
        </div>
      )}
      {step >= 2 && <ContactInfoSummary data={contactInfo} />}
    </>
  );
};

export default ContactInfoSection;

const ContactInfoForm = ({
  setStep,
  contactInfo,
  setContactInfo,
  order_id
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { user } = useSelector((state) => state.app);

  const [buttonLoading, setButtonLoading] = useState();

  const onFinish = async (formObj) => {
    setButtonLoading(true);
    setContactInfo(formObj);
    const {
      nickname,
      mobile,
      email,
      no
    } = formObj;
    let resp = await Service.call("patch", "/api/order/contact", {
      order_id,
      ...formObj
    });

    if (resp.status === 1) {
      if (!user.is_guest) {
        localStorage.setItem("contactInfo", JSON.stringify({
          nickname,
          no,
          mobile,
          email
        }));
      }
      setStep(2);
    } else {
      notification.error({
        message: t("error"),
        description: t(resp?.errorCode),
      });
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    let pastFormObj = JSON.parse(localStorage.getItem("contactInfo"));
    form.setFieldsValue({
      ...contactInfo,
      ...pastFormObj
    });
  }, [contactInfo]);

  return (
    <Form
      name="form"
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="contact-info-form"
    >
      <div style={{ color: "#000", fontWeight: "bold", marginBottom: 24 }}>
        {t("contact_info")}
      </div>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="nickname"
            label={t("name")}
            rules={[{ required: true, message: t("contact_require_nickname") }]}
          >
            <Input placeholder={t("name")} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="no" label={t("area_code")}>
            <Select>
              <Option value="+852">+852</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            name="mobile"
            label={t("phone_number")}
            rules={[
              {
                required: true,
                message: t("contact_require_phone_number"),
              },
            ]}
          >
            <Input placeholder={t("phone_number")} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="email"
            label={t("email")}
            rules={[
              {
                required: true,
                message: t("contact_require_email"),
              },
            ]}
          >
            <Input type="email" placeholder={t("email_address")} />
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0 32px" }} />
      <div style={{ color: COLOR.captionText }}>
        <Space style={{ marginBottom: 16 }}>
          <span style={{ color: "#000", fontWeight: "bold" }}>
            {t("special_requests")}
          </span>
          {`(${t("optional")})`}
        </Space>
        <div style={{ marginBottom: 24 }}>{t("special_requests_desc")}</div>
        <Form.Item name="special_requests">
          <TextArea
            placeholder={t("special_requests_placeholder")}
            style={{ minHeight: 180 }}
          />
        </Form.Item>
        <Divider className="medium-divider" />
        <div>{t("next_step_guide")}</div>
        <Form.Item
          style={{
            marginTop: 24,
            marginBottom: 0,
            textAlign: isMobile ? ":left" : "right",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="action-btn"
            style={{ width: isMobile ? "100%" : "fit-content" }}
            loading={buttonLoading}
          >
            {t("next")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

const ContactInfoSummary = ({ data }) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const {
    nickname,
    mobile,
    no,
    email,
    special_requests
  } = data;
  const { t } = useTranslation();
  return (
    <div
      className="section-card"
      style={{
        padding: 0,
        marginBottom: 24,
        borderRadius: !isMobile && 8,
        marginTop: !isMobile && 0,
      }}
    >
      {/* <div className="section-title">
        Contact info
      </div>
      <Divider style={{ margin: "16px 0" }} />
      <Row gutter={[0, 16]}>
        <ListItem title="Name" value={`${fname} ${lname}`} />
        <ListItem title="Phone Number" value={`${no} ${mobile}`} />
        <ListItem title="Email Address" value={email} />
        <ListItem title="Special Requests" value={special_requests} />
      </Row> */}
      <Collapse
        className="order-summary-collapse"
        style={{ borderRadius: !isMobile && 8 }}
        bordered={false}
        expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)}
        expandIconPosition="right"
        defaultActiveKey="default"
      >
        <Panel
          key="default"
          header={<div className="section-title">{t("contact_info")}</div>}
        >
          <Row gutter={[0, 16]}>
            <ListItem title={t("nickname")} value={nickname} />
            <ListItem title={t("phone_number")} value={`${no} ${mobile}`} />
            <ListItem title={t("email_address")} value={email} />
            <ListItem title={t("special_requests")} value={special_requests} />
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

const ListItem = ({ title, value }) => {
  return (
    <Col span={24}>
      <Row justify="space-between">
        <Col span={10} style={{ color: COLOR.captionText }}>
          {title}:
        </Col>
        <Col span={14}>
          <div style={{ wordBreak: "break-word" }}>{value}</div>
        </Col>
      </Row>
    </Col>
  );
};

import { Button, Card, Col, Image, Pagination, Row, Space, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import queryString from "query-string";
import ModalDrawerContainer from "../components/bookingDetail/ModalDrawerContainer";
import { COLOR, FONT } from "../Theme";
import LoadingIcon from "../components/LoadingIcon";
import defaultEventBanner from "../assets/default_event_banner.png";

const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();

const EventListMobilePage = ({ eventList, eventCategoryList }) => {
  let location = useLocation();
  const { company_key } = useParams();
  const { category } = queryString.parse(location.search);
  const { t } = useTranslation();
  const history = useHistory();

  const [categoryDrawerVisible, setCategoryDrawerVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(category);

  const { companySiteMeta } = useSelector((state) => state.app);
  const { MEDIA_URL } = useSelector((state) => state.app.config);

  const { event_list_title, event_list_desc, event_list_banner } = companySiteMeta;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  return (
    <div className="event-list-mobile-page">
      {category ? (
        <div>
          <div style={{ ...styles.mainTitle, marginTop: 16 }}>
            {htmlToReactParser.parse(event_list_title)}
          </div>
          <Button
            type="primary"
            ghost
            style={{ ...styles.ghostBtn }}
            onClick={() => setCategoryDrawerVisible(true)}
          >
            <span>{t(category || selectedCategory)}</span>
            <DownOutlined style={{ fontSize: 12 }} />
          </Button>
          <Row gutter={[0, 16]} style={{ padding: "24px 0", minHeight: 200 }}>
            <EventList
              eventListIds={
                _.find(eventCategoryList, ["title", category])?.eventList || []
              }
              eventList={eventList}
            />
          </Row>
          <ModalDrawerContainer
            title={
              <div style={{ ...styles.categoryTitle }}>{t("category")}</div>
            }
            visible={categoryDrawerVisible}
            onClose={() => setCategoryDrawerVisible(false)}
            footer={null}
          >
            <Space direction="vertical" size={24} style={{ width: "100%" }}>
              <Button
                key="all"
                style={{ ...styles.commonBtn }}
                className={
                  category?.title === selectedCategory
                    ? "selected"
                    : "default"
                }
                onClick={() => {
                  setSelectedCategory(category?.title);
                  history.push(
                    `/${company_key}/event/list?category=all`
                  );
                }}
              >
                {t("all_category")}
              </Button>
              {_.map(eventCategoryList, (category) => (
                <Button
                  key={category.event_category_id}
                  style={{ ...styles.commonBtn }}
                  className={
                    category?.title === selectedCategory
                      ? "selected"
                      : "default"
                  }
                  onClick={() => {
                    setSelectedCategory(category?.title);
                    history.push(
                      `/${company_key}/event/list?category=${category?.title}`
                    );
                  }}
                >
                  {category?.title}
                </Button>
              ))}
            </Space>
          </ModalDrawerContainer>
        </div>
      ) : (
        <div>
          <Image
            src={event_list_banner ? `${MEDIA_URL}/${event_list_banner}` : defaultEventBanner}
            width="100%"
            alt="cover-image"
            preview={false}
            style={{ objectFit: "contain", minHeight: 200, maxHeight: 300 }}
          />
          <div style={{ padding: 16 }}>
            <div>
              <div style={{ ...styles.mainTitle }}>{htmlToReactParser.parse(event_list_title)}</div>
              <div style={{ ...styles.desc }}>{htmlToReactParser.parse(event_list_desc)}</div>
            </div>
            <div style={{ marginBottom: 24 }}>
              {_.map(eventCategoryList, (list) => (
                <EventListByCategory
                  key={`category_${list.event_category_id}`}
                  categoryList={list}
                  eventList={eventList}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventListMobilePage;

const EventListByCategory = ({ categoryList, eventList }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { company_key } = useParams();
  const carouselItem = useRef();
  const eventListKeyByIds = _.keyBy(eventList, "event_id");

  const { title } = categoryList;
  const eventDetailList = _.map(
    categoryList?.eventList,
    (id) => eventListKeyByIds[id]
  ).filter((item) => item);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div style={{ marginTop: 24 }}>
      <div style={{ ...FONT.h5, marginBottom: 16 }}>{title}</div>
      <Carousel
        ref={carouselItem}
        autoPlay
        swipeable
        draggable={false}
        arrows={false}
        responsive={responsive}
        infinite
        keyBoardControl
        customTransition="transform 500ms ease-in-out"
        itemClass="carousel-item"
      >
        {_.map(eventDetailList, (event) => (
          <div key={event?.event_key} style={{ height: "100%" }}>
            {event && (<EventItem event={event} />)}
          </div>
        ))}
      </Carousel>

      <div>
        <Button
          type="primary"
          ghost
          className="ghost-btn"
          style={{ ...styles.ghostBtn }}
          onClick={() => history.push(`/${company_key}/event/list?category=${title}`)}
        >
          <span>{t("learn_more")}</span>
          <RightOutlined style={{ fontSize: 12 }} />
        </Button>
      </div>
    </div>
  );
};

const EventList = ({ eventListIds = [], eventList = [] }) => {
  const defaultPagination = {
    current: 1,
    pageSize: 20,
    total: _.isEmpty(eventListIds) ? eventList?.length : eventListIds?.length
  };

  const eventListKeyByIds = _.keyBy(eventList, "event_id");
  const [pagination, setPagination] = useState(defaultPagination);

  const renderEventListItem = (pagination) => {
    const { current, pageSize } = pagination;
    let currentIndex = pageSize * (current - 1);
    let lastIndex = pageSize * current;
    let list =
      _.isEmpty(eventListIds) ?
        eventList?.slice(currentIndex, lastIndex) :
        eventListIds?.slice(currentIndex, lastIndex);
    return list;
  };

  useEffect(() => {
    setPagination(defaultPagination);
  }, [eventListIds, eventList]);

  const onPageChange = (paginationObj) => {
    setPagination({ ...paginationObj });
  };

  return (
    <>
      {_.isEmpty(eventListIds) ? (
        <>
          {_.map(renderEventListItem(pagination), (event) => (
            <Col key={event?.event_id} span={24}>
              <EventItem event={event} />
            </Col>
          ))}
        </>
      ) : (
        <>
          {_.map(renderEventListItem(pagination), (id) => (
            <Col key={id} span={24}>
              {eventListKeyByIds[id] && <EventItem event={eventListKeyByIds[id]} />}
            </Col>
          ))}
        </>
      )}
      <Col span={24} style={{ textAlign: "center" }}>
        <Pagination
          className="event-pagination"
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          hideOnSinglePage
          onChange={(value) => onPageChange({ ...pagination, current: value })}
        />
      </Col>
    </>
  );
};

const EventItem = ({ event }) => {
  const { company } = useSelector((state) => state.app);
  const { MEDIA_URL } = useSelector((state) => state.app.config);
  const { event_logo, event_key, event_type, event_name, event_session } =
    event;
  const { t } = useTranslation();
  const { event_start_date, event_end_date } = _.first(event_session) || {};

  const dateFormat = (type, time) => {
    let format = "DD-MM-YYYY HH:mm";
    switch (type) {
      case "date":
        format = "DD-MM-YYYY";
        break;
      case "time":
        format = "HH:mm";
        break;
      case "full_date":
      default:
        format = "DD-MM-YYYY HH:mm";
        break;
    }

    return time.format(format);
  };

  return (
    <Link to={{ pathname: `/${company.company_key}/event/${event_key}` }}>
      <Card
        style={{ ...styles.card, position: "relative", width: "100%" }}
        bodyStyle={{ padding: 16 }}
        cover={
          <img
            alt="event"
            src={`${MEDIA_URL}/${event_logo}`}
            style={{ ...styles.cardImage }}
          />
        }
      >
        <Space
          direction="vertical"
          style={{ position: "absolute", top: 16, right: 16 }}
        >
          <div
            style={{ ...styles.tag }}
          >
            {event_type === "public"
              ? t("public_event")
              : event_type === "private"
                ? t("private_event")
                : ""}
          </div>
          <div>
            {(event_start_date > moment().unix()) && (
              <div
                style={{ ...styles.tag, color: COLOR.text.captionText, background: "#E8E8E8" }}
              >
                {t("coming_soon")}
              </div>
            )}
          </div>
        </Space>
        <Space direction="vertical" size={4}>
          <div style={{ ...FONT.bodyText1Bold }}>{event_name}</div>
          {!_.isEmpty(event_session) && (
            <>
              <div style={{ color: COLOR.text.primaryText }}>{`${dateFormat("date", moment.unix(event_start_date))} to ${dateFormat("date", moment.unix(event_end_date))}`}</div>
              <div style={{ color: COLOR.text.primaryText }}>{`${t("start_at")} ${dateFormat("time", moment.unix(event_start_date))}`}</div>
              <div style={{ color: COLOR.text.captionText }}>{`$${_.min(_.map(event_session, "event_price"))} ${t("up")}`}</div>
            </>
          )}
        </Space>
      </Card>
    </Link>
  );
};

const styles = {
  mainTitle: {
    color: COLOR.text.primaryText,
    ...FONT.h4,
    textAlign: "center",
    margin: 0
  },
  categoryTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },
  desc: {
    color: COLOR.text.captionText,
    ...FONT.bodyText1Regular,
    marginTop: 12,
  },
  card: {
    borderRadius: 8,
    backgroundColor: COLOR.function.background,
    borderWidth: 0,
    width: "100%",
    height: "100%",
  },
  cardImage: {
    width: "100%",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundSize: "contain",
    objectFit: "contain",
    maxHeight: "50vh",
  },
  ghostBtn: {
    borderRadius: 8,
    width: "100%",
    padding: "8px 16px",
    height: 42,
    fontSize: 16,
    color: COLOR.text.primaryText,
    borderColor: COLOR.text.primaryText,
    marginTop: 16,
  },
  commonBtn: {
    borderRadius: 8,
    width: "100%",
    padding: "8px 16px",
    height: 42,
    fontSize: 16,
    border: "none",
    color: "#828282",
  },
  tag: {
    padding: "4px 8px",
    background: "#FFE0B4",
    borderRadius: 8,
  },
  loading: {
    minHeight: "60vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

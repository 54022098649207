import React, { useRef } from "react";
import { Col, Row } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";
import { COLOR, FONT } from "../../Theme";

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const BrandBanner = (props) => {
  const { config, companySiteMeta } = useSelector((state) => state.app);
  if (!_.isEmpty(companySiteMeta.brand_background)
    && !_.isEmpty(companySiteMeta.brand_title)
    && !_.isEmpty(companySiteMeta.brand_desc)
  ) {
    return (
      <Row
        span={24}
        style={{
          height: 350,
          marginTop: 50,
          backgroundImage: `url(${`${config.MEDIA_URL}/${companySiteMeta.brand_background}`})`,
          borderRadius: 40,
          backgroundColor: "rgba(0,0,0,0.5)",
          opacity: 2
        }}
        align="middle"
        justify="center"
      >
        <Col xs={24} sm={24} md={24} lg={16} style={{ textAlign: "center" }}>
          <span style={{ color: COLOR.warning.warning300, ...FONT.h5 }}>
            {companySiteMeta.brand_title}
          </span>
          <br />
          <span
            style={{ color: COLOR.text.whiteText, ...FONT.bodyText2Regular }}
          >
            {htmlToReactParser.parse(companySiteMeta.brand_desc)}
          </span>
        </Col>
      </Row>
    );
  }
};

export default BrandBanner;

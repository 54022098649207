import React from "react";
import { Col } from "antd";
import { COLOR } from "../../Theme";

const CardItem = ({ title, value }) => {
  const styles = {
    titleStyle: { color: COLOR.captionText, fontSize: 16 },
  };
  return (
    <Col span={24}>
      <div style={{ ...styles.titleStyle }}>
        {title}
      </div>
      <div>{value}</div>
    </Col>
  );
};

export default CardItem;

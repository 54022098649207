import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Progress,
  Radio,
  Row,
  Space,
  Upload,
  Grid,
  Collapse,
  notification,
} from "antd";
import React, { useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import {
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { useWindowWidth } from "@react-hook/window-size";
import { openNotificationWithIcon } from "../form/ErrorMessage";
import * as Service from "../../core/Service";
import { BREAKPOINT, COLOR } from "../../Theme";
import PaymentStatus from "./PaymentStatus";
import PayPalContainer from "./PaypalContainer";
import QFPayContainer from "./QFPayContainer";

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const PaymentMethodSection = ({ ...props }) => {
  const { t } = useTranslation();
  const elements = useElements();

  const app = useSelector((state) => state.app);
  const { company } = app;
  const { companyPaymentMethodList } = company;
  const { MEDIA_URL } = app.client.config;

  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [successFileList, setSuccessFileList] = useState("");

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;

  const styles = {
    paymentOption: {
      background: "#fff",
      marginBottom: 16,
      borderRadius: 8,
    },
    sectionWeb: {
      marginBottom: 24,
      marginTop: !isMobile && 0,
      borderRadius: !isMobile && 8,
    },
    paymentDesc: {
      background: "transparent",
      border: "none",
    },
  };

  return (
    <div className="section-card" style={{ ...styles.sectionWeb }}>
      {!isMobile && !_.isNil(paymentStatus) ? (
        <PaymentStatus status={paymentStatus} order_id={props?.order_id} setStatus={setPaymentStatus} />
      ) : (
        <>
          <Row justify="space-between" align="middle">
            <div className="section-title">{t("payment_method")}</div>
            {!isMobile && (
              <div style={{ color: COLOR.negative.negative500 }}>
                {t("pay_by")}
                {" "}
                {dayjs.unix(props.expiry_time).format("HH:mm:ss")}
              </div>
            )}
          </Row>

          <div style={{ marginTop: 8 }}>{t("payment_method_desc")}</div>
          {isMobile && (
            <div style={{ color: COLOR.negative.negative500, marginTop: 8 }}>
              {t("pay_by")}
              {" "}
              {dayjs.unix(props.expiry_time).format("HH:mm:ss")}
            </div>
          )}

          <Divider />
          <Radio.Group
            style={{ width: "100%" }}
            onChange={(e) => {
              setSelectedPaymentMethod(e.target.value);
              setSuccessFileList(null);
            }}
            value={selectedPaymentMethod}
          >
            {_.map(companyPaymentMethodList, (item, key) => (
              <div key={key} className="payment-option" style={{ ...styles.paymentOption }}>
                <Radio value={item}>
                  <Row justify="space-between">
                    <Col
                      style={{ display: "flex", alignSelf: "center" }}
                    >
                      {item.payment_name}
                    </Col>
                    <Col>
                      <img
                        src={`${MEDIA_URL}/${item?.payment_logo}`}
                        alt="payment-method-button"
                        style={{
                          maxWidth: 176,
                          maxHeight: 30,
                          objectFit: "cover"
                        }}
                      />
                    </Col>
                  </Row>
                </Radio>
                <Collapse style={{ ...styles.paymentDesc }}>
                  {item === selectedPaymentMethod && !isMobile && (
                    <div style={{ padding: "0 24px 24px" }}>
                      <PaymentForm
                        {...props}
                        payment={selectedPaymentMethod}
                        elements={elements}
                        paymentStatus={paymentStatus}
                        setPaymentStatus={setPaymentStatus}
                        setSubmitLoading={setSubmitLoading}
                        submitLoading={submitLoading}
                        successFileList={successFileList}
                        setSuccessFileList={setSuccessFileList}
                      />
                    </div>
                  )}
                </Collapse>
              </div>
            ))}
          </Radio.Group>
          {isMobile && (
            <Button
              type="primary"
              htmlType="submit"
              disabled={_.isNil(selectedPaymentMethod)}
              className="action-btn"
              style={{ marginTop: 16 }}
              onClick={() => {
                setPaymentModalVisible(true);
                setPaymentStatus(null);
                setSubmitLoading(false);
              }}
            >
              {t("next")}
            </Button>
          )}
          {isMobile && (
            <PaymentFormModal
              {...props}
              visible={paymentModalVisible}
              setVisible={setPaymentModalVisible}
              setSuccessFileList={setSuccessFileList}
              paymentStatus={paymentStatus}
            >
              <PaymentForm
                {...props}
                payment={selectedPaymentMethod}
                elements={elements}
                paymentStatus={paymentStatus}
                setPaymentStatus={setPaymentStatus}
                setSubmitLoading={setSubmitLoading}
                submitLoading={submitLoading}
                successFileList={successFileList}
                setSuccessFileList={setSuccessFileList}
              />
            </PaymentFormModal>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentMethodSection;

const PaymentForm = ({
  payment,
  paymentType,
  elements,
  useToken,
  token,
  balance,
  order_key,
  order_id,
  paymentStatus,
  setPaymentStatus,
  submitLoading,
  setSubmitLoading,
  successFileList,
  setSuccessFileList
}) => {
  const stripe = useStripe();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [cardHolderName, setCardHolderName] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [cardError, setCardError] = useState({});

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const app = useSelector((state) => state.app);
  const { company } = app;

  const checkPaymentStatus = async (ptx_key) => {
    setPaymentStatus("processing");
    let url = "/api/payment/check_complete";
    let result = await Service.call("get", url, { ptx_key });
    if (!result || result.status !== 1) {
      setPaymentStatus("fail");
    }
    if (result.data) {
      // payment status === complete
      setPaymentStatus("success");
    } else {
      // payment not yet finish
      setTimeout(() => {
        checkPaymentStatus(ptx_key);
      }, 3000);
    }
  };

  const confirmPayment = async (fileList) => {
    try {
      setSubmitLoading(true);
      let user_payment_type = 0;
      let token_used = 0;
      if (paymentType === "monthly-payment") {
        user_payment_type = 1;
      }
      let user_payment_method = [];

      if (useToken) {
        user_payment_method.push("token");
        token_used = token || 0;
      }
      if (!_.isEmpty(payment.payment_type)) {
        user_payment_method.push(payment.payment_type);
      }
      if (balance === 0 && token_used > 0) {
        user_payment_method = ["token"];
      }
      if (_.includes(user_payment_method, "stripe")) {
        if (!stripe || !elements || !cardComplete) {
          return openNotificationWithIcon(
            "error",
            t("error"),
            t("incorrect_credit_card_info")
          );
        }
      }
      const putObj = {
        order_key,
        user_payment_type,
        user_payment_method,
        token_used,
        company_payment_method_id: payment.company_payment_method_id || 0,
      };
      const resp = await Service.call("put", "/api/payment", putObj);
      if (!resp || resp.status !== 1) {
        // not enough token case
        if (resp?.errorCode === -60007) {
          setPaymentStatus("fail");
          return Modal.error({
            title: t("not_enough_token"),
            okButtonProps: {
              style: {
                borderColor: COLOR.brandSecondary,
                backgroundColor: COLOR.brandSecondary,
                color: COLOR.whiteSecondary,
              },
            },
          });
        }
        // fail case
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      if (_.includes(user_payment_method, "paypal")) {
        return resp.data;
      }
      if (_.includes(user_payment_method, "qf_pay")) {
        setPaymentStatus("processing");
        return resp.data;
      }
      if (_.includes(user_payment_method, "stripe")) {
        setPaymentStatus("processing");
        const {
          ptxObj: transaction,
          paymentIntent
        } = resp.data;

        if (!paymentIntent) {
          setPaymentStatus("fail");
          return;
        }

        const { client_secret } = paymentIntent;

        const cardElement = elements.getElement(CardNumberElement);

        if (!client_secret) {
          setPaymentStatus("fail");
          return;
        }

        try {
          console.log("before confirmCardPayment");
          const {
            error: stripeError,
            paymentIntent: payload,
          } = await stripe.confirmCardPayment(client_secret, {
            payment_method: {
              card: cardElement,
              // billing_details: {
              //   name: cardHolderName,
              // },
            },
            return_url: window.location.href
          }, { handleActions: false });
          console.log("after confirmCardPayment");

          if (payload?.next_action?.redirect_to_url?.url) {
            window.location.href = payload?.next_action?.redirect_to_url?.url;
          }

          if (!payload || payload.error || stripeError) {
            console.log("[error]", payload.error);
            setPaymentStatus("fail");
            return;
          }
          return checkPaymentStatus(transaction.ptx_key);
        } catch (err) {
          setPaymentStatus("fail");
          console.error(err);
        }
      }
      if (_.includes(user_payment_method, "offline")) {
        try {
          setSubmitLoading(true);
          const patchObj = {
            order_key: order_key || "",
            filepath: fileList.response.filepath,
          };
          const resp = await Service.call(
            "patch",
            "/api/order/offline",
            patchObj
          );
          if (resp?.status === 1) {
            setPaymentStatus("pending");
            return;
          }
          // fail case
        } catch (err) {
          setPaymentStatus("fail");
          console.error(err);
        } finally {
          setSubmitLoading(false);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitLoading(false);
    }
  };

  const onChangeCardElement = (evt) => {
    setCardComplete({ ...cardComplete, [evt.elementType]: evt.complete });
    setCardError({ ...cardError, [evt.elementType]: evt.error?.message });
  };

  const [paypalDetail] = _.filter(company.companyPaymentMethodList, (rc) => rc.payment_type === "paypal");

  return (
    <div>
      {!_.isNil(paymentStatus) && isMobile ? (
        <PaymentStatus status={paymentStatus} order_id={order_id} setStatus={setPaymentStatus} />
      ) : (
        <>
          {isMobile && (
            <div style={{ ...styles.paymentName }}>{payment?.payment_name}</div>
          )}
          {!_.isEmpty(payment)
            && payment?.payment_type === "stripe"
            && paymentType === "pre-payment"
            && stripe && (
              <StripePaymentForm
                form={form}
                payment={payment}
                confirmPayment={confirmPayment}
                onChangeCardElement={onChangeCardElement}
                cardHolderName={cardHolderName}
                setCardHolderName={setCardHolderName}
                cardComplete={cardComplete}
                submitLoading={submitLoading}
                isMobile={isMobile}
                cardError={cardError}
                isAgree={isAgree}
                setIsAgree={setIsAgree}
              />
            )}
          {!_.isEmpty(payment)
            && payment?.payment_type !== "stripe"
            && payment?.payment_type !== "paypal"
            && payment?.payment_type !== "qf_pay"
            && paymentType === "pre-payment" && (
              <OfflinePaymentUpload
                onFinish={confirmPayment}
                payment={payment}
                successFileList={successFileList}
                setSuccessFileList={setSuccessFileList}
                submitLoading={submitLoading}
                isMobile={isMobile}
              />
            )}
          {!_.isEmpty(payment)
            && payment?.payment_type === "paypal"
            && paymentType === "pre-payment" && (
              <PayPalScriptProvider
                options={{
                  "client-id": paypalDetail.api_key,
                  components: "buttons",
                  currency: "HKD",
                  intent: "authorize"
                }}
              >
                <PayPalContainer
                  confirmPayment={confirmPayment}
                  setPaymentStatus={setPaymentStatus}
                  isMobile={isMobile}
                />
              </PayPalScriptProvider>
            )}
          {!_.isEmpty(payment)
            && payment?.payment_type === "qf_pay"
            && paymentType === "pre-payment" && (
              <QFPayContainer
                submitLoading={submitLoading}
                confirmPayment={confirmPayment}
                isMobile={isMobile}
              />
            )}
        </>
      )}
    </div>
  );
};

const PaymentFormModal = ({
  visible,
  setVisible,
  children,
  setSuccessFileList,
  paymentStatus,
}) => {
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setSuccessFileList("");
        setVisible(false);
      }}
      maskClosable={false}
      footer={false}
      closable={_.isNil(paymentStatus)}
      centered
    >
      {children}
    </Modal>
  );
};

const OfflinePaymentUpload = ({
  onFinish,
  payment,
  successFileList,
  setSuccessFileList,
  submitLoading,
  isMobile,
}) => {
  const { Dragger } = Upload;
  const [isAgree, setIsAgree] = useState(false);
  const app = useSelector((state) => state.app);
  const { STATIC_SERVER_URL, MEDIA_URL, API_URL } = app.client.config;
  const { company, userSession } = app;
  const { access_token } = userSession;
  const { t } = useTranslation();

  const props = {
    name: "file",
    showUploadList: false,
    action:
      payment.payment_type === "offline"
        ? `${API_URL}/api/payment/upload`
        : `${API_URL}/api/media`,
    headers: { "x-access-token": access_token },
    style: {
      minHeight: !isMobile && 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    beforeUpload: (file) => {
      const isLt1M = file.size / 1024 / 1024 < 1;
      const acceptMimeTypes = ["image/png", "image/jpeg"];
      if (!isLt1M) {
        message.error("圖片必須小於 1MB");
        return false;
      }
      if (!_.includes(acceptMimeTypes, file.type)) {
        message.error("只能上傳 JPG 或 PNG");
        return false;
      }
      return true;
    },
    onChange: (info) => {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file);
      }

      if (status === "done") {
        setSuccessFileList(info.file);
      } else if (status === "error" || status === "removed") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const itemRender = (file) => (
    <Row align="middle" style={{ color: COLOR.captionText, margin: "8px 0" }}>
      <Col flex={1} style={{ marginRight: 16 }}>
        <Row justify="space-between">
          <Col>{file.name}</Col>
          <Col>
            {file.status === "done"
              ? file.size / 1000 / 1024 >= 1
                ? `${(file.size / 1000 / 1024).toFixed(0)} MB`
                : `${(file.size / 1000).toFixed(0)} KB`
              : `${file.percent}%`}
          </Col>
        </Row>
        {file.status === "done" ? (
          ""
        ) : (
          <Progress
            percent={file.percent}
            showInfo={false}
            strokeColor={COLOR.brandPrimary}
            trailColor={COLOR.brandPrimary}
          />
        )}
      </Col>
      <Col>
        <Button
          type="primary"
          ghost
          onClick={() => setSuccessFileList("")}
          style={{ borderRadius: 8 }}
        >
          {t("cancel")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <div className="bank-transfer-form">
      {payment?.payment_img && (
        <div style={{ ...styles.paymentImg }}>
          <img
            width="100%"
            src={`${MEDIA_URL}/${payment?.payment_img}`}
            alt="payment-img"
            style={{ objectFit: "contain" }}
          />
        </div>
      )}
      <div style={{ margin: isMobile ? "24px 0" : "8px 0 24px" }}>
        {htmlToReactParser.parse(payment?.payment_desc)}
      </div>
      <Dragger {...props}>
        <p style={{ marginBottom: 14 }}>
          <Image
            src="/payment/upload.png"
            preview={false}
            width={60}
            height={50}
            alt=""
          />
        </p>
        <p className="ant-upload-text" style={{ color: COLOR.captionText }}>
          {t("drag_n_drop")}
        </p>
        <p className="ant-upload-hint">Max. File Size : 1MB</p>
      </Dragger>
      {successFileList && (
        <div style={{ color: COLOR.captionText, margin: "12px 0" }}>
          {successFileList.status === "done"
            ? t("uploaded")
            : `0 of 1 uploaded`}
          {successFileList && itemRender(successFileList)}
        </div>
      )}

      <div style={{ margin: "24px 0" }}>
        <Checkbox
          checked={isAgree}
          style={{ color: COLOR.captionText }}
          onChange={() => setIsAgree(!isAgree)}
        >
          {t("i_agree")}
          {" "}
          <a
            href={`${STATIC_SERVER_URL}/${company.company_key}/terms/conditions`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            style={{ color: COLOR.brandPrimary }}
          >
            {t("t_of_s_agreement")}
          </a>
        </Checkbox>
      </div>
      <div style={{ textAlign: "right" }}>
        <Button
          type="primary"
          loading={submitLoading}
          disabled={!isAgree || _.isEmpty(successFileList)}
          className="action-btn"
          style={{ width: isMobile ? "100%" : "fit-content", margin: 0 }}
          onClick={() => onFinish(successFileList)}
        >
          {t("submit")}
        </Button>
      </div>
    </div>
  );
};

const StripePaymentForm = ({
  form,
  confirmPayment,
  payment,
  onChangeCardElement,
  cardHolderName,
  setCardHolderName,
  cardComplete,
  submitLoading,
  isMobile,
  cardError,
  isAgree,
  setIsAgree
}) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const { STATIC_SERVER_URL, MEDIA_URL } = app.client.config;
  const { company } = app;
  return (
    <Form
      form={form}
      layout="vertical"
      name="form"
      className="credit-card-form"
    >
      <Row gutter={[16, 8]}>
        {payment?.payment_logo && (
          <Col span={24} style={{ margin: "12px 0" }}>
            <img
              key={payment?.payment_logo}
              src={`${MEDIA_URL}/${payment?.payment_logo}`}
              alt="payment_logo"
              style={{
                maxWidth: 176,
                maxHeight: 30,
                objectFit: "contain"
              }}
            />
          </Col>
        )}
        <Col span={24}>
          <Form.Item
            label={t("card_number")}
            rules={[{ required: true, message: t("valid_card_no_message") }]}
          >
            <div
              className="ant-input"
              style={{ ...styles.cardElementContainer }}
            >
              <CardNumberElement
                options={{
                  style: styles.cardElement,
                  placeholder: `${t("input_card_number")}`,
                }}
                onChange={onChangeCardElement}
              />
            </div>
            {cardError.cardNumber && (
              <div style={{ ...styles.cardError }}>{cardError.cardNumber}</div>
            )}
          </Form.Item>
        </Col>
        {/* <Col span={24}>
          <Form.Item
            label={t("name_on_card")}
            rules={[{ required: true, message: t("valid_name_message") }]}
          >
            <Input
              placeholder={t("card_holder_name_placeholder")}
              onChange={(evt) => setCardHolderName(evt.target.value)}
              style={{ ...styles.cardInput }}
            />
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item
            label={t("expiration_date")}
            rules={[{ required: true, message: t("expire_date_message") }]}
          >
            <div style={{ ...styles.cardElementContainer }}>
              <CardExpiryElement
                options={{
                  style: styles.cardElement,
                  placeholder: "MM/YY",
                }}
                onChange={onChangeCardElement}
              />
            </div>
            {cardError.cardExpiry && (
              <div style={{ ...styles.cardError }}>{cardError.cardExpiry}</div>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("security_code")}
            rules={[{ required: true, message: t("security_code_message") }]}
          >
            <div style={{ ...styles.cardElementContainer }}>
              <CardCvcElement
                options={{
                  style: styles.cardElement,
                  placeholder: "XXX",
                }}
                onChange={onChangeCardElement}
              />
            </div>
            {cardError.cardCvc && (
              <div style={{ ...styles.cardError }}>{cardError.cardCvc}</div>
            )}
          </Form.Item>
        </Col>
      </Row>
      {isMobile && <div style={{ ...styles.emptyBox }} />}
      <Form.Item
        name="is_read"
        required
        valuePropName="checked"
        style={{ padding: !isMobile && "12px 0" }}
      >
        <Checkbox
          style={{ color: COLOR.captionText }}
          value={isAgree}
          onChange={(e) => setIsAgree(e.target.checked)}
        >
          <Space size={4}>
            {t("i_agree")}
            <a
              href={`${STATIC_SERVER_URL}/${company.company_key}/terms/conditions`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
              style={{ color: COLOR.brandPrimary }}
            >
              {t("t_of_s_agreement")}
            </a>
          </Space>
        </Checkbox>
      </Form.Item>
      <div style={{ textAlign: "right" }}>
        <Button
          type="primary"
          loading={submitLoading}
          disabled={
            !cardComplete.cardNumber
            || !cardComplete.cardExpiry
            || !cardComplete.cardCvc
            || !isAgree
          }
          className="action-btn"
          style={{ width: isMobile ? "100%" : "fit-content" }}
          onClick={(event) => {
            event.preventDefault();
            return confirmPayment();
          }}
        >
          {t("pay_now")}
        </Button>
      </div>
    </Form>
  );
};

const styles = {
  paymentName: {
    color: "#090909",
    fontSize: 28,
    fontWeight: "bold",
    marginTop: 16,
  },
  cardElement: {
    base: {
      backgroundColor: "#FBF9F9",
      color: COLOR.captionText,
      lineHeight: "45px",
      "::placeholder": {
        color: "#BBBBBB",
      },
    },
  },
  cardElementContainer: {
    backgroundColor: "#FBF9F9",
    padding: "0 12px",
    borderRadius: 8,
  },
  cardTypeIcon: {
    position: "absolute",
    top: "50%",
    right: 12,
    transform: "translateY(-50%)",
    width: 22,
    height: 16,
  },
  iconButtonList: {
    height: "fit-content",
    border: "none",
    padding: 8,
  },
  paymentImg: {
    display: "flex",
    justifyContent: "center",
    margin: "24px 0",
  },
  cardError: {
    color: "#ec0303",
    fontSize: 12,
  },
  cardInput: {
    height: "45px",
    lineHeight: "45px",
    fontWeight: 500,
  },
  emptyBox: {
    height: 160,
    width: "100%",
  },
};

import React, { useRef } from "react";
import { Col } from "antd";
import _ from "lodash";
import BlogItem from "./BlogItem";

const BlogList = (props) => {
  const { blogList } = props;

  return _.map(_.slice(blogList, 0, 3), (eachBlog, index) => {
    return (
      <Col
        key={index}
        xs={24}
        sm={24}
        md={24}
        lg={8}
      >
        <BlogItem blogItemRc={eachBlog} />
      </Col>
    );
  });
};

export default BlogList;

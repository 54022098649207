import React, { useEffect, useState } from "react";
import {
  Button
} from "antd";
import { useTranslation } from "react-i18next";

const QFPayContainer = (props) => {
  const { t } = useTranslation();
  const {
    confirmPayment,
    submitLoading,
    isMobile
  } = props;

  const onFinish = async () => {
    try {
      const checkoutUrl = await confirmPayment();
      // console.log("checkoutUrl >>>", checkoutUrl);
      if (isMobile) {
        return window.location.replace(checkoutUrl);
      }
      return window.open(checkoutUrl);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ textAlign: "right" }}>
      <Button
        type="primary"
        loading={submitLoading}
        className="action-btn"
        style={{
          width: isMobile ? "100%" : "fit-content",
          marginTop: isMobile ? 20 : "0",
          padding: 10,
          height: 40,
        }}
        onClick={(event) => {
          event.preventDefault();
          return onFinish();
        }}
      >
        {t("pay_now")}
      </Button>
    </div>
  );
};

export default QFPayContainer;

import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import * as Service from "../core/Service";

const UserQRCode = (props) => {
  const [qrCodeImg, setQrCodeImg] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUserQrCode();
  }, [props]);

  const getUserQrCode = async () => {
    setLoading(true);
    try {
      const resp = await Service.call(
        "get",
        "/api/user/profile/qrcode",
        {},
        { responseType: "arraybuffer" }
      );
      if (resp) {
        let base64ImageString = Buffer.from(resp, "binary").toString("base64");
        let srcValue = "data:image/png;base64," + base64ImageString;
        setQrCodeImg(srcValue);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div style={{ width: "100%", textAlign: "center" }}>
        {qrCodeImg && <img src={qrCodeImg} alt="user_qr_code" style={{ width: "100%" }} />}
      </div>
    </Spin>
  );
};

export default UserQRCode;

export const SET_ADMIN = "SET_ADMIN";
export const SET_COMPANY_ADMIN = "SET_COMPANY_ADMIN";
export const SET_COMPANY = "SET_COMPANY";
export const SET_AUTH = "SET_AUTH";
export const SET_ORIGIN = "SET_ORIGIN";
export const SET_CONFIG = "SET_CONFIG";
export const SET_LOADING = "SET_LOADING";
export const SET_LOGOUTWAY = "SET_LOGOUTWAY";

export const SET_ERROR_REDIRECT = "SET_ERROR_REDIRECT";

export const APP_DATA = "APP_DATA";
export const SET_CLIENT_DATA = "SET_CLIENT_DATA";
export const SET_USER = "SET_USER";
export const GET_SESSION = "GET_SESSION";

export const SET_APPLICATION_KEY = "SET_APPLICATION_KEY";

export const SET_ADMIN_WEBCAST_EVENT_ID = "SET_ADMIN_WEBCAST";

export const SET_WEBCAST_EVENT = "SET_WEBCAST_EVENT";
export const SET_WEBCAST_REGISTRATION = "SET_WEBCAT_REGISTRATION";
export const SET_WEBCAST_SLIDES = "SET_WEBCAST_SLIDES";
export const SET_WEBCAST_HANDOUTS = "SET_WEBCAST_HANDOUTS";
export const SET_WEBCAST_LIVE_STATUS = "SET_WEBCAST_LIVE_STATUS";

export const SET_DRAWER = "SET_DRAWER";

export const SET_RENT_FORM = "SET_RENT_FORM";

export const SET_SM_SITES = "SET_SM_SITES";

export const SET_ITEM_CATEGORY = "SET_ITEM_CATEGORY";
export const SET_ITEM = "SET_ITEM";
export const SET_SELECTED_ITEM_CATEGORY = "SET_SELECTED_ITEM_CATEGORY";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_SELECTED_BOOKING = "SET_SELECTED_BOOKING";
export const SET_BOOKING = "SET_BOOKING";
export const SET_HOLIDAY = "SET_HOLIDAY";
export const SET_RULE_SET = "SET_RULE_SET";
export const SET_USER_SESSION = "SET_USER_SESSION";
export const SET_BOOKING_PURPOSE = "SET_BOOKING_PURPOSE";
export const RESET_ORDER = "RESET_ORDER";
export const SET_ORDER_INFO = "SET_ORDER_INFO";
export const SET_ORDER_ITEM_LIST = "SET_ORDER_ITEM_LIST";
export const SET_SELECTED_CAPACITY = "SET_SELECTED_CAPACITY";
export const SET_SHOPPING_CART_AMOUNT = "SET_SHOPPING_CART_AMOUNT";
export const SET_SHOPPING_CART_STATUS = "SET_SHOPPING__CART_STATUS";
export const SET_APP_MODAL = "SET_APP_MODAL";
export const SET_COMPANY_SITE_META = "SET_COMPANY_SITE_META";
export const SET_QR_CODE_VISIBLE = "SET_QR_CODE_VISIBLE";

import React, { Component } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Layout,
  Spin,
  Divider,
  PageHeader,
  Row,
  Col,
  Grid
} from "antd";
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import DirectBar from "./DirectBar";
import LoadingIcon from "./LoadingIcon";
import TNContent from "../TNCore/TNContent";
import AppModal from "./AppModal";
import QRCodeModal from "./QRCodeModal";
import UserQRCode from "./UserQRCode";
import { setQRCodeVisible } from "../redux/actions/common";
import { BREAKPOINT } from "../Theme";

const { Content } = Layout;

const AppLayout = (props) => {
  const {
    children,
    noSidebar,
    title,
    errorRedirect,
    backgroundStyle,
    containerStyle,
    className,
    childrenFullWidth,
  } = props;
  const app = useSelector((state) => state.app);
  const { userQRCodeVisible, loading } = app;
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const { t } = useTranslation();
  // console.log("this.props >>>", this.props)

  if (errorRedirect) {
    return <Redirect to="/404" />;
  }

  return (
    // <Elements stripe={stripePromise} options={{ locale: 'en' }}>
    <Layout>
      <TNContent fullWidth>
        <Row justify="start" style={{ ...backgroundStyle }}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div style={{
              minHeight: "100vh",
              margin: "auto",
              // padding: isMobile ? '0 20px' : 0,
              paddingTop: !isMobile && 20,
              ...containerStyle
            }}
            >
              <AppHeader />
              <DirectBar />
              <Row>
                <Col span={24}>
                  <TNContent className={className} fullWidth={childrenFullWidth}>
                    {loading ? (
                      <div
                        style={{
                          minHeight: "70vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin spinning indicator={<LoadingIcon />} />
                      </div>
                    ) : (
                      <div style={{ minHeight: "70vh" }}>{children}</div>
                    )}
                  </TNContent>
                </Col>
              </Row>
            </div>
            <AppFooter />
          </Col>
        </Row>
        <AppModal />
        <QRCodeModal
          title={t("access_qr_code")}
          visible={userQRCodeVisible}
          onClose={() => dispatch(setQRCodeVisible(false))}
          footer={null}
        >
          <UserQRCode />
        </QRCodeModal>
      </TNContent>
    </Layout>
    // </Elements>
  );
};

export default AppLayout;

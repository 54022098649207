import React, { Component, useState, useEffect } from "react";
import {
  Layout, Menu, Avatar, Modal, Row, Col, Button, Grid
} from "antd";
import "antd/dist/antd.min.css";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { ActionCreators } from "../redux/actions";
import logo from "../assets/Logo_White.png";
import ShoppingCart from "./ShoppingCart";
import ShoppingCartDrawer from "./ShoppingCartDrawer";
import { setAppModal, setShoppingCartStatus } from "../redux/actions/common";
import CompanyLogo from "./CompanyLogo";
import { COLOR, FONT } from "../Theme";
import * as Main from "../core/Main";

const { Header } = Layout;

const Navbar = (props) => {
  const router = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const [visible, setVisible] = useState(false);
  const {
    auth,
    user,
    client,
    shoppingCartStatus,
    company
  } = useSelector((state) => state.app);

  const {
    allow_register,
    allow_guest_checkout
  } = company;
  useEffect(() => {
    if (shoppingCartStatus > 0) {
      setVisible(true);
    }
  });

  // const showDrawer = () => {
  //   if (user?.company_user_id) {
  //     return setVisible(true);
  //   }
  //   router.push('login')
  // };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    // <Header className="header" >
    <Row
      justify="space-between"
      align="middle"
      style={{
        background: "none",
        margin: "10px 0",
      }}
    >
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={12}
      >
        <CompanyLogo customStyle={{ maxWidth: 200, marginLeft: "8%" }} width="360vw" />
      </Col>
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={12}
        style={{
          textAlign: "right",
          paddingRight: "4%",
        }}
      >
        <Button
          style={{
            backgroundColor: "transparent",
            color: i18n.language === "zh-HK"
              ? COLOR.text.primaryText : COLOR.function.disable,
            // padding: '0px 40px',
            height: 50,
            marginBottom: "10px",
            borderRadius: 0,
            borderWidth: "2px",
            border: "none",
            borderColor: "#9E8677",
            fontSize: "14px",
            fontWeight: 400
          }}
          onClick={() => {
            // console.log("i18n.language >>>", i18n.language);
            i18n.changeLanguage("zh-HK");
          }}
        >
          中文
        </Button>
        <Button
          style={{
            backgroundColor: "transparent",
            color: i18n.language === "en"
              ? COLOR.text.primaryText : COLOR.function.disable,
            // padding: "0px 40px",
            height: 50,
            marginBottom: "10px",
            borderRadius: 0,
            borderWidth: "2px",
            border: "none",
            borderColor: "#9E8677",
            fontSize: "14px",
            fontWeight: 400
          }}
          onClick={() => {
            i18n.changeLanguage("en");
          }}
        >
          ENG
        </Button>
        {auth && !_.isEmpty(user) && (
          <Button
            style={{
              backgroundColor: "transparent",
              height: 50,
              border: "none",
              ...FONT.textMedium,
              color: COLOR.text.captionText,
            }}
            onClick={() => {
              if (company?.allow_guest_checkout) {
                return;
              }
              router.push(`/${company.company_key}/profile`);
            }}
          >
            {t("hi")}
            ,
            {" "}
            {!_.isEmpty(user?.nickname)
              ? user?.nickname
              : (user?.first_name === user?.last_name
                ? user?.first_name
                : `${user?.first_name} ${user?.last_name}`)}

          </Button>
        )}

        {!allow_guest_checkout
          ? auth && !user.is_guest
            ? (
              <Button
                style={{
                  backgroundColor: "transparent",
                  height: 50,
                  border: "none",
                  ...FONT.textMedium,
                  color: COLOR.text.primaryText,
                }}
                onClick={() => {
                  Main.logout(router, company.company_key);
                }}
              >
                {t("logout")}
              </Button>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "transparent",
                    ...FONT.textMedium,
                    color: COLOR.text.captionText,
                    border: "none",
                  }}
                  onClick={() => {
                    dispatch(setAppModal({
                      appModalVisible: true,
                      type: Main.FORM_TYPE.LOGIN
                    }));
                  }}
                >
                  {t("login")}
                </Button>
                {Boolean(allow_register) && (
                  <Button
                    style={{
                      backgroundColor: COLOR.primary.primary500,
                      color: COLOR.text.whiteText,
                      ...FONT.textMedium,
                      height: 50,
                      marginBottom: "10px",
                      borderRadius: 8,
                      borderWidth: "2px",
                      borderColor: COLOR.primary.primary500
                    }}
                    onClick={() => {
                      dispatch(setAppModal({
                        appModalVisible: true,
                        type: Main.FORM_TYPE.REGISTER
                      }));
                    }}
                  >
                    {t("sign_up")}
                  </Button>
                )}
              </>
            ) : null}
      </Col>
    </Row>

  // </Header>
  );
};

export default Navbar;

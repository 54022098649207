import React, { useState } from "react";
import { Row, Col, Button, Divider, Image } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { FONT, COLOR } from "../../Theme";
import { Decimal } from "decimal.js";

const ShoppingCartDesktopView = ({
  shoppingCartInfo,
  onDelete,
  history,
  onCheckout,
}) => {
  const { shoppingCartRc, shoppingCartItemList } = shoppingCartInfo;

  return (
    <div style={{ padding: "1.5% 4%" }}>
      {!_.isEmpty(shoppingCartItemList) ? (
        <ShoppingCartItemList
          shoppingCartItemList={shoppingCartItemList}
          shoppingCartRc={shoppingCartRc}
          onCheckout={onCheckout}
          onDelete={onDelete}
        />
      ) : (
        <EmptyShoppingCart history={history} />
      )}
    </div>
  );
};

export default ShoppingCartDesktopView;

const ShoppingCartItemList = ({
  shoppingCartItemList,
  shoppingCartRc,
  onCheckout,
  onDelete,
}) => {
  return (
    <Row gutter={24}>
      <Col span={16}>
        <Row gutter={[0, 24]}>
          {_.map(shoppingCartItemList, (cartItem) => (
            <Col span={24} key={cartItem.item_id}>
              <ShoppingCartItem cartItem={cartItem} onDelete={onDelete} />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={8}>
        <ShoppingCartSummary
          shoppingCartRc={shoppingCartRc}
          shoppingCartItemList={shoppingCartItemList}
          onCheckout={onCheckout}
        />
      </Col>
    </Row>
  );
};

const EmptyShoppingCart = ({ history }) => {
  const { t } = useTranslation();
  const { company, client } = useSelector((state) => state.app);
  const { STATIC_SERVER_URL } = client.config;
  return (
    <div style={{ minHeight: 600, padding: "0 24px" }}>
      <div
        style={{
          ...FONT.h4,
          color: COLOR.text.primaryText,
          padding: "16px 0",
        }}
      >
        {t("shopping_cart")}
      </div>
      <Row justify="center" gutter={[0, 30]}>
        <Col span={24} align="middle" style={{ marginTop: 100 }}>
          <img
            src={`${STATIC_SERVER_URL}/shopping_cart_white.svg`}
            width="40"
            alt="empty_shopping_cart"
            style={{
              color: COLOR.function.disable,
            }}
          />
        </Col>
        <Col
          span={24}
          align="middle"
          style={{
            ...FONT.bodyText1Regular,
            color: COLOR.text.captionText,
          }}
        >
          {t("shopping_cart_is_empty")}
        </Col>
        <Col span={24} align="middle">
          <Button
            style={{
              width: "100%",
              borderRadius: 8,
              color: COLOR.text.whiteText,
              backgroundColor: COLOR.primary.primary500,
              height: 48,
            }}
            onClick={() => history.push(`/${company.company_key}/booking`)}
          >
            {t("continue_shopping")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const ListItem = ({ title, value, titleStyle = {}, valueStyle = {} }) => {
  const defaultStyles = {
    title: {
      ...FONT.bodyText1Regular,
      color: COLOR.text.captionText,
    },
    value: {
      ...FONT.bodyText1Regular,
    },
  };
  return (
    <Row justify="space-between">
      <Col style={{ ...defaultStyles.title, ...titleStyle }}>{title}</Col>
      <Col style={{ ...defaultStyles.value, ...valueStyle }}>{value}</Col>
    </Row>
  );
};

const ShoppingCartItem = ({ cartItem, onDelete }) => {
  const {
    item_img,
    item_name,
    start_time,
    end_time,
    currency,
    childItems,
    final_price,
    childItemsFinalPrice,
    shopping_cart_item_id,
  } = cartItem;

  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const { STATIC_SERVER_URL, MEDIA_URL } = app.client.config;

  const renderChildItems = () => {
    return (
      <div style={{ textAlign: "right" }}>
        {_.map(childItems, (item) => {
          const {
            item_id,
            final_price: item_price,
            item_name: name,
            quantity,
          } = item;
          return (
            <div key={item_id}>{`+$${item_price} ${name} x ${quantity}`}</div>
          );
        })}
      </div>
    );
  };
  return (
    <div
      style={{
        background: COLOR.function.background,
        borderRadius: 8,
        padding: 24,
      }}
    >
      <Row gutter={[{ md: 20, lg: 28 }, 24]}>
        <Col sm={24} md={12}>
          <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Image
              src={`${MEDIA_URL}/${item_img}`}
              alt="image"
              style={{ width: "100%", borderRadius: 8 }}
              preview={false}
            />
          </div>
        </Col>
        <Col sm={24} md={12}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <ListItem title={`${t("item")}:`} value={item_name} />
            </Col>
            <Col span={24}>
              <ListItem
                title={`${t("date")}:`}
                value={moment.unix(start_time).format("DD-MM-YYYY")}
              />
            </Col>
            <Col span={24}>
              <ListItem
                title={`${t("time")}:`}
                value={`${moment.unix(start_time).format("HH:mm")} - ${moment
                  .unix(end_time)
                  .format("HH:mm")}`}
              />
            </Col>
            {!_.isEmpty(childItems) && (
              <Col span={24}>
                <ListItem title={t("rentals")} value={renderChildItems()} />
              </Col>
            )}
            <Col span={24}>
              <ListItem
                title={`${t("total")}:`}
                value={`${_.toUpper(currency)}$${new Decimal(final_price)
                  .add(childItemsFinalPrice)
                  .toNumber()}`}
                valueStyle={{ fontWeight: "bold" }}
              />
            </Col>
            <Divider style={{ margin: 0 }} />
            <Col span={24}>
              <ListItem
                value={
                  <Image
                    src={`${STATIC_SERVER_URL}/trash.svg`}
                    preview={false}
                    alt=""
                    width={20}
                    height={20}
                    onClick={() => {
                      onDelete(shopping_cart_item_id);
                    }}
                  />
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const ShoppingCartSummary = ({
  shoppingCartRc,
  shoppingCartItemList,
  onCheckout,
}) => {
  const { final_price, original_price, totalDiscount } = shoppingCartRc;
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState(false);

  const itemCount = shoppingCartItemList?.length;
  const rentalCount = _.reduce(
    shoppingCartItemList,
    (prev, acc) => prev + acc.childItems.length,
    0
  );
  const itemTotal = _.reduce(
    shoppingCartItemList,
    (prev, acc) => prev + acc.final_price,
    0
  );
  const rentalTotal = _.reduce(
    shoppingCartItemList,
    (prev, acc) => prev + acc.childItemsFinalPrice,
    0
  );

  const currency = _.toUpper(_.first(shoppingCartItemList).currency);

  return (
    <div
      style={{
        padding: 24,
        borderRadius: 8,
        background: COLOR.function.background,
      }}
    >
      <div
        style={{ ...FONT.h4, color: COLOR.text.primaryText, marginBottom: 16 }}
      >
        {t("shopping_cart")}
      </div>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <ListItem
            title={`${t("items")} (${itemCount}):`}
            value={`$${itemTotal}`}
          />
        </Col>
        <Col span={24}>
          <ListItem
            title={`${t("rentals_items")} (${rentalCount || 0}):`}
            value={`$${rentalTotal}`}
          />
        </Col>
        <Divider style={{ margin: "8px 0" }} />
        <Col span={24}>
          <ListItem title={`${t("subtotal")}:`} value={`$${original_price}`} />
        </Col>
        <Col span={24}>
          <ListItem title={`${t("discount")}:`} value={`$${totalDiscount}`} />
        </Col>
        <Col span={24}>
          <ListItem
            title={`${t("order_total")} (${itemCount + rentalCount} ${t(
              "items"
            )}):`}
            value={`${currency}$${final_price}`}
            valueStyle={{
              ...FONT.bodyText1Bold,
              color: COLOR.text.primaryText,
            }}
          />
        </Col>
      </Row>
      <Button
        loading={buttonLoading}
        style={{
          width: "100%",
          marginTop: 20,
          borderRadius: 8,
          height: 48,
          borderColor: COLOR.text.primaryText,
          backgroundColor: COLOR.primary.primary500,
          color: COLOR.whiteText,
        }}
        onClick={async () => {
          setButtonLoading(true);
          try {
            await onCheckout();
          } catch (error) {
            console.error(error);
          } finally {
            setButtonLoading(false);
          }
        }}
      >
        {t("checkout")}
      </Button>
    </div>
  );
};

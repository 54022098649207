import React, { useEffect, useState } from "react";
import {
  Row, Col, Grid, Button, Spin
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useWindowWidth } from "@react-hook/window-size";
import * as Service from "../core/Service";
import AppLayout from "../components/AppLayout";
import { FONT, COLOR, BREAKPOINT } from "../Theme";
import { setLoading } from "../redux/actions/common";
import BlogItem from "../components/Landing/BlogItem";
import { useHistory } from "react-router-dom";

const NewsListPage = () => {
  const [dataList, setDataList] = useState({
    blogPostHeader: {},
    newsList: [],
    newsCategoryList: [],
  });
  const [filterList, setFilterList] = useState([]);
  const {
    company,
    config,
    companySiteMeta
  } = useSelector((state) => state.app);

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < BREAKPOINT.lg;
  const [selectedCategory, setSelectedCategory] = useState(0);

  useEffect(() => {
    if (company.company_id) {
      getCategoryList();
    }
  }, [company]);

  useEffect(() => {
    onFilter(selectedCategory);
  }, [selectedCategory]);

  const getCategoryList = async () => {
    dispatch(setLoading(true));
    try {
      const resp = await Service.call("get", "/api/company/news");
      if (resp.status === 1) {
        const { newsArr, newsCategoryArr } = resp.data;
        if (_.isEmpty(newsArr)) return history.replace(`/${company.company_key}`);
        const keyByNewsCategory = _.keyBy(newsCategoryArr, "news_category_id");
        const newsList = _.map(newsArr, (eachBlogPost) => {
          const categoryObj = keyByNewsCategory[eachBlogPost.type_category_id] || {};
          return {
            ...categoryObj,
            ...eachBlogPost,
          };
        });
        setDataList({
          blogPostHeader: {
            banner: companySiteMeta.news_banner,
          },
          newsList,
          newsCategoryList: newsCategoryArr,
        });
        setFilterList(newsList);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onFilter = (category) => {
    if (category === 0) {
      return setFilterList(dataList.newsList);
    }
    return setFilterList(
      _.filter(dataList.newsList, { type_category_id: category })
    );
  };

  return (
    <AppLayout>
      {!_.isEmpty(dataList.blogPostHeader) && (
        <div style={{ position: "relative" }}>
          <Row
            span={24}
            style={{
              backgroundImage: `url(${`${config.MEDIA_URL}/${dataList.blogPostHeader.banner}`})`,
              backgroundColor: "rgba(0,0,0,0.5)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "darken",
              backgroundPosition: "center",
              opacity: 2,
              width: "100%",
              height: "300px",
            }}
            align="middle"
            justify="center"
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
              style={{ textAlign: "center" }}
            >
              <span
                style={{
                  color: COLOR.warning.warning300,
                  ...FONT.h3,
                }}
              >
                {t("news")}
              </span>
              <br />
              <span
                style={{
                  color: COLOR.text.whiteText,
                  ...FONT.h3,
                }}
              >
                {companySiteMeta[`news_title_${i18n.language}`]}
              </span>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              bottom: -1,
              width: "100%",
              backgroundColor: "white",
              height: 30,
              borderTopRightRadius: 45,
              borderTopLeftRadius: 45,
            }}
          />
        </div>
      )}
      <div style={{ padding: "2% 4%" }}>
        <Row
          style={{ marginBottom: "20px" }}
          justify={isMobile ? "center" : undefined}
          gutter={[20, 20]}
        >
          <Col
            span={isMobile ? 24 : undefined}
            style={{
              ...FONT.bodyText2Regular,
              color: COLOR.text.blackText,
              textAlign: "center",
            }}
          >
            <span>
              {t("category")}
              :
            </span>
          </Col>
          <Col
            style={{
              ...FONT.bodyText2Regular,
              color:
                selectedCategory === 0
                  ? COLOR.text.primaryText
                  : COLOR.function.disable,
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedCategory(0);
            }}
          >
            <span style={{ marginLeft: 8, marginRight: 8 }}>{t("all")}</span>
          </Col>
          {_.map(dataList.newsCategoryList, (eachCategory) => {
            return (
              <Col
                style={{
                  ...FONT.bodyText2Regular,
                  color:
                    selectedCategory === eachCategory.news_category_id
                      ? COLOR.text.primaryText
                      : COLOR.function.disable,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedCategory(eachCategory.news_category_id);
                }}
              >
                <span
                  style={{ marginLeft: 8, marginRight: 8 }}
                >
                  {eachCategory[`category_name_${i18n.language}`] || eachCategory.category_name || ""}
                </span>
              </Col>
            );
          })}
        </Row>
        <Row gutter={[{ md: 20, lg: 28 }, 24]}>
          {_.map(filterList, (eachBlogPost, index) => {
            return (
              <Col key={index} xs={24} sm={24} md={24} lg={8} xl={6}>
                <BlogItem blogItemRc={eachBlogPost} />
              </Col>
            );
          })}
        </Row>
      </div>
    </AppLayout>
  );
};

export default NewsListPage;
